import {SiteSettings} from '@process-manager/pm-library';
import {User} from '@process-manager/pm-library/lib/model/user';
import {
  AuthenticationActions, DOMAIN_CHOSEN, LOGIN_SUCCESS, LOGOUT, OFFLINE, UPDATE_SETTINGS
} from '../actions/auth.actions';
import {LoadTree} from '../actions/tree.actions';


export interface UserState {
  domain: string;
  localSession: boolean;
  loggedIn: boolean;
  isOnline: boolean;
  user: User | null;
  siteSettings: SiteSettings;
}

export const initialState: UserState = {
  domain: undefined,
  localSession: true,
  loggedIn: false,
  isOnline: false,
  user: null,
  siteSettings: null
};

export function authReducer(state = initialState, action: AuthenticationActions | LoadTree): UserState {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isOnline: action.payload.isOnline,
        user: action.payload.user,
        siteSettings: action.payload.siteSettings,
        domain: action.payload.domain
      };
    case DOMAIN_CHOSEN:
      return {
        ...state,
        domain: action.payload.domain,
        localSession: !!action.payload.local
      }
    case UPDATE_SETTINGS: {
      return {
        ...state,
        siteSettings: action.payload.settings
      }
    }
    case OFFLINE: {
      return {
        ...state,
        isOnline: false
      }
    }

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}

export const getLoggedIn = (state: UserState) => state.loggedIn;
export const getUser = (state: UserState) => state.user;
export const getDomain = (state: UserState) => state.domain;
export const getUserRoles = (state: UserState) => state.user?.roles || [];
export const getUserIsPublic = (state: UserState) => state.user?.userIsPublic;
export const getSiteLanguages = (state: UserState) => state.siteSettings && state.siteSettings.languages || [];
