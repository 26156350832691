<div class="pm-anchor pm-options-main-panel" [@showTrigger]="activeState" (@showTrigger.start)="captureAnimationStart()">
  <button color="primary" mat-mini-fab [matTooltip]="textEditLabel | async" matTooltipPosition="above"
          (click)="doToggleTextEdit()" *ngIf="canEditOrTranslate" [class.pm-active-button]="textEditing"
          [class.mat-elevation-z2]="textEditing" [class.mat-elevation-z8]="!textEditing">
    <mat-icon *ngIf="isDefaultLanguage">edit</mat-icon>
    <mat-icon *ngIf="!isDefaultLanguage">translate</mat-icon>
  </button>
  <button color="primary" mat-mini-fab matTooltip="{{'menu.options.main.add-resource' | translate}}"
          matTooltipPosition="above" class="mat-elevation-z8" (click)="addResource()" *ngIf="canEditOrTranslate">
    <mat-icon>attach_file</mat-icon>
  </button>
  <button color="primary" mat-mini-fab matTooltip="{{'menu.options.main.add-element' | translate}}"
          matTooltipPosition="above" *ngIf="!isBullet && canEditSelfOrParent" (click)="toggleAddEdit()"
          [class.mat-elevation-z2]="addEditing" [class.mat-elevation-z8]="!addEditing"
          [class.pm-active-button]="addEditing">
    <mat-icon>add_circle</mat-icon>
  </button>
  <button color="primary" mat-mini-fab matTooltip="{{'menu.options.main.add-bullet' | translate}}"
          matTooltipPosition="above" class="mat-elevation-z8" *ngIf="isBullet && canEditSelf"
          (click)="createProcessOrBullet('after', true)">
    <mat-icon>add_circle</mat-icon>
  </button>
  <button color="primary" mat-mini-fab matTooltip="{{'menu.options.main.delete' | translate}}"
          matTooltipPosition="above" class="mat-elevation-z8" (click)="deleteItem()" *ngIf="canDelete">
    <mat-icon>remove_circle</mat-icon>
  </button>
  <button color="primary" mat-mini-fab matTooltip="{{'menu.options.main.cut' | translate}}" matTooltipPosition="above"
          (click)="onCutOrCopy(false)" class="mat-elevation-z8" *ngIf="canEditSelf">
    <mat-icon>content_cut</mat-icon>
  </button>
  <!--suppress HtmlUnknownAttribute -->
  <button color="primary" mat-mini-fab matTooltip="{{'menu.options.main.copy' | translate}}" matTooltipPosition="above"
          *ngIf="!context?.backup" (click)="onCutOrCopy(true)" class="mat-elevation-z8" ngxClipboard [cbContent]="url">
    <mat-icon>content_copy</mat-icon>
  </button>
  <button color="primary" mat-mini-fab *ngIf="!isBullet && canEditSelf"
          matTooltip="{{'menu.options.main.edit-styling' | translate}}" matTooltipPosition="above"
          (click)="toggleStyleEdit()" [class.mat-elevation-z2]="styleEditing" [class.mat-elevation-z8]="!styleEditing"
          [class.pm-active-button]="styleEditing">
    <mat-icon>style</mat-icon>
  </button>
  <button color="primary" mat-mini-fab *ngIf="isBullet && canEditSelf"
          matTooltip="{{ 'menu.options.main.show-bullet' | translate}}" matTooltipPosition="above"
          (click)="toggleBullet()" [class.pm-active-button]="bulletVisible"
          [class.mat-elevation-z2]="bulletVisible" [class.mat-elevation-z8]="!bulletVisible">
    <mat-icon>format_list_bulleted</mat-icon>
  </button>
  <button color="primary" mat-mini-fab matTooltip="{{'menu.options.main.edit-owner' | translate}}"
          matTooltipPosition="above" class="mat-elevation-z8" (click)="editOwnerAndPermissions()" *ngIf="canEditSelf">
    <mat-icon>person</mat-icon>
  </button>
  <button color="primary" mat-mini-fab (click)="toggleFavorite()" [class.pm-active-button]="isFavorite"
          [class.mat-elevation-z2]="isFavorite" [class.mat-elevation-z8]="!isFavorite"
          *ngIf="(fromCustomer$ | async) && !isBackup && !isTemplateId"
          matTooltip="{{'menu.options.main.favorite' | translate}}" matTooltipPosition="above">
    <mat-icon>{{favoriteIcon}}</mat-icon>
  </button>
  <button color="primary" mat-mini-fab *ngIf="hasOwner && (isOnline$ | async)" (click)="popupContactDialog()" class="mat-elevation-z8"
          matTooltip="{{'menu.options.main.contact-owner' | translate}}" matTooltipPosition="above">
    <mat-icon>mail</mat-icon>
  </button>
  <button color="primary" mat-mini-fab class="mat-elevation-z8" *ngIf="canEditSelf && (labels$ | async).length > 0" matTooltipPosition="above"
          matTooltip="{{'menu.options.main.label' | translate}}" [matMenuTriggerFor]="tagMenu">
    <mat-icon class="material-icons-outlined">label</mat-icon>
  </button>
  <button color="primary" mat-mini-fab class="mat-elevation-z8" *ngIf="canEditSelf && isVendor" matTooltipPosition="above"
          matTooltip="{{'menu.options.main.publish-template' | translate}}" (click)="publishTemplateVersion()" >
    <mat-icon>publish</mat-icon>
  </button>
  <button color="primary" mat-mini-fab class="mat-elevation-z8" *ngIf="canOverwriteSite && isAuthor" matTooltipPosition="above"
          matTooltip="{{'menu.options.main.replace-site-with-template' | translate}}" (click)="replaceSite()" >
    <mat-icon>home</mat-icon>
  </button>
</div>

<div class="pm-anchor pm-options-style-panel" [@showTrigger]="styleEditState" [class.pm-hidden]="!styleEditing">
  <button mat-mini-fab matTooltip="{{'menu.options.style.line-color' | translate}}" matTooltipPosition="above"
          class="mat-elevation-z8 line-color-trigger" cdkOverlayOrigin #lineColorTrigger="cdkOverlayOrigin"
          (click)="lineColorSelectorOpen = !lineColorSelectorOpen">
    <mat-icon>border_color</mat-icon>
  </button>
  <button mat-mini-fab matTooltip="{{'menu.options.style.fill-color' | translate}}" matTooltipPosition="above"
          class="mat-elevation-z8 bg-color-trigger" cdkOverlayOrigin #bgColorTrigger="cdkOverlayOrigin"
          (click)="bgColorSelectorOpen = !bgColorSelectorOpen">
    <mat-icon>format_color_fill</mat-icon>
  </button>
  <button mat-mini-fab matTooltip="{{'menu.options.style.shape' | translate}}" matTooltipPosition="above"
          [matMenuTriggerFor]="shapeMenu" class="mat-elevation-z8">
    <mat-icon>format_shapes</mat-icon>
  </button>
</div>

<div class="pm-anchor pm-options-add-panel" [@showTrigger]="addEditState" [class.pm-hidden]="!addEditing">
  <button mat-mini-fab matTooltip="{{'menu.options.add.before' | translate}}" matTooltipPosition="above"
          class="mat-elevation-z8 create-before" (click)="createProcessOrBullet('before')" *ngIf="canEditParent">
    <mat-icon *ngIf="!isSubPage">arrow_back</mat-icon>
    <mat-icon *ngIf="isSubPage">arrow_upward</mat-icon>
  </button>
  <button mat-mini-fab matTooltip="{{'menu.options.add.under' | translate}}" matTooltipPosition="above"
          class="mat-elevation-z8 create-under" (click)="createProcessOrBullet('under')"
          [class.sub-page-under]="isSubPage" *ngIf="canEditSelf">
    <mat-icon *ngIf="!isSubPage">arrow_downward</mat-icon>
    <mat-icon *ngIf="isSubPage">arrow_forward</mat-icon>
  </button>
  <button mat-mini-fab matTooltip="{{'menu.options.add.after' | translate}}" matTooltipPosition="above"
          class="mat-elevation-z8 create-after" (click)="createProcessOrBullet('after')" *ngIf="canEditParent">
    <mat-icon *ngIf="!isSubPage">arrow_forward</mat-icon>
    <mat-icon *ngIf="isSubPage">arrow_downward</mat-icon>
  </button>
  <button mat-mini-fab matTooltip="{{'menu.options.add.bullet' | translate}}" matTooltipPosition="above"
          class="mat-elevation-z8" (click)="createProcessOrBullet('under', true)" *ngIf="!isSubPage && canEditSelf">
    <mat-icon>format_list_bulleted</mat-icon>
  </button>
</div>

<mat-menu #shapeMenu="matMenu" class="shape-menu" [hasBackdrop]="true">
  <div *ngFor="let style of styleArray" class="pm-shape-previews"
       [class.pm-shape-selected]="context.style && context.style.shape === style.shape">
    <pm-shape [style]="style" [width]="100" [height]="50" (click)="updateElementShape(style.shape)"></pm-shape>
  </div>
  <mat-divider></mat-divider>
  <div mat-menu-item *ngIf="canChangePageType">
    <mat-checkbox (click)="$event.stopPropagation()" [checked]="isMainPage"
                  [disabled]="!canChangePageTypeCurrently" (change)="setMainPage($event)">{{'menu.options.style.page' |
      translate}}
    </mat-checkbox>
  </div>
  <div mat-menu-item>
    <mat-checkbox (click)="$event.stopPropagation()" [checked]="isSearchable" (change)="setSearchable($event)">
      {{'menu.options.style.searchable' | translate}}
    </mat-checkbox>
  </div>
</mat-menu>

<mat-menu #tagMenu="matMenu" class="label-menu" [hasBackdrop]="true">
  <div mat-menu-item *ngFor="let label of (labels$ | async)">
    <mat-checkbox (click)="$event.stopPropagation()" [checked]="hasLabel(label)" (change)="setLabel($event, label)">
      <div class="label-wrapper" [style.background-color]="label.color" [class.contrast-dark]="isDark(label)">
        <span>{{label.name}}</span>
      </div>
    </mat-checkbox>
  </div>
  <div class="pm-label-hint">{{'menu.options.labels.max-labels' | translate}}</div>
</mat-menu>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="lineColorTrigger" [cdkConnectedOverlayOpen]="lineColorSelectorOpen"  [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="lineColorSelectorOpen = false; updateElementStyle()">>
  <color-chrome [color]="style.lineColor" (onChangeComplete)="lineColorChange($event)" disableAlpha="true" ></color-chrome>
</ng-template>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="bgColorTrigger" [cdkConnectedOverlayOpen]="bgColorSelectorOpen" [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="bgColorSelectorOpen = false; updateElementStyle()">
  <color-chrome [color]="style.bgColor" (onChangeComplete)="bgColorChange($event)" disableAlpha="true" ></color-chrome>
</ng-template>
