import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserService} from '@process-manager/pm-library';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

export interface LogbookEntry {
  id: number;
  author: string;
  created: Date;
  entryText: string;
}

@Injectable({
  providedIn: 'root'
})
export class LogbookService {
  constructor(private http: HttpClient, private userService: UserService) {
  }

  getEntries(): Observable<LogbookEntry[]> {
    const url = environment.api + this.userService.domain + '/logbook/';

    return this.http.get(url).pipe(map(entries => (entries as any[]).map(this.convertEntry)));
  }

  addEntry(entry: string): Observable<LogbookEntry> {
    const url = environment.api + this.userService.domain + '/logbook/';

    return this.http.post(url, entry).pipe(map(this.convertEntry));
  }

  deleteEntry(id: number) {
    const url = environment.api + this.userService.domain + '/logbook/' + id;

    return this.http.delete(url);
  }

  private convertEntry(entry: any) {
    return {
      id: entry.id,
      author: entry.author,
      entryText: entry.entryText,
      created: new Date(entry.created)
    }
  }
}
