import {Component, Input, OnInit} from '@angular/core';
import {HistoryEvent} from '../../../../../../shared/model/history-events';

@Component({
  selector: 'pm-default-history-renderer',
  templateUrl: './default-history-renderer.component.html',
  styleUrls: ['./default-history-renderer.component.css']
})
export class DefaultHistoryRendererComponent implements OnInit {
  @Input() event: HistoryEvent;

  constructor() {
  }

  get eventName() {
    // @ts-ignore
    return this.event?.eventType ?? '';
  }

  ngOnInit(): void {
  }

}
