import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {LogbookEntry} from '../shared/logbook.service';

const EMTPY_ENTRY: LogbookEntry = {
  id: 0,
  entryText: '',
  author: '',
  created: new Date()
};

@Component({
  selector: 'pm-logbook-entry',
  templateUrl: './logbook-entry.component.html',
  styleUrls: ['./logbook-entry.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogbookEntryComponent implements OnInit {
  @HostBinding('class.mat-elevation-z1')
  raise = true;

  @Input() entry: LogbookEntry = EMTPY_ENTRY;
  @Input() isAdmin: boolean;

  @Output()
  delete = new EventEmitter<void>();

  language: string;


  ngOnInit() {
  }

}
