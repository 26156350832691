<h2 mat-dialog-title>{{'dialog.label-admin.title' | translate}}</h2>
<form [formGroup]="labelForm" novalidate mat-dialog-content class="mat-typography">
  <mat-form-field class="block-field" floatLabel="always">
    <mat-label>{{'dialog.label-admin.label' | translate}}</mat-label>
    <mat-select formControlName="id" placeholder="{{'dialog.label-admin.select-label' | translate}}"
                (selectionChange)="labelSelectionChange($event)">
      <mat-option [value]="NEW_LABEL_SELECT">{{'dialog.label-admin.new-label' | translate}}</mat-option>
      <mat-optgroup label="{{'dialog.label-admin.existing-labels' | translate}}">
        <mat-option *ngFor="let label of (labelAsArray$ | async)" [value]="label.id">{{ label.name }}</mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
  <div>
    <mat-form-field class="block-field">
      <mat-label>{{'dialog.label-admin.name' | translate}}</mat-label>
      <input matInput id="pm_labelname" type="text" formControlName="name">
      <mat-error *ngIf="name.errors?.notUnique">{{'form.errors.label.name.not-unique' | translate}}</mat-error>
      <mat-error
              *ngIf="name.errors?.required || name.errors?.minlength">{{'form.errors.label.name.required' | translate}}</mat-error>
      <mat-error
              *ngIf="name.errors?.maxlength">{{'form.errors.label.name.maxLength' | translate:name.errors?.maxlength}}</mat-error>
    </mat-form-field>
  </div>

  <section *ngIf="showVendorFeatures" class="pm-trial-selector">
    <div>{{'dialog.label-admin.label-type' | translate}}</div>
    <mat-radio-group formControlName="labelType">
      <mat-radio-button value="trial">{{'dialog.label-admin.label-type.is-trial' | translate}}</mat-radio-button>
      <mat-radio-button value="extension">{{'dialog.label-admin.label-type.is-extension' | translate}}</mat-radio-button>
      <mat-radio-button value="none">{{'dialog.label-admin.label-type.none' | translate}}</mat-radio-button>
    </mat-radio-group>
  </section>

  <div class="block-field">
    <div class="pm-custom-label">{{'dialog.label-admin.color' | translate}}</div>
    <pm-color-picker-input id="pm_labelcolor" formControlName="color"></pm-color-picker-input>
  </div>
</form>

<div mat-dialog-actions>
  <button mat-button type="button" (click)="submit()" [disabled]="labelForm.invalid">{{'button.ok' |
    translate}}
  </button>
  <button mat-button type="button" mat-dialog-close="">{{'button.cancel' | translate}}</button>
  <button mat-button type="button" (click)="delete()" [disabled]="isNew" color="warn">
    {{'button.delete' | translate}}
  </button>
</div>
