import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Authenticate} from '@process-manager/pm-library/lib/model/user';
import {hasMessage} from '../domain-form/has.message';

@Component({
  selector: 'pm-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  @Input() domain: string;
  @Input() returnUrl: string;
  @Input() pending: boolean;
  @Input() error: Error | string;

  @Output() authenticated = new EventEmitter<Authenticate>();
  @Output() reset = new EventEmitter<void>();
  @Output() backToDomain = new EventEmitter<void>();

  errorLabel = '';

  constructor(private translateService: TranslateService) {
  }

  get errorMessage(): string {
    if (this.error instanceof HttpErrorResponse && !this.error.status) {
      return this.errorLabel;
    } else if (hasMessage(this.error)) {
      return this.error.message;
    } else {
      return this.error;
    }
  }

  ngOnInit() {
    this.translateService.get('login.error.unknown').subscribe(label => this.errorLabel = label);
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      this.authenticated.emit({
        ...form.value,
        returnUrl: this.returnUrl
      });
    }
  }

  resetPassword() {
    this.reset.emit();
  }

  chooseDomain() {
    this.backToDomain.emit();
  }
}
