import {AuthenticationActions, REQUEST_LOGOUT} from '../actions/auth.actions';
import {FormatActionTypes, FormattingActions} from '../actions/formatting.actions';
import {TreeActions, TreeActionTypes} from '../actions/tree.actions';

export interface FormattingState {
  formatting: boolean;
  formatSourceId: string;
}

export const initialState: FormattingState = {
  formatting: false,
  formatSourceId: null
};

export function formattingStoreReducer(state = initialState,
                                       action: AuthenticationActions | FormattingActions | TreeActions): FormattingState {
  switch (action.type) {
    case FormatActionTypes.ACTIVATE_FORMATTING:
      return {
        formatting: true,
        formatSourceId: null
      };
    case FormatActionTypes.SET_FORMAT_SOURCE:
      return {
        ...state,
        formatSourceId: action.payload.id
      };
    case FormatActionTypes.DEACTIVATE_FORMATTING:
    case TreeActionTypes.UNDO_STEP:
    case TreeActionTypes.LOAD_TREE:
    case TreeActionTypes.SAVE_STEPS:
    case TreeActionTypes.ACTIVATE_ELEMENT:
    case REQUEST_LOGOUT:
      return {
        formatting: false,
        formatSourceId: null
      };
    default:
      return state;
  }
}
