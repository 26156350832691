<h2 mat-dialog-title>{{'dialog.history.title'|translate}}</h2>

<div mat-dialog-content>
  <div class="expansion-controls mat-typography">
    <span class="pull-right"></span>
    <span><small>
          <a href="#" (click)="!!expandAll();">{{'action.expand-all' | translate}}</a>&nbsp;
          <a href="#" (click)="!!closeAll()">{{'action.collapse-all' | translate}}</a></small>
  </span>
  </div>
  <mat-accordion [multi]="true" displayMode="flat">
    <mat-expansion-panel *ngFor="let eventBlock of currentLoaderResponse?.result; trackBy: identity">
      <mat-expansion-panel-header>
        <mat-panel-title>{{'history.user.update' |translate:eventBlock}}</mat-panel-title>
      </mat-expansion-panel-header>
      <pm-event-block-view [eventBlock]="eventBlock"></pm-event-block-view>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="paging-controls">
    <button mat-flat-button [disabled]="!(currentLoaderResponse?.hasMoreBefore)" (click)="previous()">{{'action.previous' | translate}}</button>
    <button mat-flat-button [disabled]="!(currentLoaderResponse?.hasMoreAfter)" (click)="next()">{{'action.next' | translate}}</button>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button type="button" mat-dialog-close="">{{'button.close' | translate}}</button>
</div>
