<button class="pm-view-options-button" mat-icon-button [matMenuTriggerFor]="viewOptionsMenu" matTooltip="{{'menu.view-options' | translate}}">
  <mat-icon>visibility</mat-icon>
</button>

<mat-menu #viewOptionsMenu="matMenu">
  <button mat-menu-item (click)="openLabelsDialog()">
    <mat-icon>label</mat-icon> {{'menu.view-options.labels' | translate}}...
  </button>
  <button mat-menu-item [matMenuTriggerFor]="highlightMenu">
    <ng-container [ngSwitch]="translationHighlight">
      <mat-icon *ngSwitchCase="'untranslated'">flashlight_on</mat-icon>
      <mat-icon *ngSwitchCase="'translated'" class="material-icons-outlined">flashlight_on</mat-icon>
      <mat-icon *ngSwitchCase="'off'">flashlight_off</mat-icon>
    </ng-container>
    {{'menu.view-options.translations' | translate}}
  </button>
</mat-menu>

<mat-menu #highlightMenu="matMenu">
  <button mat-menu-item  (click)="highlightSelected('untranslated')"
          [matTooltipDisabled]="!isDefaultLanguage"
          matTooltip="{{'menu.translate.highlight-untranslated.details' | translate}}">
    <mat-icon>flashlight_on</mat-icon>
    {{'menu.translate.highlight-untranslated' | translate}}
  </button>
  <button mat-menu-item  (click)="highlightSelected('translated')"
          [matTooltipDisabled]="!isDefaultLanguage"
          matTooltip="{{'menu.translate.highlight-translated.details' | translate}}">
    <mat-icon class="material-icons-outlined">flashlight_on</mat-icon>
    {{'menu.translate.highlight-translated' | translate}}
  </button>
  <button mat-menu-item  (click)="highlightSelected('off')">
    <mat-icon>flashlight_off</mat-icon>
    {{'menu.translate.highlight-off' | translate}}
  </button>
</mat-menu>
