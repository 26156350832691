<h2 mat-dialog-title>{{'dialog.language-admin.title' | translate}}</h2>

<div mat-dialog-content>
  <mat-form-field class="language-selector block-field">
    <mat-label>{{'dialog.user-admin.language' | translate}}</mat-label>
    <mat-select name="selected-language" [(ngModel)]="selectedLanguage" (selectionChange)="languageSelectionChange($event)">
      <mat-option [value]="NEW_LANGUAGE_SELECT">{{'dialog.language-admin.new-language' | translate}}</mat-option>
      <mat-optgroup label="{{'dialog.language-admin.existing-languages' | translate}}">
        <mat-option *ngFor="let lang of languages" [value]="lang.name">{{lang.name}}</mat-option>
      </mat-optgroup>
      <mat-select-trigger>{{overriddenTriggerLabel}}</mat-select-trigger>
    </mat-select>
  </mat-form-field>
<form [formGroup]="languageForm" novalidate>
  <mat-form-field class="block-field">
    <input matInput id="pm_language-name" name="language-name"
           placeholder="{{'dialog.language-admin.language-name' | translate}}" required
           formControlName="name">
  </mat-form-field>

  <mat-form-field class="block-field">
    <mat-label>{{'dialog.language-admin.ui-language' | translate}}</mat-label>
    <mat-select formControlName="interfaceLanguage">
      <mat-option [value]="'da_dk'">{{'lang.danish' | translate}}</mat-option>
      <mat-option [value]="'en_gb'">{{'lang.english' | translate}}</mat-option>
      <mat-option [value]="'it_it'">{{'lang.italian' | translate}}</mat-option>
      <mat-option [value]="''">{{'dialog.language-admin.browser-language' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="block-field pm-auto-language">
    <mat-label>{{automaticLanguage | translate}}</mat-label>
    <mat-select formControlName="autoTranslateLanguage">
      <mat-option [value]="''">{{automaticLanguageNone | translate}}</mat-option>
      <mat-option *ngFor="let autoLang of filterAutoLanguages(this.languageForm.get('isDefault').value)" [value]="autoLang.languageCode">{{autoLang.displayName}}</mat-option>
    </mat-select>
    <mat-hint>{{automaticLanguageHint | translate}}</mat-hint>
    <mat-error *ngIf="autoControl.errors?.notUnique">{{'dialog.language-admin.auto-language.error.not-unique' | translate}}</mat-error>
  </mat-form-field>

  <mat-checkbox name="language-is-default" formControlName="isDefault">{{'dialog.language-admin.default-language' | translate}}
  </mat-checkbox>

  <div class="block-field">
    <hr>
    <div class="pm-dictionary mat-typography" *ngIf="dictionaryRelevant">
      <p class="mat-hint">{{'dialog.language-admin.show-dictionary.description' | translate}}</p>
      <button mat-stroked-button type="button" (click)="showDictionary()">{{'dialog.language-admin.show-dictionary' | translate}}</button>
    </div>
  </div>
</form>
</div>
<div mat-dialog-actions>
  <button mat-button type="submit" (click)="onSubmit()" [disabled]="languageForm.invalid">{{'button.ok' | translate}}</button>
  <button mat-button type="button" mat-dialog-close>{{(languageForm.pristine ? 'button.close' : 'button.cancel') | translate}}</button>
  <button mat-button type="button" color="warn" [disabled]="disabled" (click)="onDelete()">{{'button.delete' |
    translate}}
  </button>
</div>
