// noinspection JSUnusedGlobalSymbols

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';
import {map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {isTemplateId, templateIdEquals} from '../../shared/model/template-id';
import {TreeWithLanguages} from '../../shared/model/tree';
import {NavigationService} from '../../shared/services/navigation.service';
import {LanguageMap, TemplateLanguageMappingService} from '../../shared/services/template-language-mapping.service';
import {TemplatesService} from '../../shared/services/templates.service';
import {TemplateTreeBuilderService} from '../../shared/tree-building/template-tree-builder.service';
import {
  ListTemplateAction, ListTemplateCompleteAction, LoadTemplateAction, LoadTemplateCompleteAction, TemplateActionTypes,
  ToggleTemplatesAction, UpdateOldSourceLanguages
} from '../actions/templates.actions';
import {ChangeLanguage, LoadTree, LoadTreeSuccess, TreeActionTypes} from '../actions/tree.actions';
import {getPreferredLanguage} from '../helpers/getPreferredLanguage';
import {
  AppState, getLastActiveNonTemplateSource, getLastActiveTemplateSource, getTreeActiveLanguage, getTreeSource
} from '../reducers';

@Injectable()
export class TemplatesEffects {
  listTemplateRequest$ = createEffect(
    () => this.actions$.pipe(ofType<ListTemplateAction>(TemplateActionTypes.LIST_TEMPLATES_REQUEST),
      switchMap(() => this.templateService.getPreRenderedTemplates()
        .pipe(map(templates => new ListTemplateCompleteAction({templateNodes: templates}))))));

  loadTemplateRequest$ = createEffect(
    () => this.actions$.pipe(ofType<LoadTemplateAction>(TemplateActionTypes.LOAD_TEMPLATE_REQUEST),
      withLatestFrom(this.store$.select(getTreeSource), this.store$.select(getTreeActiveLanguage),
        this.store$.select(getLastActiveTemplateSource)),
      tap(([action, treeSource, activeLanguage, lastTemplateSource]) => {
        const templateId = action.payload.templateId;
        const loadTemplateIntoTree = (tree: TreeWithLanguages, languageMap: LanguageMap) => {
          const siteLanguages = this.userService.getSiteSettings().languages;
          const languages = tree.languages.map(lang => {
            const mappedLang = languageMap.hasOwnProperty(lang.id.toString(10)) && languageMap[lang.id.toString(10)] ||
              null;
            let newName = this.translateService.instant('lang.unmapped', {lang: lang.name});
            if (!!mappedLang) {
              const siteLangName = siteLanguages.find(siteLang => siteLang.id === mappedLang.id).name;
              if (siteLangName !== lang.name) {
                newName = this.translateService.instant('lang.mapped', {
                  templateLang: lang.name,
                  siteLang: siteLangName
                });
              } else {
                newName = lang.name;
              }
            }
            return {
              ...lang,
              name: newName,
              mappedLang: mappedLang?.id
            };
          });

          tree = {
            ...tree,
            languages
          }

          if (treeSource === 'customer') {
            this.store$.dispatch(new ToggleTemplatesAction({forcedState: 'closed'}));
          }

          this.store$.dispatch(new LoadTemplateCompleteAction({
            ...action.payload,
            languages: languages
          }));

          const initialLanguage = getPreferredLanguage(activeLanguage, tree.languages);
          this.store$.dispatch(new LoadTreeSuccess({
            tree: tree,
            wipeHistory: true,
            openNode: action.payload.nodeId || tree.root,
            language: initialLanguage
          }));
          this.store$.dispatch(new ChangeLanguage({
            source: tree.source,
            newLanguage: initialLanguage,
            oldLanguage: null,
            doReload: false
          }));
        }

        if (!treeSource || treeSource === 'customer' || treeSource === 'offline' || !templateIdEquals(treeSource, templateId)) {
          this.templateService.getVersion(templateId)
            .subscribe(result => {
              const tree = this.templateTreeBuilderService.build(result, {templateId: templateId});
              const languageMap = this.templateLanguageMapping.getMapping();
              if (!languageMap) {
                throw new Error('Language map should be stored by now.')
              }
              loadTemplateIntoTree(tree, languageMap);
            });
        } else {
          this.store$.dispatch(new LoadTree({
            id: action.payload.nodeId && ('' + action.payload.nodeId) || undefined,
            template: templateId
          }))
        }
      })), {dispatch: false});

  changeLanguage$ = createEffect(() => this.actions$.pipe(ofType<ChangeLanguage>(TreeActionTypes.CHANGE_LANGUAGE),
    withLatestFrom(this.store$.select(getTreeSource), this.store$.select(getTreeActiveLanguage)), map(
      ([, source, language]) => new UpdateOldSourceLanguages({
        source,
        language
      }))));

  exitTemplate$ = createEffect(() => this.actions$.pipe(ofType<LoadTemplateAction>(TemplateActionTypes.EXIT_TEMPLATE),
    withLatestFrom(this.store$.select(getTreeSource), this.store$.select(getLastActiveNonTemplateSource)),
    tap(([, treeSource, lastNonTemplateSource]) => {
      if (isTemplateId(treeSource)) {
        this.store$.dispatch(new ChangeLanguage({
          source: 'customer',
          newLanguage: lastNonTemplateSource?.language,
          doReload: false
        }));
        this.navigationService.navigateToNode(lastNonTemplateSource?.nodeId)
          .catch(err => console.error('Could not navigate to node', lastNonTemplateSource?.nodeId, err));
      }
    })), {dispatch: false});

  constructor(private actions$: Actions, private store$: Store<AppState>, private templateService: TemplatesService,
    private templateTreeBuilderService: TemplateTreeBuilderService, private navigationService: NavigationService,
    private templateLanguageMapping: TemplateLanguageMappingService, private userService: UserService,
    private translateService: TranslateService) {
  }
}
