import {v1} from "uuid";

export function convertFontSize(stringSize: string): string {
  const x = +stringSize;

  // Yes, it's magic numbers. No, I will not replace with const (numbers found using
  // http://www.xuru.org/rt/PR.asp#CopyPaste).
  return (2.236111751e-3 * x ** 6 - 5.304168208e-2 * x ** 5 + 4.963195911e-1 * x ** 4 - 2.304792366 * x ** 3 +
    5.516446201 * x ** 2 - 6.167168831 * x + 3.140000787).toFixed(2) + 'em';
}

const TAGS_TO_REPLACE = ['font', 'b', 'i', 'em', 'u', 'strike'];
const START_TAGS_TO_REPLACE = TAGS_TO_REPLACE.map(tag => new RegExp(`<${tag}(\\s[^>]+>|>)`, 'gi'));
const END_TAGS_TO_REPLACE = TAGS_TO_REPLACE.map(tag => new RegExp(`<\/${tag}>`, 'gi'));

export function replaceTags(input: string): string {
  START_TAGS_TO_REPLACE.forEach(tag => input = input.replace(tag, '<span>'));
  END_TAGS_TO_REPLACE.forEach(tag => input = input.replace(tag, '</span>'));
  return input;
}

function wrap(el: Node, wrapper: HTMLElement) {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

const emptyFilter: NodeFilter = {
    acceptNode: (node) => node.nodeValue.trim() === '' ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT
};

const nonEmptyFilter: NodeFilter = {
  acceptNode: (node) => node.nodeValue.trim() !== '' ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT
};

const getTextTreeWalker = function (under: Node, filter: NodeFilter = null): TreeWalker {
  return document.createTreeWalker(under, NodeFilter.SHOW_TEXT, filter);
};

function findAllTextNodes(under: Element, filter: NodeFilter = null) {
  const a = [];
  const treeWalker = getTextTreeWalker(under, filter);
  let n;
  while (!!(n = treeWalker.nextNode())) {
    a.push(n);
  }
  return a;
}

function findFirstTextNode(element: Node, filter: NodeFilter = null): Text {
  return getTextTreeWalker(element, filter).firstChild() as Text;
}

function copyStyle(sourceStyle: CSSStyleDeclaration, targetStyle: CSSStyleDeclaration) {
  Array.from(sourceStyle).map((style) => {
    targetStyle.setProperty(style, sourceStyle.getPropertyValue(style));
  });
}

export function extractStyle(originalText: string, styleSourceText: string): string {
  const stylingElement = document.createElement('span');
  stylingElement.id = 'styleExtractor-' + v1();
  stylingElement.innerHTML = styleSourceText;
  const targetElement: HTMLElement = document.createElement('span');
  targetElement.innerHTML = replaceTags(originalText);
  const emptyWalker = getTextTreeWalker(targetElement, emptyFilter);
  while (emptyWalker.nextNode()) {
    emptyWalker.currentNode.parentNode.removeChild(emptyWalker.currentNode);
  }

  const firstTextNode = findFirstTextNode(stylingElement, nonEmptyFilter);
  if (!!firstTextNode) {
    targetElement.querySelectorAll('*').forEach(origElem => origElem.removeAttribute('style'));
    targetElement.removeAttribute('style');
    const textNodes = findAllTextNodes(targetElement);
    if (targetElement.children.length === 0 && textNodes.length > 0) {
      wrap(textNodes[0], document.createElement('span'));
    }

    let parent: HTMLElement = firstTextNode.parentElement;
    while (parent.id !== stylingElement.id) {
      const current = parent;
      parent = parent.parentElement;
      const currentStyle = current.style;
      if (['B', 'U', 'EM', 'I', 'STRIKE'].includes(current.tagName)) {
        textNodes.forEach(textNode => wrap(textNode, document.createElement(current.tagName)));
      }

      if (current.tagName === 'FONT') {
        const color = current.getAttribute('color');
        const face = current.getAttribute('face');
        const size = current.getAttribute('size');
        if (!!color) {
          currentStyle.setProperty('color', color);
        }
        if (!!face) {
          currentStyle.setProperty('font-family', face);
        }
        if (!!size) {
          currentStyle.setProperty('font-size', convertFontSize(size));
        }
      }
      copyStyle(currentStyle, parent.style);
    }

    Array.from(targetElement.children).forEach(child => copyStyle(parent.style, (child as HTMLElement).style));
  }

  return targetElement.innerHTML;
}

export function stripHtml(input: string): string {
  return new DOMParser().parseFromString(input, 'text/html')?.body?.textContent || '';
}
