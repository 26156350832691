<h1 mat-dialog-title>{{'dialog.logbook.title' | translate}}</h1>
<mat-dialog-content>
  <div *ngIf="isAuthor" class="pm-logbook-editor">
    <div class="pm-logbook-trumbowyg" [pmTrumbowyg]="trumbowygOptions" [(ngModel)]="newEntry" name="logbook_body" tabindex="0" contenteditable="true"></div>
  </div>
  <div class="entries" #entryList>
    <pm-logbook-entry *ngFor="let entry of entries" [entry]="entry" [isAdmin]="isAdmin" (delete)="onDelete(entry)" (pmLinkClick)="openLink($event)" ></pm-logbook-entry>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button *ngIf="isAuthor" (click)="onAddEntryClick()">{{'dialog.logbook.add' | translate}}</button>
  <button mat-button color="primary" *ngIf="isAuthor && showLogoutButton" (click)="onAddEntryClick(true)">{{'dialog.logbook.add.logout' | translate}}</button>
  <button mat-button [mat-dialog-close]="true">{{'button.close' | translate}}</button>
</mat-dialog-actions>
