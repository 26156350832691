import {Language} from '@process-manager/pm-library';
import {isTemplateId, TemplateId} from '../../shared/model/template-id';
import {TemplateActions, TemplateActionTypes} from '../actions/templates.actions';
import {ChangeLanguage, LoadTreeSuccess, TreeActionTypes} from '../actions/tree.actions';

export interface TemplatesState {
  templatesEnabled: boolean;
  templatesShowing: boolean;
  lastTemplateSource: {
    templateId: TemplateId,
    language: Language,
    nodeId: string
  };
  lastNonTemplateSource: {
    language: Language,
    nodeId: string;
  }
}

const initialState: TemplatesState = {
  templatesEnabled: false,
  templatesShowing: false,
  lastTemplateSource: undefined,
  lastNonTemplateSource: undefined
}

export function templatesReducer(state = initialState, action: TemplateActions | LoadTreeSuccess | ChangeLanguage): TemplatesState {
  switch (action.type) {
    case TemplateActionTypes.TOGGLE_TEMPLATES:
      let templatesShowing = false;
      if (action.payload?.forcedState !== undefined) {
        templatesShowing = action.payload?.forcedState === 'open';
      } else {
        templatesShowing = !state.templatesShowing;
      }
      return {...state, templatesShowing};
    case TemplateActionTypes.SET_TEMPLATES_ENABLED:
      return  {...state, templatesEnabled: action.payload.enabled};
    case TemplateActionTypes.LOAD_TEMPLATE_COMPLETE:
      return {...state, lastTemplateSource: {
          templateId: action.payload.templateId,
          nodeId: undefined,
          language: undefined
        }
      };
    case TemplateActionTypes.UPDATE_OLD_SOURCE_LANGUAGE:
      if (isTemplateId(action.payload.source)) {
        return {
          ...state,
          lastTemplateSource: {
            ...state.lastTemplateSource,
            language: action.payload.language
          }
        }
      } else {
        return {
          ...state,
          lastNonTemplateSource: {
            ...state.lastNonTemplateSource,
            language: action.payload.language
          }
        }
      }
    case TreeActionTypes.LOAD_TREE_SUCCESS:
      if (isTemplateId(action.payload.tree.source)) {
        return {
          ...state,
          lastTemplateSource: {
            ...state.lastTemplateSource,
            nodeId: action.payload.openNode || state.lastTemplateSource.nodeId,
            language: action.payload.language
          }
        }
      } else {
        return {
          ...state,
          lastNonTemplateSource: {
            language: action.payload.language,
            nodeId: action.payload.openNode || state.lastNonTemplateSource?.nodeId
          }
        }
      }
    default:
      return state;
  }
}
