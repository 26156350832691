<div>
  <div *ngFor="let changeBlock of changeEvents">
    <h4 *ngIf="!!changeBlock.nodeId">{{'history.event-block.node-title.0'|translate:nodeChangeBlockTranslation(changeBlock)}}
    <span [class.linkPart]="isActive(changeBlock)" (click)="browseToNode(changeBlock)">{{'history.event-block.node-title.1'|translate:nodeChangeBlockTranslation(changeBlock)}}</span>
      {{'history.event-block.node-title.2'|translate:nodeChangeBlockTranslation(changeBlock)}}
    </h4>
    <h4 *ngIf="!!changeBlock.resourceId">{{'history.event-block.resource-title.0'|translate:resourceChangeBlockTranslation(changeBlock)}}
      <span>{{'history.event-block.resource-title.1'|translate:resourceChangeBlockTranslation(changeBlock)}}</span>
      {{'history.event-block.resource-title.2'|translate:resourceChangeBlockTranslation(changeBlock)}}
    </h4>

    <div>
      <div *ngFor="let event of changeBlock.events" [ngSwitch]="event.eventType">
        <ng-template [ngSwitchCase]="EventTypes.NODE_LABELS_CHANGED">
          <pm-node-labels-changed-renderer [event]="event | convertTo:NodeLabelsChanged"></pm-node-labels-changed-renderer>
        </ng-template>
        <ng-template [ngSwitchCase]="EventTypes.NODE_LINK_ADDED||EventTypes.NODE_LINK_DELETED">
          <pm-node-links-changed-renderer [event]="event | convertTo:NodeLinksChanged"></pm-node-links-changed-renderer>
        </ng-template>
        <ng-template [ngSwitchCase]="EventTypes.RESOURCE_COLOR_CHANGED">
          <pm-resource-color-changed-renderer [event]="event | convertTo:ResourceColorChanged"></pm-resource-color-changed-renderer>
        </ng-template>
        <ng-template ngSwitchDefault>
          <pm-default-history-renderer [event]="event"></pm-default-history-renderer>
        </ng-template>
      </div>
    </div>
  </div>
</div>
