import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface EmbeddedDialogData {
  title: string;
  html: string;
  height: number | string;
  width: number | string;
  preFill?: boolean;
  type: string;
  /**
   * Callback for opening links. Return true to close dialog (e.g. on internal links)
   * @param string
   */
  linkOpen?: (string) => boolean;
}

@Component({
  selector: 'pm-embedded-dialog',
  templateUrl: './embedded-dialog.component.html',
  styleUrls: ['./embedded-dialog.component.css']
})
export class EmbeddedDialogComponent implements OnDestroy {
  showContents = false;

  dialogSubscription = this.dialogRef.afterOpened().subscribe(() => {
    this.showContents = true;
  });

  constructor(public dialogRef: MatDialogRef<EmbeddedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmbeddedDialogData) {
    if (this.data.preFill) {
      this.showContents = true;
    }
  }

  get contents(): string {
    return this.showContents ? this.data.html : '';
  }

  get height(): string {
    if (this.data.height === null) {
      return undefined;
    }

    let height: string = this.data.height as string;
    if (!isNaN(+this.data.height)) {
      height = this.data.height + 'px';
    }

    return this.showContents ? height : '0';
  }

  get width(): string {
    let width: string = this.data.width as string;
    // + in front of a string converts it to a number (strictly)
    if (!isNaN(+this.data.width)) {
      width = this.data.width + 'px';
    }

    return this.showContents ? width : '0';
  }

  get linkType(): string {
    return this.data && this.data.type || '';
  }

  ngOnDestroy(): void {
    this.dialogSubscription.unsubscribe();
  }

  infoClicked(href: string) {
    if(this.data.linkOpen && this.data.linkOpen(href)) {
      this.dialogRef.close();
    }
  }
}
