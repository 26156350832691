import {
  AfterViewInit, Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChildren
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Store} from '@ngrx/store';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {isTemplateId} from '../../../shared/model/template-id';
import {PathElement} from '../../../shared/model/tree';
import {NavigationService} from '../../../shared/services/navigation.service';
import {UpdateTreeElementText} from '../../../state-management/actions/tree.actions';
import {AppState, getAuthIsAdmin, getTreeSource} from '../../../state-management/reducers';

@Component({
  selector: 'pm-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnChanges, AfterViewInit {
  @Input() path: PathElement[];
  @Input() domain: string;

  editText = new FormControl();
  editing = false;

  isAdmin$ = this.store$.select(getAuthIsAdmin);
  currentTreeSource = this.store$.select(getTreeSource);

  @ViewChildren('input') private dropDownDivList: QueryList<ElementRef>;

  constructor(private navigationService: NavigationService, private store$: Store<AppState>) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['path'] && this.path.length > 0) {
      this.cancelEdit();
    }
  }

  ngAfterViewInit(): void {
    this.dropDownDivList.changes.subscribe((list: QueryList<ElementRef>) => {
      if (list.length > 0) {
        list.first.nativeElement.focus();
      }
    });
  }

  getRouterLink(pathElement: PathElement): Observable<string> {
    return this.currentTreeSource.pipe(map(source => {
      if (isTemplateId(source)) {
        return decodeURI(this.navigationService.getTemplateUrl(source, pathElement.id));
      }
      return this.navigationService.getNavigationUrl(pathElement.id);
    }))

  }

  canEdit(pathElement: PathElement) {
    return this.path.indexOf(pathElement) === 0;
  }

  toggleEdit() {
    if (this.editing && this.editText.value !== this.path[0].label) {
      window.setTimeout(() => {
        this.store$.dispatch(new UpdateTreeElementText({
          id: this.path[0].id,
          label: this.editText.value
        }))
      }, 0);
    }
    this.editing = !this.editing;
  }

  shouldHide(pathElement: PathElement) {
    return this.canEdit(pathElement) && this.editing;
  }

  cancelEdit() {
    this.editing = false;
    this.editText.setValue(this.path[0].label);
  }
}
