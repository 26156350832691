import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserService} from '@process-manager/pm-library';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AppConfig} from '../../app.config';
import {Label} from '../model/label';

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  constructor(private http: HttpClient, private userService: UserService, private appConfig: AppConfig) { }

  createLabel(label: Label): Observable<Label> {
    return this.http.post<Label>(environment.api + this.userService.domain + '/labels/', label);
  }

  getLabels(): Observable<Label[]> {
    return this.http.get<Label[]>(environment.api + this.userService.domain + '/labels/');
  }

  updateLabel(label: Label): Observable<Label> {
    return this.http.put<Label>(environment.api + this.userService.domain + '/labels/' + label.id, label);
  }

  deleteLabel(label: Label): Observable<void> {
    return this.http.delete<void>(environment.api + this.userService.domain + '/labels/' + label.id);
  }
}
