import {Component, Inject, OnInit, Optional} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {
  MAT_DIALOG_DATA, MatDialogRef
} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Language} from '@process-manager/pm-library';
import {TemplateId} from '../../shared/model/template-id';
import {
  LanguageMapEntry, TemplateLanguageMappingService
} from '../../shared/services/template-language-mapping.service';
import {TemplatesService} from '../../shared/services/templates.service';
import {AppState, getAuthSiteLanguages} from '../../state-management/reducers';

interface LanguageWithSelection extends Language {
  siteLanguage: number
}

export type TemplateDialogOpenType = 'missing' | 'edit';

export interface TemplateDialogData {
  node?: TemplateId;
  type: TemplateDialogOpenType;
}

@Component({
  selector: 'pm-template-language-dialog',
  templateUrl: './template-language-dialog.component.html',
  styleUrls: ['./template-language-dialog.component.css']
})
export class TemplateLanguageDialogComponent implements OnInit {
  siteLanguages: Language[];

  rememberMapping = true;
  saving = false;
  dataSource: LanguageWithSelection[] = [];

  constructor(private store$: Store<AppState>, private templateService: TemplatesService,
    private router: Router,
    private templateLanguageMapping: TemplateLanguageMappingService,
    @Inject(MAT_DIALOG_DATA) public data: TemplateDialogData,
    @Optional() private dialogRef: MatDialogRef<TemplateLanguageDialogComponent>) {
  }

  get titleKey(): string {
    const keys: {[key in TemplateDialogOpenType]: string} = {
      'missing': 'dialog.template-mapping.missing.title',
      'edit': 'dialog.edit-template-mappings.title'
    }
    return keys[this.data.type];
  }

  get columns(): string[] {
    return ['template', 'customer'];
  }

  get mappingRemembered(): boolean {
    return this.templateLanguageMapping.hasMapping();
  }

  get languageValidator(): ValidationErrors {
    const defaultLanguage = this.siteLanguages?.find(lang => lang.isDefault)?.id || null;
    const defLangMapped = defaultLanguage && this.dataSource.some(lang => lang.siteLanguage === defaultLanguage);
    const multipleLanguages = this.dataSource.filter(lang => !!lang.siteLanguage).map(lang => lang.siteLanguage)
      .filter((id, index, arr) => arr.indexOf(id) !== index);

    if (defLangMapped && multipleLanguages.length === 0) {
      return null;
    }

    const validationErrors = {};

    if (!defLangMapped) {
      validationErrors['default-language-not-mapped'] = true;
    }

    if (multipleLanguages.length > 0) {
      validationErrors['site-language-multi-mapped'] = multipleLanguages;
    }
    return validationErrors;
  }

  async ngOnInit(): Promise<void> {
    this.store$.select(getAuthSiteLanguages).subscribe((siteLanguages) => {
      const mappings = this.templateLanguageMapping.getMapping();
      this.siteLanguages = siteLanguages;
      const templateLanguages = [...Object.values(this.templateLanguageMapping.getTemplateLanguages())];
      this.dataSource = templateLanguages.map(templateLang => {
        let foundSiteLangId: number;
        if (!!mappings) {
          foundSiteLangId = mappings[templateLang.id + '']?.id;
        } else {
          const foundSiteLang = this.siteLanguages.find(siteLang => siteLang.name === templateLang.name) ||
            this.siteLanguages.find(siteLang => siteLang.autoTranslateLanguage === templateLang.autoTranslateLanguage) ||
            this.siteLanguages.find(siteLang => siteLang?.interfaceLanguage === templateLang?.interfaceLanguage) || null;
          foundSiteLangId = foundSiteLang?.id || null
        }
        return {
          ...templateLang,
          siteLanguage: foundSiteLangId || null
        }
      });
    })
  }

  submit() {
    const mapping = this.dataSource.filter(lang => !!lang.siteLanguage)
      .reduce<{[key: string]: LanguageMapEntry}>((acc, value) => {
        acc[value.id.toString(10)] = {id: value.siteLanguage, name: value.name}
        return acc;
      }, {});
    this.templateLanguageMapping.storeMapping(mapping, this.rememberMapping);
    this.dialogRef.close(mapping);
  }

  deleteMappings() {
    this.templateLanguageMapping.clearAllMappings();
    this.dialogRef.close();
  }
}
