import {Injectable} from '@angular/core';
import {Language} from '@process-manager/pm-library';
import * as Immutable from 'immutable';

import {Bullet} from '../model/bullet';
import {Label} from '../model/label';
import {Link} from '../model/link';
import {Process, Size} from '../model/process';
import {Style} from '../model/style';
import {TemplateId} from '../model/template-id';
import {PathElement, TreeWithLanguages} from '../model/tree';
import {TreeElement} from '../model/treeelement';
import {NavigationService} from '../services/navigation.service';
import {TreeBuilderInterface} from './tree-builder';

type TemplateTreeElement = TreeElement & TemplateId;

export function buildLanguages(json: any) {
  const languages = new Map<number, Language>();

  for (const language of json.languages) {
    const key = Number(language.ID);
    languages.set(key, {
      id: key,
      name: language.name,
      interfaceLanguage: language?.interfaceLanguage,
      isDefault: Boolean(language.deflang),
      autoTranslateLanguage: language.autoTranslateLanguage
    });
  }

  return languages;
}

class TemplateTreeBuilder {
  defaultLanguageId = -1;
  constructor(private navigationService: NavigationService, private templateId: TemplateId) {}

  build(json: any): TreeWithLanguages {
    const languages = buildLanguages(json);
    const defaultLanguage = Array.from(languages.values()).find(lang => lang.isDefault);
    this.defaultLanguageId = defaultLanguage.id;
    const styles = this.buildStyles(json);

    const translatedNodes: Map<number, Immutable.Map<string, TemplateTreeElement>>
      = new Map<number, Immutable.Map<string, TemplateTreeElement>>();
    const translatedLinks: Map<number, Immutable.Map<string, Link>> = new Map<number, Immutable.Map<string, Link>>();
    Array.from(languages.values()).forEach(language => {
      const [, nodes] = this.buildNodes(styles, json, language.id);
      translatedNodes.set(language.id, Immutable.Map(nodes));
      translatedLinks.set(language.id,  Immutable.Map(this.buildLinks(json, nodes)));
    });

    const versionTimeDatePart = <string>json['metadata']['version']['creationTime'].split('T')[0];
    const root = String(json.tree.ID);
    return {
      source: {
        templateId: json['metadata']['name'],
        templateVersion: json['metadata']['version']['name'] || versionTimeDatePart
      },
      path: this.buildPath(json),
      localPath: [],
      root: root,
      labels: Immutable.Map(this.buildLabels(json)),
      nodes: translatedNodes.get(defaultLanguage.id),
      links: translatedLinks.get(defaultLanguage.id),
      languages: Array.from(languages.values()),
      translatedNodes: Immutable.Map(translatedNodes),
      translatedLinks: Immutable.Map(translatedLinks)
    };
  }

  private buildStyles(json: any): Map<string, Style> {
    const styles = new Map<string, Style>();

    for (const style of json.styles) {
      const key = String(style.ID);
      styles.set(key, {
        id: key,
        serverId: style.ID,
        lineColor: style.lineColor,
        bgColor: style.bgColor,
        shape: style.shape
      });
    }


    return styles;
  }

  private buildLabels(json: any): Map<number, Label> {
    const labels = new Map<number, Label>();

    for (const label of json.labels) {
      const key = Number(label.ID);
      labels.set(key, {
        id: key,
        name: label.name,
        color: label.color,
        isTrial: !!label.isTrial,
        extension: !!label.extension
      });
    }

    return labels;
  }


  private buildPath(json: any): PathElement[] {
    return [new PathElement(String(json.tree.ID), json.metadata.name)];
  }

  private buildNodes(styles: Map<string, Style>, json: any, languageId: number): [Process, Map<string, TemplateTreeElement>] {
    const nodes = new Map<string, TemplateTreeElement>();
    return [this.buildProcess(nodes, styles, json.tree, null, languageId, []), nodes];
  }

  private buildElement(json: any, parentId: string, styles: Map<string, Style>, languageId: number,
    parentLabels: number[]): TemplateTreeElement {
    const style: Style = styles.get(String(json.styleID));

    const getTranslation = (data: any) => {
      const languageKey = String(languageId);
      if (data !== undefined && Object.keys(data).includes(languageKey)) {
          return data[languageKey];
      } else {
        return undefined;
      }
    }

    const texts = getTranslation(json.texts);
    return {
      id: String(json.ID),
      serverId: json.ID,
      parentId: parentId,
      label: texts?.text || '',
      info: texts?.info || '',
      extendedInfo: texts?.extendedInfo,
      infoColor: json.infoColor,
      style: style,
      type: json.type || 'bullet',
      links: (getTranslation(json.links) || []).map(String),
      labels: json.labels,
      ancestorLabels: parentLabels,
      ...this.templateId
    };
  }

  private buildProcess(nodes: Map<string, TemplateTreeElement>, styles: Map<string, Style>,
                       nodeJson: any, parentId: string, languageId: number, parentLabels: number[]): Process {
    const childSize: Size = new Size(nodeJson.childWidthRatio || 2, nodeJson.childHeightRatio || 1);

    const inheritedLabels = [...new Set([...parentLabels || [], ...nodeJson.labels || []])];
    const builtProcesses = this.buildProcesses(nodes, styles, nodeJson, languageId, inheritedLabels);
    const process: Process & TemplateId = {
      ...this.buildElement(nodeJson, parentId, styles, languageId, parentLabels),
      childSize: childSize,
      nodes: builtProcesses,
      bullets: this.buildBullets(nodes, styles, nodeJson, languageId, inheritedLabels),
      hasUnloadedChildren: false,
      ...this.templateId
    };

    nodes.set(process.id, process);
    return process;
  }

  private buildProcesses(nodes: Map<string, TemplateTreeElement>, styles: Map<string, Style>,
                         nodeJson: any, languageId: number, parentLabels: number[]): string[] {
    const parentId = String(nodeJson.ID);
    if (Array.isArray(nodeJson.children)) {
      return nodeJson.children.filter(child => child.type !== 'bullet')
        .map(child => this.buildProcess(nodes, styles, child, parentId, languageId, parentLabels).id)
    }
    return [];
  }

  private buildBullet(nodes: Map<string, TemplateTreeElement>, styles: Map<string, Style>,
                      nodeJson: any, parentId: string, languageId: number, parentLabels: number[]): Bullet {
    const bullet: Bullet&TemplateId = this.buildElement(nodeJson, parentId, styles, languageId, parentLabels);

    nodes.set(bullet.id, bullet);
    return bullet;
  }

  private buildBullets(nodes: Map<string, TemplateTreeElement>, styles: Map<string, Style>,
                       nodeJson: any, languageId: number, parentLabels: number[]): string[] {
    const parentId = String(nodeJson.ID);
    if (Array.isArray(nodeJson.children)) {
      return nodeJson.children.filter(child => child.type === 'bullet')
        .map(child => this.buildBullet(nodes, styles, child, parentId, languageId, parentLabels).id);
    }
    return [];
  }

  private buildLinks(json: any, nodes: Map<string, TemplateTreeElement>): Map<string, Link> {
    const links = new Map<string, Link>();

    for (const jsonLink of json.resources) {
      const link = this.buildLink(jsonLink, nodes);
      if (!!link) {
        links.set(link.id, link);
      }
    }

    return links;
  }

  private buildLink(link: any, nodes: Map<string, TemplateTreeElement>): Link {
    if (!!link) {
      if (link.type === 'internalLink') {
        if (nodes.has(link.details + '')) {
          const navigationUrl = this.navigationService.getTemplateUrl(this.templateId, link.details);
          link.label = navigationUrl;
          link.details = navigationUrl;
        } else {
          return null;
        }
      }

      return {
        id: link.uuid || String(link.id),
        label: link.label,
        action: link.details,
        linkType: 'http',
      }
    }

    return null;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TemplateTreeBuilderService implements TreeBuilderInterface {

  constructor(private navigationService: NavigationService) {
  }

  build(json: any, extraOptions: {templateId: TemplateId}): TreeWithLanguages {
    return new TemplateTreeBuilder(this.navigationService, extraOptions.templateId).build(json);
  }
}
