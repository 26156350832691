import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Link} from '../../../shared/model/link';
import {convertToPixelSize, Size} from '../../../shared/model/process';
import {RenderedElement} from '../../../shared/model/rendered/rendered-element';
import {RenderedProcess} from '../../../shared/model/rendered/rendered-process';
import {ActivationType} from '../../../state-management/reducers/tree.reducer';

@Component({
  selector: 'pm-row',
  templateUrl: './pm-row.component.html',
  styleUrls: ['./pm-row.component.css']
})
export class PmRowComponent implements OnInit {
  @Input() rowNode: RenderedProcess;
  @Input() isMainPage: boolean;
  @Input() isTemplateRoot = false;
  @Input() rowProcessSize: Size;
  @Input() openRows: RenderedProcess[];
  @Input() dragDropActive = true;
  @Input() isAuthor: boolean;
  @Output() activationChange = new EventEmitter<{ element: RenderedElement, type: ActivationType }>();
  @Output() updated = new EventEmitter<{ element: RenderedElement, content: ActivationType }>();
  @Output() resized = new EventEmitter<{ element: RenderedElement, size: Size }>();
  @Output() linkClick = new EventEmitter<{ element: RenderedElement, link:  Link|string }>();
  @Output() elementClick = new EventEmitter<RenderedElement>();

  constructor() {
  }

  get canDrop(): boolean {
    return this.dragDropActive && this.rowNode.writeable
  }

  ngOnInit(): void {
  }

  getItemId(index: number, item: RenderedProcess): string {
    return item ? item.id : '';
  }

  getRowHeight(rowNode: RenderedProcess): string {
    return (rowNode?.childSize && convertToPixelSize(rowNode.childSize).height || Size.BASE_HEIGHT) + 'px';
  }
}
