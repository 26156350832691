import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PositioningService {
  constructor() {
  }

  doCheck(measuringElement: HTMLElement, targetElement = measuringElement): boolean {
    if(!!measuringElement) {
      const elementLeft = measuringElement.getBoundingClientRect().left;
      const tooRight = document.documentElement.clientWidth -
        (elementLeft + measuringElement.offsetWidth) < 5;

      if (tooRight) {
        targetElement.classList.add('pm-end');
      }

      return tooRight;
    } else {
      console.warn('PositioningService: Missing measuring element? Cannot check for end-of-row');
      return false;
    }
  }

  clearPositioning(targetElement: HTMLElement) {
    targetElement.classList.remove('pm-end');
  }
}
