import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';
import {filter} from 'rxjs/operators';
import {PasswordUpdate} from '../../shared/components/password-change-component/password-change.component';

@Component({
  selector: 'pm-login-new-password-form',
  templateUrl: './new-password-login.component.html',
  styleUrls: ['./new-password-login.component.css']
})
export class NewPasswordLoginComponent implements OnInit {
  username: string;
  error: string;
  domain: string;
  private token: string;
  private email: string;

  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService,
    private translateService: TranslateService) {
  }

  get changePasswordTranslateParam() {
    return {
      username: this.username,
      domain: this.domain
    }
  }
  ngOnInit(): void {
    this.translateService.setDefaultLang('en_gb');
    this.translateService.use(this.translateService.getBrowserCultureLang().toLowerCase().replace('-', '_'));

    this.route.queryParams.pipe(filter(params => !!params.domain && !!params.email && !!params.token)).subscribe(params => {
      this.domain = params.domain;
      this.email = params.email;
      this.token = params.token;
      this.username = params.username;
    })
  }

  onSubmit($event: PasswordUpdate) {
    this.userService.updatePasswordFromToken(this.domain, this.email, this.token, $event.newPassword).subscribe(() => {
      this.router.navigate(['/authentication']);
    }, error => {
      if (error.status === 404) {
        this.error = this.translateService.instant('new-password.error.email.not-found');
      } else if (error.status === 401 && error.error.errorcode === 506) {
        this.error = this.translateService.instant('new-password.error.email.expired');
      } else {
        this.error = this.translateService.instant('new-password.error.email.unknown-error');
      }
    });

  }
}
