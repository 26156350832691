import {Action} from '@ngrx/store';
import {SiteSettings, UserService} from '@process-manager/pm-library';
import {Authenticate, PasswordReset, User} from '@process-manager/pm-library/lib/model/user';

export const DOMAIN_CHOSEN = '[Auth] Domain chosen';
export const LOGIN = '[Auth] Login';
export const REQUEST_RESET = '[Auth] Request reset';
export const REQUEST_LOGOUT = '[Auth] Request Logout';
export const LOGOUT = '[Auth] Logout';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_FAILURE = '[Auth] Login Failure';
export const LOGIN_REDIRECT = '[Auth] Login Redirect';
export const UPDATE_SETTINGS = '[Auth] Update site settings';
export const OFFLINE = '[Auth] Going offline';

export class DomainChosen implements Action {
  readonly type = DOMAIN_CHOSEN;

  constructor(public payload: { domain: string; returnUrl: string; local?: boolean}) {
  }
}

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: { auth: Authenticate }) {
  }
}

export class PasswordResetRequest implements Action {
  readonly type = REQUEST_RESET;

  constructor(public payload: { resetRequest: PasswordReset; language: string }) {
  }
}

export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(public payload: {
    user: User, returnUrl: string, redirect: boolean, siteSettings: SiteSettings,
    currentLanguage?: number, passwordIsTemporary?: boolean, domain: string, isOnline: boolean
  }) {
    this.payload.currentLanguage = this.payload.currentLanguage || Number(localStorage.getItem(payload.domain + UserService.LANGUAGE_SELECTED_POSTFIX));
  }
}

export class LoginFailure implements Action {
  readonly type = LOGIN_FAILURE;

  constructor(public payload: {  domain?: string, publicUser?: string, error?: Error, errorDetails ?: string }) {
  }
}

export class LoginRedirect implements Action {
  readonly type = LOGIN_REDIRECT;

  constructor(public payload: { publicUser: any; domain: any; returnUrl: string; local: boolean }) {
  }
}

export class RequestLogout implements Action {
  readonly type = REQUEST_LOGOUT;
  constructor(public payload: { checkChanges: boolean }) {
  }
}

export class UpdateSettings implements Action {
  readonly type = UPDATE_SETTINGS;

  constructor(public payload: { settings: SiteSettings }) {
  }
}

export class Offline implements Action {
  readonly type = OFFLINE;

  constructor() {
  }
}
export class Logout implements Action {
  readonly type = LOGOUT;

  constructor(public payload: { domain: string}) {
  }
}

export type AuthenticationActions = DomainChosen |Login | LoginSuccess | LoginFailure | LoginRedirect | RequestLogout | UpdateSettings
  | Logout | Offline;
