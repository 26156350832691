import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'pm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private translateService: TranslateService) {
    translateService.setDefaultLang('en_gb');
    translateService.use(translateService.getDefaultLang());

    let lang = this.translateService.getBrowserCultureLang().toLowerCase().replace('-', '_');
    if(lang.startsWith('en') || lang.startsWith('da')) {
      this.translateService.use(lang);
    }
  }
}
