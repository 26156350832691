import {EMPTY, iif, merge, NEVER, Observable, of, timer} from 'rxjs';
import {delay, retryWhen, share, switchMap, take, takeUntil} from 'rxjs/operators';

export interface PollConfig {
  /**
   * The interval between request
   */
  requestInterval?: number,

  /**
   * Number of retries
   */
  retryCount?: number,

  /**
   * The initial (and currently the constant) retry delay.
   */
  initialRetryDelay?: number,

  /**
   * An emit on this observable will force an instant reload.
   */
  forceReload?: Observable<any>,

  /**
   * An emit on this observable will stop all reloads
   */
  forceStop?: Observable<any>

  /**
   * Emitting true on this observable will pause reloads until a false is emitted
   */
  pause?: Observable<boolean>

}

/**
 *
 * @param request A callback that returns the request
 * @param options Configuration for polling
 */
export const startPolling = (request: () => Observable<any>, options: PollConfig = {}) => {
  const {
    requestInterval = 60 * 1000,
    retryCount = 5,
    initialRetryDelay = 1000,
    forceReload = NEVER,
    forceStop = NEVER,
    pause = of(false)
  } = options;
  return pause.pipe(switchMap(val => iif(() => !val, merge(timer(0, requestInterval), forceReload), EMPTY)))
    .pipe(switchMap(() => request()), retryWhen((error$) => error$.pipe(delay(initialRetryDelay), take(retryCount))), share(), takeUntil(forceStop));
}
