// noinspection JSUnusedGlobalSymbols

import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {UserService} from '@process-manager/pm-library';
import * as Immutable from 'immutable';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap, skip, switchMap, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {PopupDialogComponent, PopupDialogData} from '../../shared/components/popup-dialog/popup-dialog.component';
import {FavoriteService} from '../../shared/services/favorite.service';
import {LOGIN_SUCCESS} from '../actions/auth.actions';
import {
  ADD_FAVORITE,
  AddFavorite,
  LOAD_FAVORITES,
  LOAD_FAVORITES_ERROR,
  LoadFavorites,
  LoadFavoritesError,
  LoadFavoritesSuccess,
  REMOVE_FAVORITE,
  RemoveFavorite,
  STORE_FAVORITES,
  STORE_FAVORITES_ERROR,
  StoreFavorites,
  StoreFavoritesError,
  StoreFavoritesSuccess
} from '../actions/favorite.actions';
import {AppState, getAuthUser, selectFavorites} from '../reducers/';

@Injectable()
export class FavoriteEffects {
  public static readonly UNKNOWN_ERROR_STRING = 'login.error.unknown';

  loadFavorites$: Observable<Action> = createEffect(
    () => this.actions$.pipe(ofType<LoadFavorites>(LOAD_FAVORITES), withLatestFrom(this.store$.select(selectFavorites)),
      switchMap(([, favorites]) => {
        if(!window.navigator.onLine) {
          return of(new LoadFavoritesSuccess(Immutable.Map([])))
        }
        const nextSearch$ = this.actions$.pipe(ofType(LOAD_FAVORITES)).pipe(skip(1));

        if (favorites) {
          return of(new LoadFavoritesSuccess(favorites));
        }

        return this.favoriteService.getFavorites('', this.userService.domain).pipe(takeUntil(nextSearch$),
          map(loadedFavorites => new LoadFavoritesSuccess(Immutable.Map(loadedFavorites.map(item => [item.id, item])))),
          catchError(err => of(new LoadFavoritesError(err))));
      })));

  storeFavorites$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType<StoreFavorites>(STORE_FAVORITES),
    withLatestFrom(this.store$.select(getAuthUser), this.store$.select(selectFavorites)),
    mergeMap(([, user, favorites]) => {
      return this.favoriteService.storeFavorites(user.username, this.userService.domain, favorites.keySeq().toArray())
        .pipe(map(() => new StoreFavoritesSuccess()), catchError(error => of(new StoreFavoritesError(error))));
    })));

  load_error$ = createEffect(() => this.actions$.pipe(
    ofType<LoadFavoritesError | StoreFavoritesError>(LOAD_FAVORITES_ERROR, STORE_FAVORITES_ERROR)).pipe(tap(action => {
    let message = action.payload.message;
    if (action.payload instanceof HttpErrorResponse && !action.payload.status) {
      message = FavoriteEffects.UNKNOWN_ERROR_STRING;
    }

    this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {data: {body: message}});
  })), {dispatch: false});

  loadOnLoginSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(LOGIN_SUCCESS), switchMap(() => of(new LoadFavorites()))));

  changedFavorites$ = createEffect(
    () => this.actions$.pipe(ofType<AddFavorite | RemoveFavorite>(ADD_FAVORITE, REMOVE_FAVORITE),
      switchMap(() => of(new StoreFavorites()))));

  constructor(private actions$: Actions, private favoriteService: FavoriteService, private store$: Store<AppState>,
    private dialog: MatDialog, private userService: UserService) {
  }
}
