<button *ngIf="isAdmin$ | async" mat-icon-button class="edit-button" (click)="toggleEdit()">
  <mat-icon title="Edit top node name" *ngIf="!editing">edit</mat-icon>
  <mat-icon title="Done editing" *ngIf="editing">check</mat-icon>
</button>
<button *ngIf="editing" mat-icon-button (click)="cancelEdit()">
  <mat-icon title="Cancel editing">close</mat-icon>
</button>
<!--suppress HtmlFormInputWithoutLabel -->
<input #input [formControl]="editText" type="text" *ngIf="editing" [value]="path[0].label">
<ng-container *ngFor="let pathElement of path">
<a mat-button *ngIf="!shouldHide(pathElement)" class="breadcrumb" [routerLink]="getRouterLink(pathElement) | async">{{
  pathElement.label || '&nbsp;'}}</a>
</ng-container>
