import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Store} from '@ngrx/store';
import {AppConfig} from '../../../app.config';
import {NodeType} from '../../../shared/model/treeelement';
import {NavigationService} from '../../../shared/services/navigation.service';
import {ActivateElement} from '../../../state-management/actions/tree.actions';
import {AppState, getAuthUserIsPublic} from '../../../state-management/reducers';

interface Details {
  label: string;
  id: number;
  type: NodeType;
  pos: number;
  path: string;
  parent: number;
  parentPage: number;
  extraData: {
    language: number;
    key: string;
  }
}

export interface PublishErrorData {
  errorCode: string;
  details?: Details[]
}

@Component({
  selector: 'pm-publish-error-dialog',
  templateUrl: './publish-error-dialog.component.html',
  styleUrls: ['./publish-error-dialog.component.css']
})
export class PublishErrorDialogComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<Details>();
  activeSearchElement: any = null;

  isPublic: boolean;
  isPublicSubscription = this.store$.select(getAuthUserIsPublic).subscribe(isPublic => this.isPublic = isPublic);

  displayedColumns = ['label', 'path', 'type'];
  folded = false;

  constructor(private appConfig: AppConfig, private navigationService: NavigationService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: PublishErrorData, private store$: Store<AppState>) {
  }

  ngOnInit(): void {
    this.dataSource.data = this.data?.details || [];
  }

  ngOnDestroy() {
    this.isPublicSubscription.unsubscribe();
  }

  navigateToNode(searchElement: Details) {
    this.activeSearchElement = searchElement.id;
    this.appConfig.highlightNext = true;
    if (['page', 'mainpage'].includes(searchElement.type)) {
      this.navigationService.navigateToNode(searchElement.parent, this.isPublic).then(() => this.store$.dispatch(
        new ActivateElement({
          id: searchElement.id.toString(10),
          activationType: 'highlight'
        })))

    } else {
      this.navigationService.navigateToNode(searchElement.id, this.isPublic);
    }
  }

}
