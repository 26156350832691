import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ColorEvent} from 'ngx-color';

type ChangedHandlerFunction = (change: any) => void;
type TouchedHandlerFunction = () => void;

@Component({
  selector: 'pm-color-picker-input',
  templateUrl: './color-picker-input.component.html',
  styleUrls: ['./color-picker-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ColorPickerInputComponent), multi: true
  }]
})
export class ColorPickerInputComponent implements OnInit, ControlValueAccessor {
  colorOverlayOpen=false;
  constructor() { }

  @Input()
  color: string = 'white';

  @Output()
  colorChanged: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
  }

  _disabled: boolean = false;

  readonly errorState = false;
  readonly focused: boolean;
  readonly id: string;
  readonly required: boolean;

  get disabled(): boolean {
    return this._disabled;
  }

  onTouched: TouchedHandlerFunction = () => {
  };

  onChange: ChangedHandlerFunction = () => {
  };

  registerOnChange(fn: ChangedHandlerFunction): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: TouchedHandlerFunction): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(obj: string): void {
    this.color = obj;
  }

  colorChange(colorEvent: ColorEvent): void {
    this.writeValue(colorEvent.color.hex);
  }

  onColorClose() {
    this.colorOverlayOpen = false;
    this.colorChanged.emit(this.color);
    this.onChange(this.color);
  }
}
