<button class="pm-language-selector-button" mat-flat-button [matMenuTriggerFor]="languageMenu">
  {{languageName}} <span *ngIf="isDefault" class="mat-small">{{'language-selector.default-lang' | translate}}</span>
  <mat-icon>keyboard_arrow_down</mat-icon>
</button>

<mat-menu #languageMenu="matMenu">
  <button mat-menu-item *ngFor="let language of languages" (click)="selectLanguage(language)" [disabled]="!languageIsMapped(language)">
    {{language.name}} <span *ngIf="language.isDefault" class="mat-small">{{'language-selector.default-lang' | translate}}</span>
  </button>
</mat-menu>
