import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Language} from '@process-manager/pm-library';
import {take, withLatestFrom} from 'rxjs/operators';
import {AppState, getLabelsSettings, getTreeLabels, HighlightSelectionType} from '../../../state-management/reducers';

import {LabelSettings} from '../../../state-management/reducers/view-options.reducer';
import {
  LabelOptionsDialogComponent, LabelsOptionsDialogData
} from '../label-options-dialog/label-options-dialog.component';
import {LanguageChange} from '../language-selector';

@Component({
  selector: 'pm-view-options-menu',
  templateUrl: './view-options-menu.component.html',
  styleUrls: ['./view-options-menu.component.css']
})
export class ViewOptionsMenuComponent {
  @Input() currentLanguage: Language;
  @Input() translationHighlight: HighlightSelectionType = 'off';
  @Input() labelSettings: LabelSettings = {
    show: true,
    inherit: false,
    descendents: false,
    enableFilter: false
  };

  @Input() showHighlightButton = true;
  @Output() languageChange: EventEmitter<LanguageChange> = new EventEmitter();
  @Output() labelChange: EventEmitter<LabelSettings> = new EventEmitter();
  @Output() highlightChange: EventEmitter<HighlightSelectionType> = new EventEmitter();

  constructor(private matDialog: MatDialog, private store: Store<AppState>) {
  }

  get isDefaultLanguage(): boolean {
    return this.currentLanguage && this.currentLanguage.isDefault;
  }

  highlightSelected(type: HighlightSelectionType) {
      this.highlightChange.emit(type);
  }

  openLabelsDialog() {
    this.store.select(getTreeLabels).pipe(withLatestFrom(this.store.select(getLabelsSettings)), take(1)).subscribe(([labels, settings]) => {
      this.matDialog.open<LabelOptionsDialogComponent, LabelsOptionsDialogData, LabelSettings>(LabelOptionsDialogComponent, {
        data: {
          availableLabels: labels,
          currentSettings: settings
        }
      }).afterClosed().subscribe((value) => {
        this.labelChange.emit(value);
      })
    });
  }
}
