<h1 mat-dialog-title>{{'dialog.statistics.title' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <div class="statistics-description">{{'dialog.statistics.description' | translate}}</div>
  <mat-form-field>
    <mat-label>{{'dialog.statistics.period.select' | translate}}</mat-label>
    <mat-select [(ngModel)]="selectedPeriod" (selectionChange)="fetch()">
      <mat-option *ngFor="let period of periodOptions" [value]="period">
        {{'dialog.statistics.period.' + period | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="labels tree-flex">
    <span class="name"></span>
    <span class="own" title="{{'dialog.statistics.self.tooltip' | translate}}">{{'dialog.statistics.self' | translate}}</span>
    <span class="total" title="{{'dialog.statistics.total.tooltip' | translate}}">{{'dialog.statistics.total' | translate}}</span>
  </div>

  <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" [matTreeNodePadding]="null" matTreeNodePaddingIndent="20" class="tree-flex"
                   fxLayoutGap="0.5em">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.label"
              [disabled]="!hasChild(0, node)" fxFlex="0 0 auto">
        <mat-icon *ngIf="hasChild(0, node)" class="mat-icon-rtl-mirror">{{treeControl.isExpanded(node) ?
          'expand_more'
          : 'chevron_right'}}
        </mat-icon>
      </button>
      <span class="name">{{node.data.label}}</span>
      <span class="own">{{node.data.selfCount}}</span>
      <span class="total">{{node.totalCount}}</span>
    </mat-tree-node>
  </mat-tree>
</div>
