import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SwUpdate} from '@angular/service-worker';
import {Store} from '@ngrx/store';
import {interval} from 'rxjs';
import {withLatestFrom} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AppState, getTreeHasUndo} from '../../state-management/reducers';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {
  readonly TEST_INTERVAL = 15_000; //ms;
  readonly PRODUCTION_INTERVAL = 5 * 60_000; // ms

  constructor(private swUpdate: SwUpdate, private snackbar: MatSnackBar, private store$: Store<AppState>,
              private translate: TranslateService) {
    this.checkServices();
  }

  private checkServices(): void {
    if (this.swUpdate.isEnabled) {
      interval(environment.production ? this.PRODUCTION_INTERVAL : this.TEST_INTERVAL).subscribe(async () => {
        if(window.navigator.onLine) {
          await this.swUpdate.checkForUpdate();
        }
      })

      this.swUpdate.versionUpdates.pipe(withLatestFrom(this.store$.select(getTreeHasUndo)))
        .subscribe(([event, hasUndo]) => {
          if (!hasUndo && event.type === 'VERSION_READY') {
            console.log('New update ready');
            this.updateIsReady();
          }
        })
    }
  }

  private updateIsReady() {
    this.snackbar.open(this.translate.instant('updater.new-version'),
      this.translate.instant('updater.new-version.refresh-button'), {
      politeness: 'polite',
    }).afterDismissed().subscribe(result => {
      if (result.dismissedByAction) {
        document.location.reload();
      }
    });
  }
}
