import {HttpClient} from '@angular/common/http';
import {ApplicationRef, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first, switchMap, timeout} from 'rxjs/operators';

interface Provider {
  name: string;
  patterns: string[];
}

export interface EmbedDetails {
  error?: string;
  width?: number;
  author_name?: string;
  author_url?: string;
  version?: string;
  provider_url?: string;
  provider_name?: string;
  thumbnail_width?: number;
  thumbnail_url?: string;
  height?: number;
  thumbnail_height?: number;
  html?: string;
  url?: string;
  type?: string;
  title: string;
}

const getWindowDimensions = () => {
  const width = Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );

  const height = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );

  return { width, height };
};

@Injectable()
export class NoEmbedService {
  readonly NOEMBED_TIMEOUT_PROVIDER_LIST = 1500;
  readonly NOEMBED_TIMEOUT_EMBED = 5000;

  readonly NOEMBED_EMBED_URL = 'https://noembed.com/embed';
  readonly NOEMBED_PROVIDERS_URL = 'https://noembed.com/providers';
  // noinspection SpellCheckingInspection
  readonly NOEMBED_BAD_PROVIDERS = ['https?://(www\\.)?facebook\\.com/'
  ].map((provider) => new RegExp(provider, 'i'));

  providers: Provider[] = [];

  constructor(private http: HttpClient, private appRef: ApplicationRef) {
  }

  private providerLoader$: Observable<Provider[]> = this.appRef.isStable.pipe(first(stable => stable),
    switchMap(() => this.http.jsonp<Provider[]>(this.NOEMBED_PROVIDERS_URL, 'callback')
      .pipe(timeout(this.NOEMBED_TIMEOUT_PROVIDER_LIST))));

  load(): void {
    if(window.navigator.onLine) {
      this.providerLoader$.subscribe(data => this.providers = data,(reason) => {
        this.providers = [];
        console.warn('Could not load embed providers. Using simple links instead of embedding. Error details: ',
          reason?.message, reason);
      });
    }
  }

  public hasProvider(url: string): boolean {
    if (!window.navigator.onLine || !this.providers || this.NOEMBED_BAD_PROVIDERS.some(provider => provider.test(url))) {
      return false;
    }

    return this.providers.some(provider => {
      return provider.patterns.some(pattern => {
        return new RegExp(pattern).test(url);
      });
    })
  }

  public getEmbedDetails(url: string, maxWidth?: number, maxHeight?: number): Observable<EmbedDetails> {
    const windowSize = getWindowDimensions();
    const encodedUrl = `${this.NOEMBED_EMBED_URL}?url=${encodeURIComponent(
      url)}&maxwidth=${maxWidth || Math.floor(windowSize.width * 0.8)}&maxheight=${maxHeight ||
    Math.floor(windowSize.height * 0.8)}`;
    return this.http.jsonp<EmbedDetails>(encodedUrl, 'callback').pipe(timeout(this.NOEMBED_TIMEOUT_EMBED));
  }
}
