import * as Immutable from 'immutable';
import {AuthenticationActions, LOGOUT} from '../actions/auth.actions';
import {Favorite} from '../../shared/services/favorite.service';
import {
  ADD_FAVORITE, FavoriteActions, LOAD_FAVORITES, LOAD_FAVORITES_SUCCESS, REMOVE_FAVORITE
} from '../actions/favorite.actions';

export interface FavoritesState {
  favorites: Immutable.Map<string, Favorite>;
  favoritesLoading: boolean;
  favoritesLoaded: boolean;
}

export const initialState: FavoritesState = {
  favorites: null,
  favoritesLoading: false,
  favoritesLoaded: false
};

export function favoritesStoreReducer(state = initialState,
                                      action: AuthenticationActions | FavoriteActions): FavoritesState {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case LOAD_FAVORITES:
      return {
        ...state,
        favoritesLoading: true,
        favoritesLoaded: false
      };
    case LOAD_FAVORITES_SUCCESS:
      return {
        ...state,
        favorites: action.payload,
        favoritesLoading: false,
        favoritesLoaded: true
      };
    case ADD_FAVORITE:
      return {
        ...state,
        favorites: state.favorites.set(action.payload.favorite.id, action.payload.favorite)
      };
    case REMOVE_FAVORITE:
      return {
        ...state,
        favorites: state.favorites.delete(action.payload)
      };
    default:
      return state;
  }
}

export const getFavorites = (state: FavoritesState) => state.favorites;
export const getFavoriteList = (state: FavoritesState) => state.favorites ? state.favorites.toIndexedSeq().toArray() : [];
