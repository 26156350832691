import {AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn} from '@angular/forms';
import {combineLatest, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

export class PmValidators {
  static asyncUniqueNameValidator = (usedNames: Observable<string[]>, originalName: Observable<string>): AsyncValidatorFn => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return combineLatest([usedNames, originalName]).pipe(take(1),map(([usedNames, originalName]) => PmValidators.uniqueNameValidator(usedNames, originalName)(control)));
    }
  }

  static uniqueNameValidator = (usedNames: string[], originalName?: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const currentName = (control?.value + '')?.toLowerCase();
      if (!!control?.value && (originalName + '')?.toLowerCase() !== currentName && usedNames.map(name => (name + '')?.toLowerCase()).includes(currentName)) {
        return {notUnique: true};
      } else {
        return null;
      }
    }
  }
}
