<mat-card appearance="outlined">
  <mat-card-content>
    <mat-toolbar color="primary">{{'login.change-password.title' | translate}}</mat-toolbar>
    <p>{{'login.change-password.description' | translate}}</p>
    <p>
      <span innerHtml="{{'login.change-password.domain' | translate:changePasswordTranslateParam}}"></span><br>
      <span innerHtml="{{'login.change-password.username' | translate:changePasswordTranslateParam}}"></span>
    </p>
    <pm-password-change #passwordChange presetCurrentPassword="not_needed" (submitted)="onSubmit($event)" [username]="username">
    </pm-password-change>
    <button mat-raised-button (click)="passwordChange.submit()" [disabled]="!passwordChange.valid">{{'dialog.password-change.change-password' | translate}}
    </button>

    <div class="pw-change-error" *ngIf="!!error">
      <p>{{error | translate}}</p>
      <a class="cancel-link" [routerLink]="['/authentication']" [queryParams]="{resetPassword:'true'}">{{ 'login.reset.retry' | translate}}</a>
    </div>
    <a class="cancel-link" [routerLink]="['/domains', domain]">{{ 'button.cancel' | translate}}</a>
  </mat-card-content>
</mat-card>
