import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Language} from '@process-manager/pm-library';
import {Entry} from '../../../shared/services/dictionary.service';

const translatedFn: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const enough = Object.values(control.controls).filter(control => !!control.value).length >= 2;
  return !enough ? { noTranslation: true } : null;
};

@Injectable({
  providedIn: 'root'
})
export class DictionaryControlService {
  private readonly LANG_PREFIX = 'lang-';

  constructor() {
  }

  getColumnId(lang: Language) {
    return this.LANG_PREFIX + lang.id;
  }

  createRow(languages: Language[], entry: Entry = null): FormGroup {
    const row: any = {};
    languages.filter(lang => !!lang.autoTranslateLanguage).forEach(lang => {
      const columnId = this.getColumnId(lang);
      const validators = [];
      if (lang.isDefault) {
        validators.push(Validators.required);
      }
      row[columnId] = new FormControl(entry && entry[columnId] || '', validators);
    });
    return new FormGroup(row, translatedFn);
  }

  sortEntry(defaultLanguage: string) {
    return (a: Entry, b: Entry): number => {
      return a[defaultLanguage]?.localeCompare(b[defaultLanguage]) || 0;
    };
  }

  toFormArray(languages: Language[], dictionaryFormArray: FormArray, dictionary: Entry[]): void {
    const defLang = languages.find(lang => lang.isDefault);
    const defLangAutoTranslateLanguage = defLang?.autoTranslateLanguage;

    if (!!defLangAutoTranslateLanguage) {
      dictionary.sort(this.sortEntry(this.getColumnId(defLang))).forEach((entry) => {
        dictionaryFormArray.push(this.createRow(languages, entry));
      });
    }
  }

  groupDuplicateValidator = (defaultLanguage: Language) => (control: FormArray) => {
    const allDefLangControls = control.controls.map((control: FormGroup) => control.get(this.LANG_PREFIX + defaultLanguage.id));

    const words = allDefLangControls.map(ctrl=> ctrl.value)
    const duplicate = words.filter((word, index) => words.indexOf(word, index + 1) != -1);
    allDefLangControls.forEach(ctrl => {
      if(duplicate.includes(ctrl.value)) {
        ctrl.setErrors({
          ...(ctrl.errors || {}),
          duplicatesWord: true
        });
      } else if (!!ctrl.errors) {
        const errors = ctrl.errors;
        delete errors.duplicatesWord;
        if (Object.keys(errors).length == 0) {
          ctrl.setErrors(null);
        } else {
          ctrl.setErrors(errors);
        }
      }
    });
    // Already taken care of, no need to mark array as well
    return null;
  }

}
