import {Component} from '@angular/core';
import {LicenseParserService} from './license-parser.service';

@Component({
  selector: 'pm-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.css']
})
export class AboutDialogComponent {
  dataSource = this.licenseParser.getLicenses();
  displayedColumns = ['project', 'licenses', 'repository', 'publisher'];

  constructor(private licenseParser: LicenseParserService) {
  }
}
