<h1 mat-dialog-title>{{'dialog.save-warnings.title' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p>{{'dialog.save-warnings.intro' | translate}}</p>
  <mat-list>
    <div mat-list-item *ngFor="let warning of warnings">
      <ng-container [ngSwitch]="warning.code">
        <span [innerHTML]="'dialog.save-warnings.code.' + warning.action + '.' + warning.code | translate: {node: (warning.resolvedSubject | async)}"></span>
        <ng-container *ngSwitchCase="'nodes_mapped_to_users'">
          <div *ngFor="let user of (warning.resolvedDetails | async)" class="indent">
            {{ user.name }} <span class="pm-fullname">{{ user.fullName}}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </mat-list>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="ok" cdkFocusInitial>{{'button.ok' | translate}}</button>
</div>
