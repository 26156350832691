<div class="pm-full-size">
  <pm-logo (watermarkClicked)="deactivate()"></pm-logo>
  <pm-toolbars (menuClicked)="menuClicked()">
  </pm-toolbars>

  <mat-sidenav-container [class.iOS]="isIOS">
    <mat-sidenav mode="over" #navMenu>
      <h3 class="proman-user-name">{{ getUserFullName(user$ | async) }}</h3>
      <mat-nav-list>
        <ng-container *ngIf="online | async">
          <a mat-list-item (click)="changePasswordClick()" *ngIf="(canChangePassword | async)">{{'dialog.password-change.title' | translate}}</a>
          <a mat-list-item *ngIf="isUserAdmin(user$ | async)" (click)="userAdminClick()">{{userAdminTitleKey | translate}}</a>
          <a mat-list-item *ngIf="isAdmin(user$ | async)" (click)="languageAdminClick()">{{'dialog.language-admin.title' | translate}}</a>
          <a mat-list-item *ngIf="isAdmin(user$ | async)" (click)="labelAdminClick()">{{'dialog.label-admin.title' | translate}}</a>

          <a mat-list-item *ngIf="isAdmin(user$ | async) && isVendor" (click)="templateAdminClick()">{{'dialog.template-admin.title' | translate}}</a>
          <a mat-list-item *ngIf="isAdmin(user$ | async) && isPurchase" (click)="subscriptionAdminClick()">{{'dialog.subscription.title' | translate}}</a>
          <a mat-list-item *ngIf="isAdmin(user$ | async) && isTrial" [href]="buyNowLink" target="_blank">{{'main.subscription.buy' | translate}}</a>
          <a mat-list-item [matMenuTriggerFor]="reportMenu">{{'main.reports' | translate}}</a>
          <mat-menu #reportMenu="matMenu" xPosition="after">
            <a mat-menu-item *ngIf="isAdmin(user$ | async)" (click)="ownerReportClick()">{{'dialog.owner-report.title' | translate}}</a>
            <a mat-menu-item *ngIf="isAdmin(user$ | async)" (click)="permissionReportClick()">{{'dialog.permissions-list.title' | translate}}</a>
            <a mat-menu-item *ngIf="isAdmin(user$ | async)" (click)="linkCheckClick()">{{'dialog.link-check.title' | translate}}</a>
            <a mat-menu-item *ngIf="isAdmin(user$ | async)" (click)="openStatistics()">{{'dialog.statistics.title' | translate}}</a>
          </mat-menu>

          <a mat-list-item *ngIf="hasSiteMap" (click)="openSiteMap()">{{'main.sitemap' | translate}}</a>
          <hr>
          <a mat-list-item (click)="onEditMappings()" *ngIf="isModern">{{'dialog.edit-template-mappings.title' | translate}}</a>
          <a mat-list-item *ngIf="showImageOption$ | async" (click)="onEditLogoClick()">{{'menu.toolbar.edit-logo-watermark' | translate}}</a>
        </ng-container>

        <a mat-list-item [href]="helpUrl((currentLanguage | async))" target="_blank">{{'menu.toolbar.help' | translate}}</a>

        </mat-nav-list>
      <div class="offline-options mat-typography" *ngIf="offlineEnabled">
        <div class="offline-selector">
          <div class="online-label">Online</div>
          <mat-slide-toggle [(ngModel)]="isOffline" (change)="offlineChanged()">Offline</mat-slide-toggle>
        </div>
        <div><small>
          <mat-checkbox [(ngModel)]="detectOffline" (change)="detectChanged()">Detect when offline</mat-checkbox>
        </small></div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="pm-main-content" [class.isTemplate]="isTemplate$ | async">
      <pm-process-tree class="pm-process-tree" [domain]="domain$ | async" [tree]="tree$ | async" [isAuthor]="isAuthor$ | async"
                       [openRows]="openRows$ | async" [nodeActivation]="nodeActivation$| async"></pm-process-tree>
      <div *ngIf="isTemplate$ | async" class="pm-template-watermark">{{'watermark.template' | translate}}</div>
      <div *ngIf="(online | async) && !(isTemplate$ | async) && (isTrial || isQuoteTrial)" class="pm-trial-watermark">{{'watermark.trial' | translate}}</div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
