import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {NodeType} from '../model/treeelement';

export interface StatisticsNodeData {
  id: string;
  parentId: string;
  label: string;
  type: NodeType;
  sortOrder: number;
  selfCount: number;
}

export interface StatisticsNode {
  data: StatisticsNodeData;
  descendantsCount: number;
  children: StatisticsNode[];
}

export interface FlatStatisticsNode {
  data: StatisticsNodeData;
  expandable: boolean;
  level: number;
  totalCount: number;
}

export const treeFlattenerTransformerFunction = (node: StatisticsNode, level: number): FlatStatisticsNode => {
  return {
    data: node.data,
    expandable: !!node.children && node.children.length > 0,
    level: level,
    totalCount: node.data.selfCount + node.descendantsCount
  }
}
export type StatisticsPeriod = 'day' | 'week' | 'month' | 'quarter' | 'year';

@Injectable({
  providedIn: 'root'
})
export class StatisticsTreeLoaderService {
  constructor(private http: HttpClient, private userService: UserService, private translationService: TranslateService) {
  }

  public fetch(period: StatisticsPeriod = null): Observable<StatisticsNodeData[]> {
    return this.http.get<any>(environment.api + this.userService.domain + '/visit/', {params: {period}})
      .pipe(map(this.convertJson));
  }

  public getStatistics(data: StatisticsNodeData[]): StatisticsNode {
    return this.updateCounts(this.assemble(data));
  }

  private assemble(nodes: StatisticsNodeData[]): StatisticsNode {
    const nodeMap = new Map<string, StatisticsNode>()
    nodes.forEach(data => nodeMap[data.id] = {
      data,
      children: [],
      descendantsCount: 0
    });
    let dataTree: StatisticsNode = null;
    nodes.forEach(data => {
      if (data.parentId !== data.id) {
        nodeMap[data.parentId].children.push(nodeMap[data.id])
      } else {
        dataTree = nodeMap[data.id];
      }
    });
    return dataTree;
  }

  private updateCounts(tree: StatisticsNode): StatisticsNode {
    const updateDescendantsCount = (node: StatisticsNode) => {
      let count = 0;
      node.children.forEach(child => {
        updateDescendantsCount(child);
        count += child.data.selfCount + child.descendantsCount;
      });
      node.descendantsCount = count;
    }
    updateDescendantsCount(tree);
    return tree;
  }

  convertJson = (input: any): StatisticsNodeData[] => {
    if (!Array.isArray(input)) {
      throw new Error('Not an array')
    } else {
      return input.map(node => ({
        id: node.nID,
        parentId: node.pID,
        label: node.txt || '*' + this.translationService.instant('general.node.unnamed') + '*',
        type: node.t,
        sortOrder: node.sO,
        selfCount: node.pV
      }));
    }
  }
}
