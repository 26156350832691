import {Action} from '@ngrx/store';
import {LogoSettings, WatermarkSettings} from '../reducers/tree.reducer';

export const IMAGE_SET_LOGO = '[Image] set temporary logo';
export const IMAGE_CLEAR_LOGO = '[Image] clear temporary logo';
export const IMAGE_SET_WATERMARK = '[Image] set temporary watermark';
export const IMAGE_CLEAR_WATERMARK = '[Image] clear temporary watermark';
export const IMAGE_CLEAR_ALL = '[Image] clear temporary logo and watermark';

export class SetLogo implements Action {
  readonly type = IMAGE_SET_LOGO;

  constructor(public payload: { logo: LogoSettings }) {
  }
}

export class SetWatermark implements Action {
  readonly type = IMAGE_SET_WATERMARK;

  constructor(public payload: { watermark: WatermarkSettings }) {
  }
}

export class ClearLogo implements Action {
  readonly type = IMAGE_CLEAR_LOGO;
}

export class ClearWatermark implements Action {
  readonly type = IMAGE_CLEAR_WATERMARK;
}

export class ImageClearAll implements Action {
  readonly type = IMAGE_CLEAR_ALL;
}

export type ImageActions = SetLogo | SetWatermark | ClearLogo | ClearWatermark | ImageClearAll;
