import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {User} from '@process-manager/pm-library/lib/model/user';
import {combineLatest, Observable} from 'rxjs';
import {map, skipWhile} from 'rxjs/operators';
import {ConfirmDialogComponent} from '../../main/confirm-navigation/confirm-dialog.component';
import {ClearHistory, DelayedNavigation, SaveSteps} from '../../state-management/actions/tree.actions';
import {AppState, getAuthUser, getTreeHasUndo, getTreeSaving, selectTreeState} from '../../state-management/reducers';
import {getLoadEffect, TreeState} from '../../state-management/reducers/tree.reducer';
import {isProcess} from '../model/process';
import {TreeElement} from '../model/treeelement';

@Injectable({
  providedIn: 'root'
})
export class ConfirmNavigationGuard {
  private hasUndo: boolean;
  private treeState: TreeState;
  private user: User;

  constructor(private dialog: MatDialog, private store$: Store<AppState>) {
    this.store$.pipe(select(getTreeHasUndo)).subscribe(hasUndo => this.hasUndo = hasUndo);
    this.store$.pipe(select(selectTreeState)).subscribe(treeState => this.treeState = treeState);
    this.store$.pipe(select(getAuthUser)).subscribe(user => this.user = user);
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const nodeId = route.params['nodeid'];
    const root = this.treeState.tree.root;
    if (!this.hasUndo || nodeId === root) {
      return true;
    } else {
      const treeElement: TreeElement = this.treeState.tree.nodes.get(nodeId);
      const missingServerId = !(treeElement?.serverId) && isProcess(treeElement);
      const rootIsPage = this.treeState.tree.nodes.get(root).type === 'page';

      if (treeElement && !missingServerId && rootIsPage && treeElement.type !== 'mainpage') {
        return true;
      } else {
        return this.askUserForConfirmation(nodeId, missingServerId);
      }
    }
  }

  private askUserForConfirmation(nodeId: string, hideSaveButton: boolean = false): Observable<boolean> {
    const dialogResult = this.dialog.open(ConfirmDialogComponent, {
      data: {
        hideSaveButton: hideSaveButton,
        title: 'dialog.confirm-navigation.title',
        body: 'dialog.confirm-navigation.content'
      }
    }).afterClosed().pipe(map(result => {
      switch (result) {
        case 'continue': {
          const effect = getLoadEffect(nodeId, this.treeState, this.user);
          if (effect.action === 'fullLoad') {
            const treeElement = this.treeState.tree.nodes.get(nodeId);
            if (!treeElement || !treeElement.serverId) {
              this.store$.dispatch(new SaveSteps({nextAction: new DelayedNavigation({id: nodeId})}));
              return false;
            } else {
              this.store$.dispatch(new SaveSteps({nextAction: {type: 'NOP'}}));
            }
          } else {
            this.store$.dispatch(new SaveSteps());
          }
          return true;
        }
        case 'drop':
          this.store$.dispatch(new ClearHistory());
          return true;
        case 'cancel':
          return false;
      }
    }));

    return combineLatest([dialogResult, this.store$.pipe(select(getTreeSaving))])
      .pipe(skipWhile(([allowContinue, saving]) => allowContinue && saving), map(([allowContinue]) => allowContinue));
  }
}
