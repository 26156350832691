import {Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

export interface ConfirmDialogOptions {
  hideSaveButton?: boolean,
  title: string,
  body: string,
  bodyTranslationArguments?: {},
  continueButton?: string,
  dropButton?: string,
  cancelButton?: string
  onContinue?: () => Observable<string|null>,
  forceLanguage?: string;
}

export type ConfirmDialogResult = 'continue'|'drop'|'cancel';

@Component({
  selector: 'pm-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  title: string;
  body: string;
  error: string = null;
  private previousLanguage: string;

  working = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogOptions,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>, private ngZone: NgZone, private translateService: TranslateService) {
  }

  closeDialog(action: ConfirmDialogResult) {
    const close = () => {
      this.ngZone.run(() => {
        this.dialogRef.close(action);
      });
    };
    if (action === 'continue' && !!this.data.onContinue) {
        this.working = true;
        this.data.onContinue().pipe(finalize(() => this.working = false)).subscribe((result) => {
          if (!result) {
            close();
          } else {
            console.log('Result:' + result);
            this.error = result;
          }
        });
    } else {
      close();
    }
  }

  ngOnInit() {
    const forceLanguage = this.data.forceLanguage;
    if(!!forceLanguage) {
      this.previousLanguage = this.translateService.currentLang;
      this.translateService.use(forceLanguage)
    }
  }

  ngOnDestroy(): void {
    if(!!this.previousLanguage) {
      this.translateService.use(this.previousLanguage);
    }
  }
}
