import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BlockedPopupReporter} from '@process-manager/pm-library';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {PopupDialogComponent, PopupDialogData} from '../components/popup-dialog/popup-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MatDialogBlockedPopupReporterService extends BlockedPopupReporter {
  constructor(private dialog: MatDialog) {
    super();
  }

  public open(): Observable<null> {
    const matDialogRef = this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
      data: {
        title: 'dialog.blocked-popup.title',
        body: 'dialog.blocked-popup',
        okLabel: 'button.retry'
      },
      disableClose: true,
      maxWidth: 600
    });
    return matDialogRef.afterClosed().pipe(take(1));
  }
}
