import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, OnInit} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {
  FlatStatisticsNode, StatisticsPeriod, StatisticsTreeLoaderService, treeFlattenerTransformerFunction
} from '../../../shared/services/statistics-tree-loader.service';

@Component({
  selector: 'pm-statistics-dialog',
  templateUrl: './statistics-dialog.component.html',
  styleUrls: ['./statistics-dialog.component.css']
})
export class StatisticsDialogComponent implements OnInit {
  treeFlattener = new MatTreeFlattener(treeFlattenerTransformerFunction, node => node.level, node => node.expandable,
    node => node.children);
  treeControl = new FlatTreeControl<FlatStatisticsNode>(node => node.level, node => node.expandable);
  treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  periodOptions: StatisticsPeriod[] = ['year', 'quarter', 'month', 'week', 'day', null];
  selectedPeriod: StatisticsPeriod = null;

  constructor(private statisticsTreeLoader: StatisticsTreeLoaderService) {
  }

  ngOnInit(): void {
    this.fetch();
  }

  public fetch() {
    this.statisticsTreeLoader.fetch(this.selectedPeriod)
      .subscribe(raw => {
        let statistics = this.statisticsTreeLoader.getStatistics(raw);
        this.treeDataSource.data = [statistics];
        this.treeControl.expand(this.treeControl.dataNodes[0]);
      });
  }

  hasChild(_: number, _nodeData: FlatStatisticsNode) {
    return _nodeData.expandable;
  };
}

