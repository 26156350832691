import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {UserService} from '@process-manager/pm-library';

import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, tap, withLatestFrom} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

import {ConfirmDialogComponent} from '../../main/confirm-navigation/confirm-dialog.component';
import {LogbookDialogComponent} from '../../main/logbook-dialog/logbook-dialog.component';
import {PopupDialogComponent} from '../../shared/components/popup-dialog/popup-dialog.component';
import {NavigationService} from '../../shared/services/navigation.service';
import {OfflineTreeService} from '../../shared/services/offline-tree.service';
import * as Auth from '../actions/auth.actions';
import {Logout, RequestLogout} from '../actions/auth.actions';
import {ListTemplateAction, SetTemplatesEnabledAction} from '../actions/templates.actions';
import {ChangeLanguage, LoadTreeSuccess, SaveSteps} from '../actions/tree.actions';
import {LabelSettingsChange, MarkTranslationChange} from '../actions/view-options.actions';
import {
  AppState,
  getAuthDomain,
  getLogbookSavedSinceLogging,
  getTreeActiveLanguage,
  getTreeHasUndo,
  getTreeOpenId,
  HighlightSelectionType
} from '../reducers';
import {LabelSettings} from '../reducers/view-options.reducer';
import {ViewOptionsEffects} from './view-options.effects';

const LEFT_SLASHES = new RegExp('^[/]+');
const RIGHT_SLASHES = new RegExp('[/]+$');
const ensureOneSlash = (base: string, path: string) => {
  return `${base.replace(RIGHT_SLASHES, '')}/${path.replace(LEFT_SLASHES, '')}`;
}

@Injectable()
export class AuthEffects {
  load$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<Auth.Login>(Auth.LOGIN),
    map(action => action.payload.auth),
    exhaustMap(query => {
      return this.userService.login(query.domain.toLowerCase().trim(), query.username.toLowerCase().trim(),
        query.password, query.userIsPublic).pipe(
        map(reply => new Auth.LoginSuccess({
          user: reply.user,
          siteSettings: reply.siteSettings,
          returnUrl: query.returnUrl,
          isOnline: true,
          redirect: true,
          passwordIsTemporary: reply.passwordIsTemporary || false,
          domain: query.domain
        })),
        catchError(error => of(new Auth.LoginFailure({
          domain: query.domain,
          publicUser: query.username,
          error: error
        })))
      );
    })
  ));

  loginSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType<Auth.LoginSuccess>(Auth.LOGIN_SUCCESS),
    map(action => {
      const payload = action.payload;
      if (payload.redirect) {
        if (payload.returnUrl) {
          this.router.navigateByUrl(payload.returnUrl);
        } else {
          this.navigationService.navigateToNode(payload.user.startId);
        }
      }
      const newLanguage = payload.siteSettings.languages.find(lang => lang.id === payload.currentLanguage) ||
        payload.siteSettings.languages.find(lang => lang.isDefault);

      this.store$.dispatch(new SetTemplatesEnabledAction({enabled: payload.siteSettings.subscriptionState !== 'legacy'}));
      const markTranslationSettings = localStorage.getItem(ViewOptionsEffects.getMarkTranslationStorageId(payload.domain));
      if (!!markTranslationSettings) {
        this.store$.dispatch(new MarkTranslationChange({
          type: markTranslationSettings as HighlightSelectionType,
          store: false
        }));
      }
      const labelSettingsJson = localStorage.getItem(ViewOptionsEffects.getLabelDisplayStorageId(payload.domain));
      if (!!labelSettingsJson) {
        const labelSettings = JSON.parse(labelSettingsJson) as LabelSettings;
        labelSettings.show = labelSettings.show ?? true;
        this.store$.dispatch(new LabelSettingsChange({settings: labelSettings, store: false}))
      }

      if (window.navigator.onLine && (this.userService.user.roles.includes('edit') || this.userService.user.roles.includes('admin'))) {
        this.store$.dispatch(new ListTemplateAction());
      }
      return new ChangeLanguage({
        source: 'customer',
        newLanguage: newLanguage,
        oldLanguage: null
      });
    })));

  domainChosen$ = createEffect(() => this.actions$.pipe(ofType<Auth.DomainChosen>(Auth.DOMAIN_CHOSEN), tap(async action => {
    if(window.navigator.onLine) {
      if (!action.payload.local) {
        window.location.href = `${environment.api}auth/${action.payload.domain.toLowerCase()}?returnUrl=${ensureOneSlash(document.baseURI, action.payload.returnUrl || this.navigationService.getDomainUrl(action.payload.domain))}`;
      }
    } else {
      const available = await this.offlineService.domainAvailable(action.payload.domain);
      if(available) {
        const root = await this.offlineService.getRoot();
        await this.navigationService.navigateToNode(root.id)
      }
    }
  })), {dispatch: false});

  loginRedirect$ = createEffect(() => this.actions$.pipe(
    ofType<Auth.LoginRedirect>(Auth.LOGIN_REDIRECT),
    map(action => action.payload), exhaustMap(payload => {
      if (!!payload.publicUser) {
        return of(new Auth.Login({
          auth: {
            domain: payload.domain,
            username: payload.publicUser,
            password: 'public',
            returnUrl: payload.returnUrl,
            userIsPublic: true
          }
        }));
      } else {
        this.router.navigate(['/authentication']);
        return of({type: 'AUTH_NO_ACTION'});
      }
    })
  ));

  loginFailure$ = createEffect(() => this.actions$.pipe(ofType<Auth.LoginFailure>(Auth.LOGIN_FAILURE),
    tap(() => this.router.navigate(['/authentication']))), {dispatch: false});

  requestLogout$ = createEffect(() => this.actions$.pipe(
    ofType<Auth.RequestLogout>(Auth.REQUEST_LOGOUT),
    withLatestFrom(this.store$.select(getTreeHasUndo), this.store$.select(getAuthDomain), this.store$.select(getLogbookSavedSinceLogging)),
    tap(([action, hasUndo, domain, hasSavedWithoutLogging]) => {
      const logoutAction = new Logout({domain: domain});

      const showLogoutLogbook = this.userService.getSiteSettings().showLogoutLogbook;
      if (hasUndo && !!action.payload.checkChanges) {
        this.showSaveWarningDialog(new RequestLogout({checkChanges: false}));
      } else if (hasSavedWithoutLogging && showLogoutLogbook !== 'none') {
        this.showLogWarningDialog(showLogoutLogbook === 'strict', logoutAction);
      } else {
        this.store$.dispatch(logoutAction);
      }
    })
  ), {dispatch: false});

  logout$ = createEffect(() => this.actions$.pipe(
    ofType<Auth.Logout>(Auth.LOGOUT),
    tap((action) => {
      this.userService.logout(action.payload.domain);
      this.router.navigate(['/authentication']);
    })
  ), {dispatch: false});

  offline$= createEffect(() => this.actions$.pipe(ofType<Auth.Offline>(Auth.OFFLINE),
    withLatestFrom(this.store$.select(getTreeActiveLanguage), this.store$.select(getTreeOpenId)),
    tap(async ([action, currentLanguage, currentId]) => {
    this.store$.dispatch(new LoadTreeSuccess({
      tree: await this.offlineService.getTree(),
      wipeHistory: true,
      openNode: currentId,
      language: currentLanguage,
    }));
  })), {dispatch: false});


  passwordReset$ = createEffect(() => this.actions$.pipe(
    ofType<Auth.PasswordResetRequest>(Auth.REQUEST_RESET),
    tap(action => {
      const payload = action.payload;
      this.userService.resetRequest(payload.resetRequest.domain, payload.resetRequest.email, payload.language).subscribe(() => {
      });
    })
  ), {dispatch: false});

  constructor(private actions$: Actions, private userService: UserService, private router: Router,
              private navigationService: NavigationService, private dialog: MatDialog,
              private store$: Store<AppState>, private offlineService: OfflineTreeService) {
  }

  private showLogWarningDialog(force: boolean, nextAction: Action) {
    this.dialog.open(PopupDialogComponent, {
      data: {
        title: 'dialog.logout-log-warning.title',
        body: force ? 'dialog.logout-log-warning.content.force' : 'dialog.logout-log-warning.content',
        actionLabel: force ? undefined : 'dialog.logout-log-warning.content.skip'
      }
    }).afterClosed().subscribe(result => {
      switch (result) {
        case 'ok':
          this.dialog.open(LogbookDialogComponent, {
            width: '80%',
            data: {
              logoutOnAdd: true
            }
          });
          break;
        case 'action':
          this.store$.dispatch(nextAction);
          break;
      }
    });
  }

  private showSaveWarningDialog(nextAction: Action) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'dialog.confirm-logout.title',
        body: 'dialog.confirm-logout.content'
      }
    }).afterClosed().subscribe(result => {
      switch (result) {
        case 'continue':
          this.store$.dispatch(new SaveSteps({nextAction}));
          break;
        case 'drop':
          this.store$.dispatch(nextAction);
          break;
      }
    });
  }
}
