<form (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
  <h2 mat-dialog-title>{{'dialog.extra-options.title' | translate}}</h2>
  <mat-dialog-content class="pm-extra-options-dialog-content mat-typography">
    <h3 class="ownership-title">{{'dialog.extra-options.ownership' | translate}}</h3>
    <mat-form-field floatLabel="always" *ngIf="contextIsProcess">
      <mat-label>{{'dialog.contact.owner-email' | translate}}</mat-label>
      <input matInput title='' [placeholder]="parentOwner" id="pm_owner_email" name="owner_email" type="email"
             [formControl]="ownerControl">
      <mat-hint *ngIf="!hasOwnerOrParent">{{'dialog.extra-options.no-owner' | translate}}</mat-hint>
    </mat-form-field>
    <div class="no-owner-bullet" *ngIf="!contextIsProcess">{{'dialog.extra-options.bullet.owner-not-supported' | translate}}</div>
    <h3 class="permission-title">{{'dialog.extra-options.permissions' | translate}}</h3>
    <mat-checkbox [(ngModel)]="inherit" name="inheritCheckbox" (change)="onInheritChange($event)">
      {{'dialog.extra-options.inherit' | translate}}
    </mat-checkbox>
    <mat-form-field>
      <mat-label>{{'dialog.extra-options.permissions.read-access' | translate}}</mat-label>
      <mat-select placeholder="{{'dialog.extra-options.permissions.everyone' | translate}}"
                  [formControl]="viewerControl" (selectionChange)="onReadersChanged()" multiple>
        <mat-select-trigger>{{viewerSelectTrigger | async}}</mat-select-trigger>
        <mat-optgroup *ngIf="readGroups.length > 0" label="{{'dialog.extra-options.permissions.groups' | translate}}">
          <mat-option *ngFor="let group of readGroups" [value]="group" [disabled]="inherit">{{ group.name }}
          </mat-option>
        </mat-optgroup>
        <mat-optgroup label="{{'dialog.extra-options.permissions.users' | translate}}">
          <mat-option *ngFor="let user of readUsers" [value]="user" [disabled]="inherit">{{ user.name }} <span
            class="pm-fullname">{{ user.fullName}}</span>
          </mat-option>
        </mat-optgroup>
      </mat-select>
      <mat-hint *ngIf="readerGroupCount == 0 && readerUserCount == 0">
        {{'dialog.extra-options.permissions.everyone-hint-view' | translate}}
      </mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'dialog.extra-options.permissions.edit-access' | translate}}</mat-label>
      <mat-select [formControl]="editorControl" (selectionChange)="onWritersChanged()" multiple>
        <mat-select-trigger>{{editorSelectTrigger | async}}</mat-select-trigger>
        <mat-optgroup *ngIf="writeGroups.length > 0" label="{{'dialog.extra-options.permissions.groups' | translate}}">
          <mat-option *ngFor="let group of writeGroups" [value]="group" [disabled]="inherit">{{ group.name }}
          </mat-option>
        </mat-optgroup>
        <mat-optgroup label="{{'dialog.extra-options.permissions.users' | translate}}">
          <mat-option *ngFor="let user of writeUsers" [value]="user" [disabled]="inherit">{{ user.name }} <span
            class="pm-fullname">{{ getFullName(user) }}</span>
          </mat-option>
        </mat-optgroup>
      </mat-select>
      <mat-hint *ngIf="writerGroupCount == 0 && writerUserCount == 0">
        {{'dialog.extra-options.permissions.everyone-hint-edit' | translate}}
      </mat-hint>
    </mat-form-field>
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-button type="submit">{{'button.ok' | translate}}</button>
    <button mat-button type="button" (click)="onCancelClick()">{{'button.cancel' | translate}}</button>
  </div>
</form>
