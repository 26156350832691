export enum Shape {
  ELLIPSE = 'ellipse',
  RECTANGLE = 'rectangle',
  LEFT_ARROW = 'leftarrow',
  RIGHT_ARROW = 'rightarrow',
  UP_ARROW = 'uparrow',
  DOWN_ARROW = 'downarrow',
  BULLET = 'bullet',
  NONE = 'none'
}
