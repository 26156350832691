<h2 mat-dialog-title>{{userAdminTitleKey | translate}}</h2>

<mat-tab-group #matTabGroup mat-dialog-content (selectedTabChange)="tabChanged($event)" [class.pm-no-tabs]="!adminUsers || !adminGroups">
  <mat-tab label="{{'dialog.user-admin.users' | translate}}" *ngIf="adminUsers">
    <form #userForm="ngForm" novalidate>
      <div class="pm-user-admin-form">
        <section>
          <h4>{{'dialog.user-admin.user-profile' | translate}}</h4>
          <mat-form-field floatLabel="always">
            <mat-label>{{'dialog.user-admin.user' | translate}}</mat-label>
            <mat-select name="selectedUser" placeholder="{{'dialog.user-admin.select-user' | translate}}"
                        [(ngModel)]="selectedUser" (selectionChange)="userSelectionChange($event)">
              <mat-option [value]="NEW_USER_SELECT">{{'dialog.user-admin.new-user' | translate}}</mat-option>
              <mat-optgroup label="{{'dialog.user-admin.existing-users' | translate}}">
                <mat-option *ngFor="let user of users" [value]="user.username">{{ user.username }}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
            <mat-form-field>
              <input matInput id="pm_first_name" name="first_name" autocomplete="given-name" [disabled]="disableControls"
                     placeholder="{{'dialog.user-admin.first-name' | translate}}" [(ngModel)]="currentUser.firstName">
            </mat-form-field>
            <mat-form-field>
              <input matInput id="pm_surname" name="surname" autocomplete="family-name" [disabled]="disableControls"
                     placeholder="{{'dialog.user-admin.surname' | translate}}" [(ngModel)]="currentUser.lastName">
            </mat-form-field>
            <mat-form-field>
              <input matInput id="pm_initials" name="initials" placeholder="{{'dialog.user-admin.initials' | translate}}"
                     [(ngModel)]="currentUser.initials" [disabled]="disableControls">
            </mat-form-field>
            <mat-form-field>
              <input matInput id="pm_email" name="email" autocomplete="email" email type="email"
                     (input)="emailChange()" [disabled]="disableControls"
                     placeholder="{{'dialog.user-admin.email' | translate}}" [(ngModel)]="currentUser.email">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'dialog.user-admin.language' | translate}}</mat-label>
              <mat-select [(ngModel)]="currentUser.language" name="language" [disabled]="disableControls">
                <mat-option *ngFor="let lang of languages$ | async" [value]="lang.id">{{lang.name}}</mat-option>
              </mat-select>
            </mat-form-field>
        </section>
        <mat-divider [vertical]="true" ></mat-divider>
        <mat-divider></mat-divider>
        <section>
          <h4>{{'dialog.user-admin.account-permissions' | translate}}</h4>
          <mat-form-field>
            <input #username="ngModel" matInput id="pm_username" name="username" autocomplete="off" pattern="[A-Za-z0-9._@\-#]*"
                   placeholder="{{'dialog.user-admin.username' | translate}}" [(ngModel)]="currentUser.username"
                   required [disabled]="disableControls">
            <mat-error *ngIf="username?.errors?.pattern">{{ 'dialog.user-admin.username.error' | translate}}</mat-error>
          </mat-form-field>

          <mat-checkbox *ngIf="!newUser" [(ngModel)]="generatePassword" name="generatePassword"
                        (change)="generatePasswordChange()" [disabled]="disableControls">
            {{'dialog.user-admin.generate-password' | translate}}
          </mat-checkbox>
          <mat-checkbox [(ngModel)]="sendEmail" name="sendEmail"
                        [disabled]="disableControls || emailDisabled">
            {{'dialog.user-admin.email-password' | translate}}
          </mat-checkbox>
          <mat-label>{{'dialog.user-admin.permissions.label' | translate}}</mat-label>
          <mat-checkbox name="admin" [(ngModel)]="currentUser.admin" [disabled]="disableControls || adminSelf">
            {{'dialog.user-admin.permissions.admin' | translate}}
          </mat-checkbox>
          <mat-checkbox name="author" [(ngModel)]="currentUser.editor" [disabled]="disableControls">
            {{'dialog.user-admin.permissions.author' | translate}}
          </mat-checkbox>
          <mat-checkbox name="documentViewer" [(ngModel)]="currentUser.showLinks" [disabled]="disableControls">
            {{'dialog.user-admin.permissions.documents' | translate}}
          </mat-checkbox>
          <mat-checkbox name="seeAllHistory" [(ngModel)]="currentUser.seeAllHistory" [disabled]="disableControls" title="{{'dialog.user-admin.permissions.show-all-history.details' | translate}}">
            {{'dialog.user-admin.permissions.show-all-history' | translate}}
          </mat-checkbox>
        </section>
        <mat-divider [vertical]="true"></mat-divider>
        <mat-divider></mat-divider>
        <section>
          <div class="tree-scroller">
            <h4>{{'dialog.user-admin.access.label' | translate}}</h4>
            <span class="labels tree-flex">
              <span class="node-name"></span>
              <span class="label-access user-access">{{'dialog.user-admin.access.access' | translate}}</span>
              <span class="label-access user-start">{{'dialog.user-admin.access.start' | translate}}</span>
            </span>
            <mat-radio-group name="user-access" [(ngModel)]="currentUser.accessNode" required></mat-radio-group>
            <mat-radio-group name="user-start" [(ngModel)]="currentUser.startNode" required></mat-radio-group>
            <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl">
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20" class="tree-flex">
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.label"
                        [disabled]="!hasChild(0, node)" class="tree-flex">
                  <mat-icon *ngIf="hasChild(0, node)" class="mat-icon-rtl-mirror">{{treeControl.isExpanded(node) ?
                    'expand_more'
                    : 'chevron_right'}}
                  </mat-icon>
                </button>
                <span class="node-name">{{node.label}}</span>
                <mat-radio-button class="user-access" name="user-access" [value]="node" [checked]="isAccessNode(node.id)"
                                  [disabled]="disableControls || adminSelf"
                                  (change)="accessChanged($event)" ></mat-radio-button>
                <mat-radio-button class="user-start" name="user-start" [value]="node" [checked]="isStartNode(node.id)"
                                  (change)="startChanged($event)" [disabled]="disableControls ||startDisabled(node.id)"
                                  ></mat-radio-button>
              </mat-tree-node>
            </mat-tree>
          </div>
        </section>
      </div>
    </form>
  </mat-tab>
  <mat-tab label="{{'dialog.user-admin.groups' | translate}}" *ngIf="adminGroups">
    <form #groupForm="ngForm" novalidate>
      <mat-form-field class="block-field" floatLabel="always">
        <mat-label>{{'dialog.user-admin.group' | translate}}</mat-label>
        <mat-select name="selectedGroup" [(ngModel)]="selectedGroup"
                    placeholder="{{'dialog.user-admin.select-group' | translate}}"
                    (selectionChange)="groupSelectionChange($event)">
          <mat-option [value]="NEW_GROUP_SELECT">{{'dialog.user-admin.new-group' | translate}}</mat-option>
          <mat-optgroup label="{{'dialog.user-admin.existing-groups' | translate}}">
            <mat-option *ngFor="let group of groups" [value]="group.name">{{ group.name }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <div>
        <mat-form-field class="block-field">
          <input matInput id="pm_groupname" name="groupname" [(ngModel)]="currentGroup.name"
                 placeholder="{{'dialog.user-admin.group-name' | translate}}"
                 required>
        </mat-form-field>
      </div>
      <div *ngIf="adminUsers">
        <mat-form-field class="block-field">
          <mat-label>{{'dialog.user-admin.group-members' | translate}}</mat-label>
          <mat-select name="group-members" [(ngModel)]="currentGroup.members" multiple>
            <mat-select-trigger>{{'dialog.user-admin.member-count' | translate:memberCountTranslationParam}}
            </mat-select-trigger>
            <mat-option *ngFor="let user of users" [value]="user.id">{{ user.username }} <span
              class="pm-fullname">{{ getFullName(user) }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-tab>
</mat-tab-group>
<div mat-dialog-actions>
  <button mat-button type="button" (click)="currentTabSubmit()" [disabled]="currentTabInvalid">{{'button.ok' |
    translate}}
  </button>
  <button mat-button type="button" mat-dialog-close>{{'button.cancel' | translate}}</button>
  <button mat-button type="button" (click)="currentTabDelete()" [disabled]="currentTabDeleteDisabled" color="warn">
    {{'button.delete' | translate}}
  </button>
</div>
