<button matTooltip="{{'menu.toolbar.history' | translate}}" mat-icon-button (click)="isOpen = !isOpen" cdkOverlayOrigin
        #trigger="cdkOverlayOrigin">
  <mat-icon>history</mat-icon>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayViewportMargin]="15"
  (backdropClick)="isOpen = false"
  cdkConnectedOverlayBackdropClass="invisibleBackdrop"
>
  <div class="pm-history-panel mat-elevation-z8">
    <mat-card appearance="outlined">
      <h4 mat-card-subtitle>{{'history.popup.recent' | translate}}</h4>
      <div mat-card-content>
        <a href="#" (click)="!!showAll();">{{'history.popup.show-all' | translate}}</a>
        <span class="pull-right"><small>
          <a href="#" (click)="!!expandAll();">{{'action.expand-all' | translate}}</a>&nbsp;
          <a href="#" (click)="!!closeAll()">{{'action.collapse-all' | translate}}</a></small>
        </span>
      </div>
    </mat-card>
    <mat-accordion [multi]="true" displayMode="flat" *ngIf="loaded">
      <mat-expansion-panel *ngFor="let eventBlock of historyEventBlock; trackBy: identity">
        <mat-expansion-panel-header>
          <mat-panel-title>{{'history.user.update' |translate:eventBlock}}</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <pm-event-block-view [eventBlock]="eventBlock"></pm-event-block-view>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-spinner *ngIf="!loaded" [diameter]="32"></mat-spinner>
  </div>
</ng-template>
