import {Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent, merge} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'platform'
})
export class PointerMoveListenerService {
  private _isTouch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    merge(fromEvent(document, 'pointermove'),fromEvent(document,'pointerdown')).pipe(map((event:PointerEvent) => event.pointerType === 'touch' )).subscribe(this._isTouch$);
  }

  get isTouch$() {
    return this._isTouch$.asObservable();
  }

  get isTouch() {
    return this._isTouch$.value;
  }
}
