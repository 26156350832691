import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors,
  ValidatorFn, Validators
} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {UserService} from '@process-manager/pm-library';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {NavigationService} from '../../services/navigation.service';

const MIN_PASSWORD_LENGTH = 8;

export class ParentErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

export interface PasswordUpdate {
  currentPassword?: string;
  newPassword: string;
}

@Component({
  selector: 'pm-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent {
  @ViewChild('submitButton', {static: true}) submitButton: ElementRef;
  @Input() presetCurrentPassword: string;
  @Input() username: string;
  @Output() submitted: EventEmitter<PasswordUpdate> = new EventEmitter();

  parentMatch: ErrorStateMatcher = new ParentErrorStateMatcher();
  pending: boolean;
  error: string;

  passwordForm = this.fb.group({
    currentPassword: new FormControl('', {
      updateOn: 'blur',
      validators: [this.currentPasswordValidation()],
      asyncValidators: [this.checkPasswordValidation()]
    }),
    newPassword: ['', [Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH)]],
    repeatPassword: ['', Validators.required]
  }, {validator: this.passwordsEqualValidation()});
  tooShortParam = {length: MIN_PASSWORD_LENGTH};

  constructor(private fb: FormBuilder, private userService: UserService, private navigationService: NavigationService) {
  }

  get needsCurrentPassword() {
    return !this.presetCurrentPassword;
  }

  get valid() {
    return this.passwordForm.valid;
  }

  onSubmit() {
    if (this.valid) {
      this.submitted.emit({
        currentPassword: this.passwordForm.get('currentPassword').value || this.presetCurrentPassword,
        newPassword: this.passwordForm.get('newPassword').value
      });
    }
    this.navigationService.addRandomHash();
  }

  submit() {
    this.submitButton.nativeElement.click();
  }

  private currentPasswordValidation(): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (!this.presetCurrentPassword) {
        return Validators.required(control);
      } else {
        return null;
      }
    }
  }

  private passwordsEqualValidation(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const newPassword = group.controls.newPassword.value;
      const repeatPassword = group.controls.repeatPassword.value;

      return newPassword === repeatPassword ? null : {passwordsDiffer: true}
    }
  }

  private checkPasswordValidation(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!this.presetCurrentPassword) {
        return this.userService.confirmPassword(control.value).pipe(map(success => success ? null : {wrongPassword: true}));
      } else {
        return of(null);
      }
    }
  }
}
