import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';
import {Observable} from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Tree} from '../model/tree';
import {CustomerTreeBuilder} from '../tree-building/customer-tree-builder.service';

export interface ServerIds {
  [key: string]: number;
}

export function resultIsSaveTreeResult(cand: SaveTreeResult | ServerIds): cand is SaveTreeResult {
  return cand['idChanges'] !== undefined && cand['warnings'] !== undefined;
}

export interface ActionWarning {
  action: string
  code: string;
  message: string;
  subject: number;
  details: any[];
}

export interface SaveTreeResult {
  idChanges: ServerIds;
  warnings: ActionWarning[];
}

export abstract class PageTreeNode {
  id: number;
  label: string;
}

export class NestedPageTreeNode extends PageTreeNode {
  children: NestedPageTreeNode[];
}

@Injectable()
export class TreeService {

  constructor(private http: HttpClient, private treeBuilder: CustomerTreeBuilder, private userService: UserService,
    private translateService: TranslateService) {
  }

  /**
   * Gets the tree, starting at page node is on, unless onlySubTree is set.
   * @param id The id of the top node
   * @param onlySubTree Do not load the entire page, only the node and its children
   * @param userIsPublic Is the current user public?
   * @returns {Observable<Tree>} The loaded tree
   */
  public getTree(id: number, onlySubTree: boolean, userIsPublic: boolean): Observable<Tree> {
    const url = environment.api + this.userService.domain + '/tree/' + id;
    const httpParams = new HttpParams().set('noParents', onlySubTree ? 'true' : 'false');

    return this.http.get(url, {params: httpParams}).pipe(map(r => this.treeBuilder.build(r, {userIsPublic})));
  }

  public getPages(): Observable<NestedPageTreeNode> {
    const url = environment.api + this.userService.domain + '/pagetree';
    return this.http.get(url).pipe(withLatestFrom(this.translateService.get('general.node.unnamed')),
      map(([response, label]) => this.buildNestedNodes(response, label)));
  }

  public sendActions(actions: Action[]): Observable<ServerIds | SaveTreeResult> {
    const url = environment.api + this.userService.domain + '/actions';

    return this.http.post<ServerIds>(url, JSON.stringify(actions), {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: {
        includeWarnings: 'true'
      }
    });
  }

  private buildNestedNodes(dataObject: any, label: string): NestedPageTreeNode {
    const currentTop = new NestedPageTreeNode();
    currentTop.label = dataObject.label || '*' + label + '*';
    currentTop.id = dataObject.id;
    currentTop.children = [];
    if (!!dataObject.children) {
      for (const child of dataObject.children) {
        currentTop.children.push(this.buildNestedNodes(child, label));
      }
    }
    return currentTop;
  }
}
