import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as Immutable from 'immutable';
import {Subscription} from 'rxjs';
import {Label} from '../../../shared/model/label';
import {LabelSettings} from '../../../state-management/reducers/view-options.reducer';

export interface LabelsOptionsDialogData {
  currentSettings: LabelSettings;
  availableLabels: Immutable.Map<number, Label>
}

@Component({
  selector: 'pm-label-options-dialog',
  templateUrl: './label-options-dialog.component.html',
  styleUrls: ['./label-options-dialog.component.css']
})
export class LabelOptionsDialogComponent implements OnInit, OnDestroy {
  labelOptionsForm: FormGroup = this.fb.group({
    show: [this.data.currentSettings.show],
    inherit: [this.data.currentSettings.inherit],
    descendents: [this.data.currentSettings.descendents],
    enableFilter: [this.data.currentSettings.enableFilter],
    labels: this.fb.group(this.data.availableLabels.keySeq().toArray().reduce((acc, val) => ({
      ...acc,
      [val]: {
        value: this.data.currentSettings.labels === null || (this.data.currentSettings.labels || []).includes(val),
        disabled: !this.data.currentSettings.enableFilter
      }
    }), {}))
  });
  private filterLabelSubscription: Subscription;
  private showLabelsSubscription: Subscription;

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: LabelsOptionsDialogData) {
  }

  get showLabels(): FormControl {
    return this.labelOptionsForm.get('show') as FormControl;
  }

  get inheritLabels(): FormControl {
    return this.labelOptionsForm.get('inherit') as FormControl;
  }

  get descendentsLabels(): FormControl {
    return this.labelOptionsForm.get('descendents') as FormControl;
  }

  get filterLabels(): FormControl {
    return this.labelOptionsForm.get('enableFilter') as FormControl;
  }

  get labels(): FormGroup {
    return this.labelOptionsForm.get('labels') as FormGroup;
  }

  get output(): LabelSettings {
    const labels = this.labelOptionsForm.getRawValue().labels as { [key: string]: boolean };
    return {
      ...this.labelOptionsForm.getRawValue(),
      'labels': labels && Object.keys(labels).filter(key => labels[key]).map(id => Number(id)) || null
    }
  }

  ngOnInit(): void {
    const filterLabelsUpdated = () => {
      if (this.filterLabels.value) {
        this.labels.enable();
      } else {
        this.labels.disable();
      }
    }

    const showLabelsUpdated = () => {
      if (this.showLabels.value) {
        this.inheritLabels.enable();
        this.descendentsLabels.enable();
      } else {
        this.inheritLabels.disable();
        this.descendentsLabels.disable();
      }
    }

    this.filterLabelSubscription = this.filterLabels.valueChanges.subscribe(filterLabelsUpdated);
    this.showLabelsSubscription = this.showLabels.valueChanges.subscribe(showLabelsUpdated);
    showLabelsUpdated();
    filterLabelsUpdated();
  }

  ngOnDestroy(): void {
    this.filterLabelSubscription.unsubscribe();
  }

  getLabel(labelId: string | number): Label {
    return this.data.availableLabels.get(Number(labelId));
  }

  filterControl(labelId: string | number): FormControl {
    return this.labels.controls[labelId] as FormControl;
  }
}
