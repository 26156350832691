import {Action} from '@ngrx/store';
import {Language} from '@process-manager/pm-library';
import {v1} from 'uuid';
import {Bullet} from '../../shared/model/bullet';
import {Label} from '../../shared/model/label';
import {Link} from '../../shared/model/link';
import {Size} from '../../shared/model/process';
import {Style} from '../../shared/model/style';
import {TemplateId} from '../../shared/model/template-id';
import {Tree} from '../../shared/model/tree';
import {NodeType, Permission, TreeElement} from '../../shared/model/treeelement';
import {ServerIds} from '../../shared/services/tree.service';

import {ActivationType} from '../reducers/tree.reducer';

function* idGenerator(elements: number) {
  let count = 0;
  while (elements > count++) {
    yield v1();
  }
}

export enum TreeActionTypes {
  LOAD_TREE = '[Tree] Load tree', // Comments ensure wrapping
  LOAD_TREE_SUCCESS = '[Tree] Load tree completed', //
  LOAD_TREE_ERROR = '[Tree] Load tree error', //
  ADD_TREE_ELEMENT = '[Tree] Add tree element', //
  ADD_RESOURCE = '[Tree] Add resource', //
  UPDATE_RESOURCE = '[Tree] Update resource', //
  CHANGE_RESOURCE_COLOR = '[Tree] Change resource color', //
  REMOVE_RESOURCE = '[Tree] Remove resource', //
  UPDATE_TREE_ELEMENT_LABELS = '[Tree] Update element labels', //
  UPDATE_TREE_ELEMENT_TEXT = '[Tree] Update element text', //
  UPDATE_TREE_ELEMENT_SIZE = '[Tree] Update element size', //
  UPDATE_TREE_ELEMENT_STYLE = '[Tree] Update element style', //
  UPDATE_TREE_ELEMENT_PERMISSIONS = '[Tree] Update element permissions', //
  UPDATE_TREE_ELEMENT_TYPE = '[Tree] Update element type', //
  UPDATE_TREE_ELEMENT_SEARCHABLE = '[Tree] Update element searchable', //
  MOVE_TREE_ELEMENT = '[Tree] Move element', //
  DELETE_TREE_ELEMENT = '[Tree] Delete tree element', //
  ACTIVATE_ELEMENT = '[Tree] Activate element', //
  DEACTIVATE_ELEMENT = '[Tree] Deactivate element', //
  CHANGE_LANGUAGE = '[Tree] Change language', //
  UNDO_STEP = '[Tree] Undo tree-altering step', //
  REDO_STEP = '[Tree] Redo tree-altering step', //
  SAVE_STEPS = '[Tree] Save history to backend', //
  SAVE_STEPS_COMPLETE = '[Tree] Saving complete', //
  SAVE_STEPS_FAILED = '[Tree] Saving failed', //
  CLEAR_HISTORY = '[Tree] Clear undo history', //
  UPDATE_SERVER_IDS = '[Tree] Update server ids', //
  DELAYED_NAVIGATION = '[Tree] delayed navigation', //
  CREATE_LABEL = '[Tree] Label create',
  UPDATE_LABEL = '[Tree] Label updated',
  DELETE_LABEL = '[Tree] Label deleted',
}

export type AddOrMoveRelation = 'before' | 'after' | 'under';
export type ResourceType = 'info' | 'link' | 'upload';

export interface BackupElementData {
  treeElement: TreeElement,
  links: Link[],
  bulletChildren?: Bullet[],
  width: number;
  templateSource?: TemplateId
}

export interface AddElementPayload {
  sourceElementId: string,
  targetElementId: string;
  relation: AddOrMoveRelation;
  backupElementData?: BackupElementData
}

export interface MoveElementPayload extends AddElementPayload {
  isNew: boolean,
  newIds?: string[],
  newUrls?: string[],
  template?: TemplateId
}

export interface CreateElementPayload extends AddElementPayload {
  forceBullet: boolean;
  newElement: TreeElement;
}

export interface Upload {
  token: string;
  filename: string;
  tempSource?: string;
  subtype?: string;
  settings?: {[key: string]: string};
}

export interface Info {
  title: string;
  body: string;
}

export interface ResourceUpdate {
  id: string;
}

export interface ResourceLink {
  url: string;
  name: string;
}

export interface ErrorPayload {
  error: Error;
  action: Action;
}

export interface LoadTreeErrorPayload extends ErrorPayload {
  id: string,
  errorCode: number;
}

export interface ErrorAction extends Action {
  payload: ErrorPayload
}

export class LoadTree implements Action {
  readonly type = TreeActionTypes.LOAD_TREE;

  constructor(public payload?: { id?: string, highlight?: boolean, template?: TemplateId}) {
  }
}

export class LoadTreeSuccess implements Action {
  readonly type = TreeActionTypes.LOAD_TREE_SUCCESS;

  constructor(public payload: { tree: Tree, wipeHistory: boolean, language: Language, openNode?: string }) {
  }
}

export class LoadTreeError implements ErrorAction {
  readonly type = TreeActionTypes.LOAD_TREE_ERROR;

  constructor(public payload: LoadTreeErrorPayload | null) {
  }
}

export class AddTreeElement implements Action {
  readonly type = TreeActionTypes.ADD_TREE_ELEMENT;

  constructor(public payload: CreateElementPayload) {
  }
}

export class AddResource implements Action {
  readonly type = TreeActionTypes.ADD_RESOURCE;

  constructor(public payload: {
    elementId: string, resourceType: ResourceType, newLinkId: string, data: Info | Upload | ResourceLink, replaceOldResource?: string
  }) {
  }
}

export class UpdateResource implements Action {
  readonly type = TreeActionTypes.UPDATE_RESOURCE;

  constructor(public payload: { elementId: string, resourceType: ResourceType, data: Info | Partial<Upload> & ResourceUpdate }) {
  }
}

export class ChangeResourceColor implements Action {
  readonly type = TreeActionTypes.CHANGE_RESOURCE_COLOR;

  constructor(public payload: { elementId: string, resourceId?: string, color: string }) {
  }
}

export class RemoveResource implements Action {
  readonly type = TreeActionTypes.REMOVE_RESOURCE;

  constructor(public payload: { elementId?: string, resourceType: ResourceType, resourceId?: string }) {
  }

}

export class UpdateTreeElementLabels implements Action {
  readonly type = TreeActionTypes.UPDATE_TREE_ELEMENT_LABELS;

  constructor(public payload: { updatedElementId: string, labelIds: number[] }) {
  }
}

export class UpdateTreeElementText implements Action {
  readonly type = TreeActionTypes.UPDATE_TREE_ELEMENT_TEXT;

  constructor(public payload: { id: string, label: string }) {
  }
}

export class UpdateTreeElementStyle implements Action {
  readonly type = TreeActionTypes.UPDATE_TREE_ELEMENT_STYLE;

  constructor(public payload: { updatedElementId: string, style: Style }) {
  }
}

export class UpdateTreeElementSize implements Action {
  readonly type = TreeActionTypes.UPDATE_TREE_ELEMENT_SIZE;

  constructor(public payload: { parentId: string, size: Size }) {
  }
}

export class UpdateTreeElementPermissions implements Action {
  readonly type = TreeActionTypes.UPDATE_TREE_ELEMENT_PERMISSIONS;

  constructor(public payload: { id: string, owner: string, permissions: Permission[] | null }) {
  }
}

export class UpdateTreeElementType implements Action {
  readonly type = TreeActionTypes.UPDATE_TREE_ELEMENT_TYPE;

  constructor(public payload: { id: string, type: NodeType }) {
  }
}

export class UpdateTreeElementSearchable implements Action {
  readonly type = TreeActionTypes.UPDATE_TREE_ELEMENT_SEARCHABLE;

  constructor(public payload: { id: string, searchable: boolean }) {
  }
}

export class MoveTreeElement implements Action {
  readonly type = TreeActionTypes.MOVE_TREE_ELEMENT;

  constructor(public payload: MoveElementPayload) {
    if (payload.isNew || !!payload.template) {
      payload.newIds = Array.from(idGenerator(50));
    }
  }
}

export class DeleteTreeElement implements Action {
  readonly type = TreeActionTypes.DELETE_TREE_ELEMENT;

  constructor(public payload: { id: string }) {
  }
}

export class ActivateElement implements Action {
  readonly type = TreeActionTypes.ACTIVATE_ELEMENT;

  constructor(public payload?: { id: string, activationType: ActivationType }) {
  }
}

export class DeactivateElement implements Action {
  readonly type = TreeActionTypes.DEACTIVATE_ELEMENT;

  /**
   * If payload is set, only deactivate if criteria is matched
   * @param payload
   */
  constructor(public payload?: { id: string, activationType: ActivationType}) {
  }
}

export class ChangeLanguage implements Action {
  readonly type = TreeActionTypes.CHANGE_LANGUAGE;

  constructor(public payload?: {
    source: 'customer' | 'offline' | TemplateId, newLanguage: Language, oldLanguage?: Language, doReload?: boolean
  }) {
  }
}

export class UndoStep implements Action {
  readonly type = TreeActionTypes.UNDO_STEP;
}

export class RedoStep implements Action {
  readonly type = TreeActionTypes.REDO_STEP;
}

export class SaveSteps implements Action {
  readonly type = TreeActionTypes.SAVE_STEPS;

  constructor(public payload?: { nextAction?: Action, reloadTree?: boolean }) {
  }
}

export class SaveStepsComplete implements Action {
  readonly type = TreeActionTypes.SAVE_STEPS_COMPLETE;
}

export class SaveStepsFailed implements Action {
  readonly type = TreeActionTypes.SAVE_STEPS_FAILED;

  constructor(public payload: ErrorPayload) {
  }
}

export class UpdateServerIds implements Action {
  readonly type = TreeActionTypes.UPDATE_SERVER_IDS;

  constructor(public payload: { updatedIds: ServerIds }) {
  }
}

export class ClearHistory implements Action {
  readonly type = TreeActionTypes.CLEAR_HISTORY;

  constructor(public payload?: { onlyFuture?: boolean}) {
  }
}

export class DelayedNavigation implements Action {
  readonly type = TreeActionTypes.DELAYED_NAVIGATION;

  constructor(public payload: {id: string}) {
  }
}

export class LabelCreated implements Action {
  readonly type = TreeActionTypes.CREATE_LABEL;

  constructor(public payload: {label: Label}) {
  }
}

export class LabelUpdated implements Action {
  readonly type = TreeActionTypes.UPDATE_LABEL;

  constructor(public payload: {label: Label}) {
  }
}

export class LabelDeleted implements Action {
  readonly type = TreeActionTypes.DELETE_LABEL;

  constructor(public payload: {label: Label}) {
  }
}

export type TreeActions = LoadTree | LoadTreeSuccess | LoadTreeError | AddTreeElement | AddResource | UpdateResource
  | ChangeResourceColor | RemoveResource | UpdateTreeElementLabels | UpdateTreeElementText | UpdateTreeElementStyle
  | UpdateTreeElementSize | UpdateTreeElementPermissions | UpdateTreeElementType | MoveTreeElement
  | UpdateTreeElementSearchable | DeleteTreeElement | ActivateElement | DeactivateElement | ChangeLanguage
  | UndoStep | RedoStep | SaveSteps | SaveStepsComplete | SaveStepsFailed | ClearHistory | UpdateServerIds
  | LabelCreated | LabelUpdated | LabelDeleted;
