<button class="zx-mobile-menu-button" mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu class="zx-menu" #menu="matMenu">
  <button mat-menu-item *ngFor="let item of items" (click)="item.clicked.emit(subject)">
    <mat-icon>{{item.iconName}}</mat-icon>
    {{item.tooltip}}
  </button>
</mat-menu>
<button class="zx-desktop-button" mat-icon-button *ngFor="let item of items" (click)="item.clicked.emit(subject)"
  [matTooltip]="item.tooltip">
  <mat-icon>{{item.iconName}}</mat-icon>
</button>
