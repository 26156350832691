<div class="card-holder" *ngIf="open" #cardHolder [@showTrigger] (@showTrigger.start)="beforeAnimation()" (@showTrigger.done)="afterAnimation()"  (contextmenu)="dontPropagate($event)">
  <mat-card appearance="outlined" class="pm-info-card mat-elevation-z10" *ngIf="hasInfo()" (click)="onCardClicked()">
    <mat-card-header>
      <div mat-card-title *ngIf="isInfoExtended && !isInfoEditing">{{node.info}}</div>
      <div mat-card-subtitle *ngIf="!node.isTemplateTop">{{'menu.link.internal-document' | translate}} <span class="mat-small" *ngIf="isAutoTranslated">{{'menu.link.auto-translated' | translate}}</span></div>
    </mat-card-header>
    <mat-card-content *ngIf="isInfoExtended && !isInfoEditing" (pmLinkClick)="embeddedInfoLinkClicked($event)"
                      [innerHtml]="node.extendedInfo | unsafeHtml"></mat-card-content>
    <mat-card-content *ngIf="!isInfoExtended && !isInfoEditing">{{node.info}}</mat-card-content>
    <mat-card-content *ngIf="isInfoEditing">
      <mat-form-field>
        <input matInput type="text" placeholder="{{'menu.link.title' | translate}}" name="pm-title-field"
               [value]="tempInfoTitle" (input)="updateInfoTitle($event)" required>
      </mat-form-field>
      <div class="pm-info-editor" [pmTrumbowyg]="trumbowygOptions" [innerHtml]="extendedInfo | unsafeHtml"
           (textUpdate)="onBodyChange($event)" [autoFocus]="false"></div>
    </mat-card-content>
    <mat-card-actions *ngIf="isAuthor && node.writeable" (click)="stopPropagation($event)" align="end">
      <button *ngIf="showRemoveButton" mat-raised-button class="pm-remove-link" color="warn" (click)="onRemoveInfo($event)">{{buttonRemoveLabel | async}}
      </button>
      <button mat-raised-button class="pm-edit-link" color="primary" (click)="onEditInfo($event)" *ngIf="!isInfoEditing">
        {{buttonEditLabel | async}}
      </button>
      <span class="pull-right"></span>
      <pm-color-picker-input [color]="linkColor" (colorChanged)="colorChanged($event)"></pm-color-picker-input>
    </mat-card-actions>
  </mat-card>
  <ng-container *ngFor="let link of filteredLinks; trackBy: getLinkId">
    <pm-embedcard [link]="link" [node]="node" (remove)="onRemoveLink(link)"
                  (linkClicked)="onLinkClicked(link)"></pm-embedcard>
  </ng-container>
</div>
