<form (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
  <h2 mat-dialog-title>{{'menu.toolbar.edit-logo-watermark' | translate}}</h2>
  <mat-dialog-content class="pm-logo-dialog-content">
    <section>
      <h3>{{'dialog.logo.logo' | translate}}</h3>
      <div class="inherit">
        <mat-checkbox [(ngModel)]="logoEnabled" name="enableLogo" (change)="onLogoInheritChange($event)">
          {{'dialog.logo.active-logo' | translate}}
        </mat-checkbox>
      </div>
      <div class="position">
        <mat-radio-group [disabled]="!logoEnabled" [(ngModel)]="logoPosition" name="logoPosition">
          <span class="label">{{'dialog.logo.position.label' | translate}}</span>
          <mat-radio-button value="topleft">{{'dialog.logo.position.topleft' | translate}}</mat-radio-button>
          <mat-radio-button value="topright">{{'dialog.logo.position.topright' | translate}}</mat-radio-button>
          <mat-radio-button value="bottomleft">{{'dialog.logo.position.bottomleft' | translate}}</mat-radio-button>
          <mat-radio-button value="bottomright">{{'dialog.logo.position.bottomright' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="file-upload">
        <label for="pm_logo_upload" class="label">
          <button class="upload-button" type="button" [disabled]="!logoEnabled" mat-button (click)="logoUpload.click()">
            <mat-icon>attach_file</mat-icon>
            {{ logoFilename }}
          </button>
        </label><br>
        <input #logoUpload type="file" class="upload-input" id="pm_logo_upload" name="logoUpload"
               (change)="handleLogoUpload($event)">
        <mat-progress-bar mode="determinate" [value]="logoUploadData.progress"></mat-progress-bar>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <h3>{{'dialog.logo.watermark' | translate}}</h3>
      <div class="inherit">
        <mat-checkbox [(ngModel)]="watermarkEnabled" name="enabledWatermark"
                      (change)="onWatermarkInheritChange($event)">
          {{'dialog.logo.active-watermark' | translate}}
        </mat-checkbox>
      </div>
      <div class="display row">
        <div class="column">
          <!-- TODO: The 'tickInterval' property no longer exists -->
          <mat-slider discrete [disabled]="!watermarkEnabled" [min]="0" [max]="100"
                      [displayWith]="formatLabel" ><input matSliderThumb name="watermarkOpacity" [(ngModel)]="watermarkOpacity"/></mat-slider>
          <mat-label>{{'dialog.logo.opacity' | translate}}</mat-label>
        </div>
        <div class="column">
          <!-- TODO: The 'tickInterval' property no longer exists -->
          <mat-slider discrete [disabled]="!watermarkEnabled" [min]="0" [max]="100"
                      [displayWith]="formatLabel" ><input matSliderThumb name="watermarkSize" [(ngModel)]="watermarkSize"/></mat-slider>
          <mat-label>{{'dialog.logo.size' | translate}}</mat-label>
        </div>
      </div>

      <div class="file-upload">
        <label for="pm_watermark_upload" class="label">
          <button class="upload-button" type="button" [disabled]="!watermarkEnabled" mat-button
                  (click)="watermarkUpload.click()">
            <mat-icon>attach_file</mat-icon>
            {{ watermarkFilename }}
          </button>
        </label><br>
        <input #watermarkUpload type="file" class="upload-input" id="pm_watermark_upload" name="watermarkUpload"
               (change)="handleWatermarkUpload($event)">
        <mat-progress-bar mode="determinate" [value]="watermarkUpload.progress"></mat-progress-bar>
      </div>
    </section>
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-button type="submit" [disabled]="f.invalid">{{'button.ok' | translate}}</button>
    <button mat-button type="button" (click)="closeDialog()">{{'button.cancel' | translate}}</button>
  </div>
</form>
