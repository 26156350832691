import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ColorChromeModule} from 'ngx-color/chrome';
import {ProcessManagerMaterialModule} from '../../material/material.module';
import {ColorPickerInputComponent} from './color-picker-input/color-picker-input.component';
import {FoldableDialogTitleComponent} from './foldable-dialog-title/foldable-dialog-title.component';
import {PasswordChangeComponent} from './password-change-component/password-change.component';
import {PopupDialogComponent} from './popup-dialog/popup-dialog.component';
import {ResponsiveMenuComponent, ResponsiveMenuItemComponent} from './responsive-menu/responsive-menu.component';
import {SaveWarningsDialogComponent} from './save-warnings-dialog/save-warnings-dialog.component';

@NgModule({
  declarations: [PasswordChangeComponent, PopupDialogComponent, ColorPickerInputComponent, ResponsiveMenuComponent,
    ResponsiveMenuItemComponent,
    FoldableDialogTitleComponent,
    SaveWarningsDialogComponent],
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule, ProcessManagerMaterialModule, TranslateModule.forChild(), ColorChromeModule],
    exports: [PasswordChangeComponent, ColorPickerInputComponent, ResponsiveMenuComponent, ResponsiveMenuItemComponent,
        FoldableDialogTitleComponent]
})
export class SharedComponentsModule {
}
