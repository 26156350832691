import {Action} from '@ngrx/store';
import {Language} from '@process-manager/pm-library';
import {RenderedProcess} from '../../shared/model/rendered/rendered-process';
import {TemplateId} from '../../shared/model/template-id';

export enum TemplateActionTypes {
  SET_TEMPLATES_ENABLED = '[Templates] Set active',
  TOGGLE_TEMPLATES = '[Templates] Toggle',
  UPDATE_OLD_SOURCE_LANGUAGE = '[Templates] Update old source language',
  EXIT_TEMPLATE = '[Template] Exit template',
  LIST_TEMPLATES_REQUEST = '[Templates] List',
  LIST_TEMPLATES_COMPLETE = '[Templates] List complete',
  LIST_TEMPLATES_FAILURE = '[Templates] List failure',
  LOAD_TEMPLATE_REQUEST = '[Templates] Load',
  LOAD_TEMPLATE_COMPLETE = '[Templates] Load complete',
  LOAD_TEMPLATE_FAILURE = '[Templates] Load failure'
}

export class ToggleTemplatesAction implements Action {
  readonly type = TemplateActionTypes.TOGGLE_TEMPLATES;
  constructor(public payload?: { forcedState?: 'open' | 'closed'}) {
  }
}

export class ExitTemplateAction implements Action {
  readonly type = TemplateActionTypes.EXIT_TEMPLATE;
}

export class SetTemplatesEnabledAction implements Action {
  readonly type = TemplateActionTypes.SET_TEMPLATES_ENABLED;

  constructor(public readonly payload: { enabled: boolean }) {
  }
}

export class ListTemplateAction implements Action {
  readonly type = TemplateActionTypes.LIST_TEMPLATES_REQUEST;

  constructor() {
  }
}

export class ListTemplateCompleteAction implements Action {
  readonly type = TemplateActionTypes.LIST_TEMPLATES_COMPLETE;

  constructor(public readonly payload: { templateNodes: RenderedProcess }) {
  }
}

export class ListTemplateFailureAction implements Action {
  readonly type = TemplateActionTypes.LIST_TEMPLATES_FAILURE;

  constructor(public readonly payload: { failure: string, code: number }) {
  }
}
export class LoadTemplateAction implements Action {
  readonly type = TemplateActionTypes.LOAD_TEMPLATE_REQUEST;

  constructor(public readonly payload: { templateId: TemplateId, nodeId?: string, language?: Language }) {
  }
}

export class LoadTemplateCompleteAction implements Action {
  readonly type = TemplateActionTypes.LOAD_TEMPLATE_COMPLETE;

  constructor(public readonly payload: { templateId: TemplateId, languages: Language[]}) {
  }
}

export class LoadTemplateFailureAction implements Action {
  readonly type = TemplateActionTypes.LOAD_TEMPLATE_FAILURE;

  constructor(public readonly payload: { failure: string, code: number }) {
  }
}

export class UpdateOldSourceLanguages implements Action {
  readonly type = TemplateActionTypes.UPDATE_OLD_SOURCE_LANGUAGE;

  constructor(public readonly payload: {source: string | TemplateId, language: Language}) {
  }
}

export type TemplateActions = SetTemplatesEnabledAction | ToggleTemplatesAction | LoadTemplateAction
  | LoadTemplateCompleteAction | LoadTemplateFailureAction | UpdateOldSourceLanguages;
