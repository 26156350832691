import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {ProcessManagerMaterialModule} from '../material/material.module';
import {SharedComponentsModule} from '../shared/components/shared-components.module';
import {DomainFormComponent} from './domain-form/domain-form.component';
import {LoginFormComponent} from './login-form/login-form.component';
import {LoginComponent} from './login.component';
import {NewPasswordLoginComponent} from './new-password-form/new-password-login.component';
import {ProcessmanagerLogoComponent} from './processmanager-logo/processmanager-logo.component';
import {ResetFormComponent} from './reset-form/reset-form.component';

export const routes = [{
  path: 'authentication',
  component: LoginComponent,
}, {
  path: 'reset_password',
  component: NewPasswordLoginComponent
}, {
  path: 'html/reset_password',
  redirectTo: 'reset_password'
}];

@NgModule({
  imports: [CommonModule, ProcessManagerMaterialModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(routes),
    TranslateModule.forChild(), SharedComponentsModule],
  declarations: [LoginComponent, LoginFormComponent, ResetFormComponent, NewPasswordLoginComponent, DomainFormComponent, ProcessmanagerLogoComponent]
})
export class LoginModule {
}
