import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserService} from '@process-manager/pm-library';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

export enum BillingCycle {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export interface Subscription {
  billingCycle: BillingCycle;
  id: string;
  expires?: Date;
  status?: string;
  employeeCount: number;
  licensedTemplates: string[];
  hasScheduledUpdate: boolean;
  latestInvoiceId?: string;
  latestInvoiceUrl?: string;
}

export interface Price {
  id: string;
  label: string;
  interval: BillingCycle
  type: 'template'|'employee_count'
  fixedPrice?: number;
  tiers?: PriceTier[];
  tierType: 'volume' | 'graduated',
  templateName?: string,
}

export interface PriceTier {
  min: number;
  max: number;
  unitPrice: number;
  flatPrice: number;
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(private http: HttpClient, private userService: UserService) { }

  get subscriptionUrl() {
    return environment.api + this.userService.domain + '/subscription';
  }

  get buyNowUrl() {
    return this.subscriptionUrl + '/buy';
  }

  getCurrentSubscription(): Observable<Subscription> {
    return this.http.get<Subscription>(this.subscriptionUrl + '/current').pipe(tap(sub => console.log(JSON.stringify(sub))));
  }

  getCurrentPrices(): Observable<Price[]> {
    return this.http.get<Price[]>(this.subscriptionUrl + '/prices');
  }

  updateSubscription(subscription: Subscription) {
    return this.http.post(this.subscriptionUrl + '/next', subscription);
  }
}
