import {
  ChangeDetectorRef, Component, EventEmitter, HostBinding, NgZone, OnDestroy, OnInit, Output
} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {OfflineTreeService} from '../../../shared/services/offline-tree.service';
import {
  AppState, getActiveRootLogoSettings, getActiveRootWatermarkSettings, selectTempImageState
} from '../../../state-management/reducers';
import {LogoSettings, WatermarkSettings} from '../../../state-management/reducers/tree.reducer';

function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  outer.style.overflow = 'scroll';

  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

const DEFAULT_OPACITY = '0.3';

@Component({
  selector: 'pm-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit, OnDestroy {
  @Output('watermarkClicked') watermarkClicked = new EventEmitter<void>();

  showLogo = true;
  showWatermark = true;

  logoSettings$ = this.store$.select(getActiveRootLogoSettings)
  watermarkSettings$ = this.store$.select(getActiveRootWatermarkSettings)

  tempWatermarkSettings: WatermarkSettings;
  private tempImageSettings$ = this.store$.select(selectTempImageState)
  tempImageSettingsSubscription = this.tempImageSettings$.subscribe(imageState => {
    this.tempWatermarkSettings = imageState.tempWatermark;
    this.changeDetection.markForCheck();
  });

  activeLogoSettingsSubscription = combineLatest([this.logoSettings$, this.tempImageSettings$]).pipe(map(([logo, tempImage]) => {
    return tempImage.tempLogo === undefined ? logo : tempImage.tempLogo;
  })).subscribe(logo => {
    if (!!logo && !!logo.link && logo.link['available']) {
      // Url is Blob generated by us.
      this.logoSource = this.sanitizer.bypassSecurityTrustUrl(logo.source);
    } else {
      this.logoSource = logo?.source || '';
    }

    this.activeLogoSetting = logo;
    this.changeDetection.markForCheck();
  });

  activeLogoSetting: LogoSettings = null;
  logoSource: string|SafeUrl;

  activeWatermarkSettingsSubscription = combineLatest([this.watermarkSettings$, this.tempImageSettings$]).pipe(map(([watermark, tempImage]) => {
    return tempImage.tempWatermark === undefined ? watermark : tempImage.tempWatermark;
  })).subscribe(watermark => {
    if (!!watermark && !!watermark.link && watermark.link['available']) {
      // Url is Blob generated by us.
      this.watermarkSource = this.sanitizer.bypassSecurityTrustUrl(watermark.source);
    } else {
      this.watermarkSource = watermark?.source || '';
    }

    this.activeWatermarkSetting = watermark;
    this.changeDetection.markForCheck();
  });

  activeWatermarkSetting: WatermarkSettings = null;
  watermarkSource: string|SafeUrl;

  /*
   * TODO: This is incredibly nasty. It's a way to avoid covering the scrollbar. Preferred way would be to just always be below it.
   *
   * ... Unfortunately, that doesn't seem possible using our current way of laying out the processes.
   */
  @HostBinding('style.right.px') @HostBinding('style.bottom.px') scrollbarWidth: number;

  constructor(private store$: Store<AppState>, private changeDetection: ChangeDetectorRef,
              private sanitizer: DomSanitizer, private offlineService: OfflineTreeService, private ngZone:NgZone) {

  }

  public offlineSynchronizing$ = this.offlineService.synchronizing;
  public offlineSynchronizing = false;
  public offlineSynchronizingSubscription = this.offlineSynchronizing$.subscribe((sync) => {
    this.ngZone.run(() => {
      this.offlineSynchronizing = sync;
      this.changeDetection.markForCheck();
    });
  });

  public get offlinePosition() {
    return this.activeLogoSetting?.position === 'topright' ? 'bottomright' : 'topright';
  }

  get hasLogo(): boolean {
    return this.activeLogoSetting !== null;
  }

  get hasWatermark(): boolean {
    return this.activeWatermarkSetting !== null;
  }

  get logoPositionClass(): string {
    return this.activeLogoSetting && this.activeLogoSetting.position || '';
  }

  get watermarkOpacity(): string {
    return this.activeWatermarkSetting && (this.activeWatermarkSetting.alpha + '') || DEFAULT_OPACITY;
  }

  get watermarkSize(): string {
    return this.activeWatermarkSetting && (this.activeWatermarkSetting.ratio * 100) + '%' || '0';
  }

  get logoWidth(): string {
    if (this.activeLogoSetting) {
      return this.activeLogoSetting.scale && this.activeLogoSetting.width + 'px' || undefined;
    }
  }

  get logoHeight(): string {
    if (this.activeLogoSetting) {
      return this.activeLogoSetting.scale && this.activeLogoSetting.height + 'px' || undefined;
    }
  }

  watermarkClick() {
    this.watermarkClicked.emit();
  }

  ngOnInit(): void {
    this.scrollbarWidth = getScrollbarWidth();
  }

  ngOnDestroy(): void {
    this.tempImageSettingsSubscription.unsubscribe();
    this.activeLogoSettingsSubscription.unsubscribe();
    this.activeWatermarkSettingsSubscription.unsubscribe();
    this.offlineSynchronizingSubscription.unsubscribe();
  }
}
