import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {PasswordReset} from '@process-manager/pm-library/lib/model/user';
import {hasMessage} from '../domain-form/has.message';

@Component({
  selector: 'pm-login-reset-form',
  templateUrl: './reset-form.component.html',
  styleUrls: ['./reset-form.component.css']
})
export class ResetFormComponent implements OnInit {
  @Input() domain: string = '';
  @Input() pending: boolean;
  @Input() error: Error | string;

  @Output() resetRequest = new EventEmitter<PasswordReset>();
  @Output() cancelRequest = new EventEmitter<void>();

  sent = false;
  errorLabel = '';

  constructor(private translateService: TranslateService) {
  }

  get errorMessage(): string {
    if (this.error instanceof HttpErrorResponse && !this.error.status) {
      return this.errorLabel;
    } else if (hasMessage(this.error)) {
      return this.error.message;
    } else {
      return this.error;
    }
  }

  ngOnInit() {
    this.translateService.get('login.error.unknown').subscribe(label => this.errorLabel = label);
  }

  onRetry() {
    this.sent = false;
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      this.resetRequest.emit(form.value);
      this.sent = true;
    }
  }

  onCancel() {
    this.cancelRequest.emit();
  }
}
