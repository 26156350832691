import {Location} from '@angular/common';
import {Injectable, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Params, Router, UrlTree} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';
import {v1} from 'uuid';
import {AppState, getTreeLoading, getTreeSaving} from '../../state-management/reducers';
import {TemplateId} from '../model/template-id';

@Injectable()
export class NavigationService implements OnDestroy {
  private publicUser: boolean;

  private saving: boolean = false;
  isSavingSubscription = this.store$.select(getTreeSaving).subscribe(saving => this.saving = saving);

  private loading: boolean = false;
  isLoadingSubscription = this.store$.select(getTreeLoading).subscribe(loading => this.loading = loading);

  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute,
              private location: Location, private store$: Store<AppState>, private translateService: TranslateService,
              private snackBar: MatSnackBar) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.publicUser = typeof params['user'] !== 'undefined';
    });
  }

  ngOnDestroy(): void {
    this.isLoadingSubscription.unsubscribe();
    this.isSavingSubscription.unsubscribe();
  }

  public replaceNode(id: string | number): void {
    if(!this.getDomain()) {
      return;
    }

    this.location.replaceState(this.getNavigationUrl(id));
  }

  public navigateToNode(id: string | number, publicUser: boolean = this.publicUser): Promise<boolean> {
    return this.conditionalExecution(() => this.doNavigation(id, publicUser));
  }

  private conditionalExecution(exec: () => Promise<boolean>): Promise<boolean> {
    if (this.loading) {
      this.snackBar.open(this.translateService.instant('navigation.loading'), undefined, {duration: 2000});
      return Promise.resolve(false);
    } else if (this.saving) {
      this.snackBar.open(this.translateService.instant('navigation.saving'), undefined, {duration: 2000});
      return Promise.resolve(false);
    } else {
      return exec();
    }
  }

  public navigateToNodeRelative(nodeId: number | string, relativeTo: ActivatedRoute): Promise<boolean> {
    return this.conditionalExecution(() => {
      if (relativeTo.snapshot.url.length === 0) {
        return this.router.navigate(['trees', nodeId], {relativeTo});
      } else if (!!relativeTo.snapshot.params['nodeid'] ||
        relativeTo.snapshot.url[relativeTo.snapshot.url.length - 1]?.path === '') {
        return this.router.navigate(['..', nodeId], {relativeTo});
      } else {
        return this.router.navigate([nodeId], {relativeTo});
      }
    })
  }

  public navigateToTemplate(templateId: TemplateId, nodeId?: string | number) {
    let navigationTarget = '';
    if (!!nodeId) {
      navigationTarget = '' + nodeId;
    }

    this.router.navigateByUrl(this.getTemplateUrlTree(templateId, navigationTarget));
  }

  public getTemplateUrlTree(templateId: TemplateId, nodeId: string) {
    return this.router.createUrlTree(
      ['/domains', this.getDomain, 'templates', templateId.templateId, templateId.templateVersion,
        nodeId]);
  }

  public getTemplateUrl(templateId: TemplateId, nodeId: string) {
    return this.router.serializeUrl(this.getTemplateUrlTree(templateId, nodeId));
  }

  public getNavigationUrl(id: string | number, publicUser: boolean = this.publicUser): string {
    return this.router.serializeUrl(this.getNavigationUrlTree(id, publicUser));
  }

  public getDomainUrl(domain = this.getDomain()): string {
    return this.router.serializeUrl(this.router.createUrlTree(['/domains', domain]));
  }

  public addRandomHash(): void {
    this.router.navigate(this.activatedRoute.snapshot.url, {
      fragment: v1()
    });
  }

  private doNavigation(id: string | number, publicUser: boolean): Promise<boolean> {
    return this.router.navigateByUrl(this.getNavigationUrlTree(id, publicUser));
  }

  private getNavigationUrlTree(id: string | number, publicUser: boolean = this.publicUser): UrlTree {
    const user = this.userService.user;
    const domain = this.getDomain();
    const queryParams: Params = {};
    if (publicUser) {
      queryParams['user'] = user.username;
    }
    if (!!id) {
      return this.router.createUrlTree(['/domains', domain, 'trees', '' + id],
        {queryParams: queryParams});
    } else {
      return this.router.createUrlTree(['/domains', this.userService.domain],
        {queryParams: queryParams});
    }
  }

  private getDomain = () => this.activatedRoute.params['domain'] || this.userService.domain;
}
