<ng-container *ngIf="sent === false">
  <p>{{'login.reset.description' | translate}}</p>
  <p class="domain-label mat-typography">{{'login.chosen-domain' | translate:({domain: domain})}}</p>

  <form (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>
    <input type="hidden" id="pm_domain" name="domain" [ngModel]="domain"/>
    <div class="form-field-container">
      <mat-form-field appearance="outline">
        <mat-label>{{'login.reset.email' | translate}}</mat-label>
        <input autocomplete="email" matInput id="pm_email" name="email" email="true" ngModel required
               [attr.autofocus]="true"/>
      </mat-form-field>
    </div>
    <button [disabled]="pending" mat-raised-button>{{'login.reset.submit' | translate}}</button>
    <div *ngIf="error" class="alert alert-danger">{{errorMessage}}</div>
  </form>
  <a class="routerLink" [routerLink]="" queryParamsHandling="preserve" (click)="onCancel()">{{ 'button.cancel' | translate}}</a>
</ng-container>
<ng-container *ngIf="sent === true">
  <p>{{'login.reset.sent.description' | translate}}</p>
  <a class="routerLink" [routerLink]="" queryParamsHandling="preserve" (click)="onRetry()">{{ 'login.reset.retry' | translate}}</a>
</ng-container>

