import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {DomainResolver} from '@process-manager/pm-library';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AppState, getAuthDomain, getTreeSource} from '../../state-management/reducers';
import {jwtDecode} from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class StoreDomainResolver implements DomainResolver {
  constructor(private store:Store<AppState>) {
  }

  resolveToken(token: string) {
      return jwtDecode(token);
  }

  get api(): string {
        return environment.api
    }

  get domainName$(): Observable<string> {
    return this.store.select(getAuthDomain);
  }

  get isOffline(): Observable<boolean> {
    return this.store.select(getTreeSource).pipe(map((treeSource) => treeSource === 'offline'));
  }
}
