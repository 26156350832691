export function isTemplateId(candidate: any): candidate is TemplateId {
  return !!candidate && !!candidate['templateId'] && !!candidate['templateVersion'];
}

export function templateIdEquals(a: TemplateId, b: TemplateId) {
  return a?.templateId === b?.templateId && a?.templateVersion === b?.templateVersion;
}

export interface TemplateId {
  templateId: string,
  templateVersion: string
}

export interface TemplateIdWithMap extends TemplateId {
  languageMap: {
    [key: string]: number;
  }
}
