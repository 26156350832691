<form (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>
  <h2 mat-dialog-title>{{'dialog.contact.title' | translate}}</h2>
  <mat-dialog-content class="pm-contact-dialog-content">
    <div class="pm-owner-text">{{'dialog.contact.owner-email' | translate}} <span class="pm-owner-email">{{context.owner}}</span>
    </div>
    <mat-form-field>
      <input matInput id="pm_sender_name" name="sender_name" placeholder="{{'dialog.contact.sender-name' | translate}}"
             type="text" [ngModel]="fullName" required [attr.autofocus]="fullName ? null : true">
    </mat-form-field>
    <mat-form-field>
      <input matInput id="pm_sender_email" name="sender_email"
             placeholder="{{'dialog.contact.sender-email' | translate}}" type="email" [ngModel]="email">
    </mat-form-field>
    <mat-form-field>
      <textarea matInput id="pm_email_message" name="email_content"
                placeholder="{{'dialog.contact.message' | translate}}" ngModel required
                [attr.autofocus]="fullName ? true : null"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-button type="submit" [disabled]="f.pristine || f.invalid || pending"><span class="pm-inliner"><mat-spinner
      diameter="16" *ngIf="pending"></mat-spinner>{{'button.send' | translate}}</span></button>
    <button mat-button type="button" (click)="onCancelClick()">{{'button.cancel' | translate}}</button>
  </div>
</form>
