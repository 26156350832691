import {animate, query, stagger, style} from '@angular/animations';

export const staggeredFadeInAnimation = query('button', [style({
  opacity: 0
}), stagger(25, [animate('25ms 100ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
  opacity: 1
}))])]);

export const fadeOutAnimation = query('button', [style({opacity: 1}), animate('50ms', style({
  opacity: 0,
}))]);
