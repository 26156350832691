import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Authenticate, PasswordReset} from '@process-manager/pm-library/lib/model/user';
import {Observable, of, Subscription} from 'rxjs';
import {filter, flatMap, map} from 'rxjs/operators';
import {DomainChosen, Login, PasswordResetRequest} from '../state-management/actions/auth.actions';
import {
  AppState, getLoginPageDomain, getLoginPageError, getLoginPageLocal, getLoginPagePending, getLoginPageReturnUrl
} from '../state-management/reducers';

@Component({
  selector: 'pm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  pending$ = this.store$.select(getLoginPagePending);
  error$ = this.store$.select(getLoginPageError);
  domain$ = this.store$.select(getLoginPageDomain);
  local$ = this.store$.select(getLoginPageLocal);
  returnUrl$ = this.store$.select(getLoginPageReturnUrl);
  returnUrl: string;

  returnUrlSubscription = this.returnUrl$.subscribe(returnUrl => this.returnUrl = returnUrl);

  state: 'domain' | 'login' | 'reset' = 'domain';
  private localSubscription: Subscription;
  public translatedError$: Observable<Error | string>;

  constructor(private store$: Store<AppState>, private translateService: TranslateService, private route: ActivatedRoute) {
  }

  ngOnDestroy(): void {
    this.returnUrlSubscription.unsubscribe();
  }

  ngOnInit() {
    this.translatedError$ = this.error$.pipe(
      flatMap(([error, details]) => error?.message &&
      this.translateService.get(error.message, {details}).pipe(map(translatedError => ({
        ...error,
        message: translatedError
      }))) || of('')));

    this.localSubscription = this.local$.pipe(filter(local => local)).subscribe(() => this.state = 'login');
    this.route.queryParams.pipe(filter(params => !!params.resetPassword)).subscribe(() => this.state = 'reset');
}

  onDomainChosen($event: string) {
    this.store$.dispatch(new DomainChosen({domain: $event, returnUrl: this.returnUrl}));
  }

  onAuthenticated(authenticate: Authenticate): void {
    this.store$.dispatch(new Login({auth: authenticate}));
  }

  onReset() {
    this.state = 'reset';
  }

  onResetCancel() {
    this.state = 'login';
  }

  onResetRequest(passwordReset: PasswordReset) {
    this.store$.dispatch(new PasswordResetRequest({resetRequest: passwordReset, language: this.translateService.getBrowserLang()}))
  }

  onBackToDomains() {
    this.state = 'domain';
  }
}
