<h1 mat-dialog-title>{{'dialog.label-options.title' | translate}}</h1>
<mat-dialog-content [formGroup]="labelOptionsForm" class="mat-typography">
  <div class="options">
    <div class="pm-horizontal-group">
      <mat-checkbox formControlName="show">{{'dialog.label-options.show' | translate}}</mat-checkbox>
      <mat-checkbox formControlName="inherit">{{'dialog.label-options.inherit' | translate}}</mat-checkbox>
      <mat-checkbox formControlName="descendents">{{'dialog.label-options.descendents' | translate}}</mat-checkbox>
    </div>
    <hr>
  </div>
  <div class="filter">
    <div class="controls">
      <h3>{{'dialog.label-options.filtering' | translate}}</h3>
      <div class="checkbox-wrapper">
        <mat-checkbox
          formControlName="enableFilter">{{'dialog.label-options.filtering.checkbox' | translate}}</mat-checkbox>
      </div>
    </div>
    <div class="labels-wrapper">
      <div class="pm-horizontal-group labels">
        <mat-checkbox *ngFor="let labelId of labels.controls | keys; let i = index;"
                      [formControl]="filterControl(labelId)">
          <div class="label-wrapper">
            <span>{{getLabel(labelId).name}}</span>
          </div>
        </mat-checkbox>
      </div>
    </div>
    <p>
      <small>{{'dialog.label-options.filtering.description' | translate}}
      </small>
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="output">{{'button.ok' | translate}}</button>
  <button mat-button mat-dialog-close="">{{'button.cancel' | translate}}</button>
</mat-dialog-actions>
