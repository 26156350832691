<h1 mat-dialog-title>{{'dialog.language-admin.dictionary' | translate}}<mat-spinner matSuffix [class.proman-visible]="loading" [diameter]="18"></mat-spinner></h1>

<div mat-dialog-content [formGroup]="dictionaryForm" #dialogContent>
  <div class="pm-warnings" *ngFor="let warn  of warnings">
    {{'dialog.language-admin.dictionary.warning.' + warn | translate}}
  </div>

  <table mat-table [dataSource]="dataSource">

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row, let i = index; columns: columns;" [formGroup]="getGroup(i)"></tr>

    <ng-container *ngFor="let lang of sortedLanguages" [matColumnDef]="getColumnId(lang)">
      <th mat-header-cell *matHeaderCellDef>{{getLangName(lang)}}</th>
      <td class="pm-input-td" mat-cell *matCellDef="let i = index;"><input class="pm-table-input" [placeholder]="getPlaceholder(lang)" [formControl]="getControl(i, getColumnId(lang))"></td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let i = index;">
          <zx-responsive-menu [subject]="i">
            <zx-responsive-menu-item (clicked)="deleteRow($event)"
                                     [tooltip]="'Delete row' | translate" iconName="delete">
            </zx-responsive-menu-item>
          </zx-responsive-menu>
      </td>
    </ng-container>
  </table>

  <div class="pm-delay-warning mat-typography">{{'dialog.language-admin.dictionary.delay-warning' | translate}}</div>
</div>

<div mat-dialog-actions>
  <button mat-button type="submit" (click)="onSubmit()" [disabled]="loading || error || saving || dictionaryForm.pristine">
    <span class="inliner"><mat-spinner diameter="16" *ngIf="saving"></mat-spinner>{{'button.ok' | translate}}</span></button>
  <button mat-button type="button" mat-dialog-close="">{{'button.cancel' | translate}}</button>
  <button mat-button type="button" (click)="addRow()" [disabled]="error || saving">{{'button.add' |
    translate}}
  </button>
  <div class="pm-validation-warnings" *ngIf="dictionaryForm.invalid">
    {{'dialog.language-admin.dictionary.validation-errors.' + prioritisedValidationError | translate}}
  </div>
</div>
