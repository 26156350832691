import {Action} from '@ngrx/store';

export enum FormatActionTypes {
  ACTIVATE_FORMATTING = '[Format] Activat formatting mode', //
  SET_FORMAT_SOURCE = '[Format] Set style source', //
  APPLY_FORMAT = '[Format] Apply new style', //
  DEACTIVATE_FORMATTING = '[Format] Deactivate formatting mode'
}

export class ActivateFormatting implements Action {
  readonly type = FormatActionTypes.ACTIVATE_FORMATTING;
}

export class SetFormatSource implements Action {
  readonly type = FormatActionTypes.SET_FORMAT_SOURCE;

  constructor(public payload: { id: string }) {
  }
}

export class ApplyFormat implements Action {
  readonly type = FormatActionTypes.APPLY_FORMAT;

  // Since the tree reducer is the receiver, it doesn't know the source. Label is needed for server side
  constructor(public payload: { sourceId: string, targetId: string, restyledLabel: string }) {
  }
}

export class DeactivateFormatting implements Action {
  readonly type = FormatActionTypes.DEACTIVATE_FORMATTING;
}

export type FormattingActions = ActivateFormatting | SetFormatSource | DeactivateFormatting;
