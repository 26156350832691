import {Location} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {ServerUser, UserService} from '@process-manager/pm-library';
import {mergeMap, Observable, zip} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Tree} from '../model/tree';
import {TreeElement} from '../model/treeelement';
import {NavigationService} from './navigation.service';

interface ErrorReportDetails {
  error: Error;
  action: Action;
  liftedState: any;
}

@Injectable()
export class ContactService {
  constructor(private http: HttpClient, private navigationService: NavigationService, private location: Location,
    private translateService: TranslateService, private userService: UserService) {
  }

  public contactOwner(domain: string, tree: Tree, element: TreeElement, senderName: string, senderEmail: string,
                      message: string): Observable<void> {
    const recipient = element.owner;

    const path = this.generatePath(tree, element);
    const externalUrl = this.getExternalUrl(element.id);

    return zip(this.translateService.get('dialog.contact.suggestion', {process: path}).pipe(),
      this.translateService.get('dialog.contact.suggestion', {process: externalUrl}).pipe(),
      this.translateService.get('dialog.contact.sender-message', {senderName}))
      .pipe(mergeMap(([subject, suggestion, author]) => {
        const body = suggestion + '\r\n\r\n' + author + '\r\n' + message;
        return this.sendMail(domain, body, senderName, senderEmail, recipient, subject);
      }));
  }

  public reportError(details: ErrorReportDetails) {
    const url = environment.api + this.userService.domain + '/error';
    const options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

    return this.http.post<void>(url, JSON.stringify(details), options);
  }

  public sendPassword(newUser: ServerUser, isNewUser: boolean): Observable<void> {
    const sendingUser = this.userService.user;
    const senderName = [sendingUser.firstName, sendingUser.lastName].filter(Boolean).join(' ').trim();

    const message = isNewUser ? 'dialog.user-admin.new-user.message' : 'dialog.user-admin.new-password.message';
    return zip(this.translateService.get('dialog.user-admin.new-password.subject', {username: newUser.username}),
      this.translateService.get(message, {
        url: this.getExternalDomainUrl(),
        username: newUser.username,
        password: newUser.password
      })).pipe(mergeMap(
      ([subject, body]) => this.sendMail(this.userService.domain, body, senderName, sendingUser.email, newUser.email,
        subject)));
  }

  private sendMail(domain: string, body: string, senderName: string, senderEmail: string, recipient: string,
                   subject: string): Observable<void> {
    const url = environment.api + domain + '/emails';
    const options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

    return this.http.post<void>(url, JSON.stringify({
      from: !!senderEmail ? `${senderName} <${senderEmail}>` : null ,
      recipient: recipient,
      subject: subject,
      body: body
    }), options);
  }

  private getExternalDomainUrl(): string {
    return window.location.origin + this.location.prepareExternalUrl(this.navigationService.getDomainUrl());
  }

  private getExternalUrl(id: string): string {
    return window.location.origin + this.location.prepareExternalUrl(this.navigationService.getNavigationUrl(id));
  }

  private getParents(tree: Tree, element: TreeElement): TreeElement[] {
    const parents: TreeElement[] = [];

    do {
      parents.push(element);
      element = tree.nodes.get(element.parentId);
    } while (element);

    return parents.reverse();
  }

  private generatePath(tree: Tree, element: TreeElement): string {
    return tree.path.slice(0, -1).map(pathElement => pathElement.label)
      .concat(this.getParents(tree, element).map(elem => elem.label.replace(/<[^>]+>/ig, ''))).join('/');
  }
}
