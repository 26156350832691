import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Link} from '../../../shared/model/link';
import {getLinkColor} from '../shared/proman-link.service';
import {RenderedElement} from "../../../shared/model/rendered/rendered-element";

@Component({
  selector: 'pm-link-overlay',
  templateUrl: './link-overlay.component.html',
  styleUrls: ['./link-overlay.component.css']
})
export class LinkOverlayComponent implements OnInit {
  @Input() node: RenderedElement;
  @Input() highlightInfo;

  // This will just be the first link if merged
  @Output('linkClicked') linkClicked = new EventEmitter<Link>();

  constructor() {
  }

  get links() {
    const node = this.node;
    let nodeLinks = this.node?.links;
    if (!!node?.info || !!node?.extendedInfo) {
      nodeLinks = [{
        id: '-1',
        label: (node.info || '') + (!!node.extendedInfo ? '\n...' : ''),
        linkType: 'info',
        action: '',
        color: node.infoColor
      }, ...nodeLinks];
    }

    return nodeLinks.filter(link => !link.adopted && !['watermark', 'logo'].includes(link.linkType));
  }

  ngOnInit() {
  }

  clicked($event, link: Link) {
    $event.preventDefault();
    if ($event.stopImmediatePropagation) {
      $event.stopImmediatePropagation();
    }
    this.linkClicked.emit(link);
  }

  // noinspection JSUnusedLocalSymbols
  getLinkId(index: number, link: Link): string {
    return link ? link.id : '';
  }

  getTooltip(link: Link): string {
    return !!link && link.label;
  }

  linkColor(link: Link): string {
    return getLinkColor(link).hex();
  }

  highlight(link: Link) {
    return this.highlightInfo && link.linkType === 'info';
  }

  prevent() {
    return false;
  }
}
