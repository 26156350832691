import {Directive, ElementRef, EventEmitter, Input, OnDestroy, Output} from '@angular/core';

@Directive({
  selector: '[pmClickedOutside]'
})
export class ClickedOutsideDirective implements OnDestroy {
  private lastClickedElement: any;

  @Output()
  pmClickedOutside = new EventEmitter<void>()

  @Input()
  set clickedOutsideActive(isActive: boolean) {
    if(isActive) {
      this.activate();
    } else {
      this.deactivate();
    }
  }

  private activate() {
    document.addEventListener('mousedown', this.onMouseDown, {passive: true})
    document.addEventListener('mouseup', this.onMouseUp, {passive: true})
  }

  private deactivate() {
    document.removeEventListener('mousedown', this.onMouseDown)
    document.removeEventListener('mouseup', this.onMouseUp)
    this.lastClickedElement = undefined;
  }

  private hasOpenOverlays(): boolean {
    return document.querySelector('.cdk-overlay-container')?.children.length > 0;
  }

  private shouldRegisterClick(event: MouseEvent): boolean {
    return !this.elementRef.nativeElement.contains(event.target) && !this.hasOpenOverlays();
  }

  onMouseDown = (event: MouseEvent) => {
    const nativeElement = this.elementRef.nativeElement;
    if (this.shouldRegisterClick(event)) {
      this.lastClickedElement = nativeElement;
    }
  }

  onMouseUp = (event: MouseEvent) => {
    const nativeElement = this.elementRef.nativeElement;
    if (this.shouldRegisterClick(event) && this.lastClickedElement === nativeElement) {
      this.pmClickedOutside.emit();
    } else {
      this.lastClickedElement = null;
    }
  }

  constructor(private elementRef: ElementRef) { }

  ngOnDestroy(): void {
    this.deactivate();
  }
}
