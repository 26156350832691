import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {EffectsModule} from '@ngrx/effects';
import {MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  BlockedPopupReporter, DomainResolver, PmLibraryModule, UserService, XsrfInterceptor
} from '@process-manager/pm-library';
import {ClipboardModule} from 'ngx-clipboard';
import {ColorChromeModule} from 'ngx-color/chrome';

import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {AppConfig} from './app.config';
import {AppRoutingModule} from './app.routing-module';
import {CantOpenDialogComponent} from './error-dialogs/cant-open-dialog/cant-open-dialog.component';
import {GenericErrorDialogComponent} from './error-dialogs/generic-error-dialog/generic-error-dialog.component';
import {LoginModule} from './login/login.module';
import {MainComponent} from './main';
import {ConfirmDialogComponent} from './main/confirm-navigation/confirm-dialog.component';
import {TemplateLanguageDialogComponent} from './main/copy-template-dialog/template-language-dialog.component';
import {LabelAdminDialogComponent} from './main/label-admin-dialog/label-admin-dialog.component';
import {
  AutoTranslateDictionaryDialogComponent
} from './main/language-admin-dialog/auto-translate-dictionary-dialog/auto-translate-dictionary-dialog.component';
import {LanguageAdminDialogComponent} from './main/language-admin-dialog/language-admin-dialog.component';
import {LogbookDialogComponent} from './main/logbook-dialog/logbook-dialog.component';
import {LogbookEntryComponent} from './main/logbook-dialog/logbook-entry/logbook-entry.component';
import {PasswordChangeDialogComponent} from './main/password-change-dialog/password-change-dialog.component';
import {BulletListComponent} from './main/process-tree/bullet-list/bullet-list.component';
import {BulletComponent} from './main/process-tree/bullet-list/bullet/bullet.component';
import {ContactDialogComponent} from './main/process-tree/contact-dialog/contact-dialog.component';
import {ExtraOptionsDialogComponent} from './main/process-tree/extra-options-dialog/extra-options-dialog.component';
import {LabelsOverlayComponent} from './main/process-tree/labels-overlay/labels-overlay.component';
import {LinkDialogComponent} from './main/process-tree/link-dialog/link-dialog.component';
import {EmbedCardComponent} from './main/process-tree/link-menu/embedcard/embedcard.component';
import {EmbeddedDialogComponent} from './main/process-tree/link-menu/embedded-dialog/embedded-dialog.component';
import {LinkMenuComponent} from './main/process-tree/link-menu/link-menu.component';
import {LinkOverlayComponent} from './main/process-tree/link-overlay/link-overlay.component';
import {LogoComponent} from './main/process-tree/logo/logo.component';
import {MenuTriggerOverlayComponent} from './main/process-tree/menu-trigger-overlay/menu-trigger-overlay.component';
import {OptionsOverlayComponent} from './main/process-tree/options-overlay/options-overlay.component';
import {PmRowComponent} from './main/process-tree/pm-row/pm-row.component';
import {ProcessComponent} from './main/process-tree/process';
import {ProcessTreeComponent} from './main/process-tree/process-tree.component';
import {ShapeComponent} from './main/process-tree/process/shape/shape.component';
import {LinkClickDirective} from './main/process-tree/shared/directives/link-click.directive';

import {NoEmbedService} from './main/process-tree/shared/no-embed.service';
import {PromanLinkService} from './main/process-tree/shared/proman-link.service';
import {UnsafeHtmlPipe} from './main/process-tree/shared/unsafe-html.pipe';
import {UnsafeUrlPipe} from './main/process-tree/shared/unsafe-url.pipe';
import {
  PublishErrorDialogComponent
} from './main/publish-template-dialog/publish-error-dialog/publish-error-dialog.component';
import {PublishTemplateDialogComponent} from './main/publish-template-dialog/publish-template-dialog.component';
import {LinkCheckComponent} from './main/reports/link-check/link-check.component';
import {OwnerListComponent} from './main/reports/owners/owner-list.component';
import {PermissionListComponent} from './main/reports/permissions/permission-list.component';
import {StatisticsDialogComponent} from './main/reports/statistics-dialog/statistics-dialog.component';

import {SubscriptionDialogComponent} from './main/subscription-dialog/subscription-dialog.component';
import {TemplateAdminDialogComponent} from './main/template-admin-dialog/template-admin-dialog.component';
import {ToolbarsComponent} from './main/toolbars';
import {AboutDialogComponent} from './main/toolbars/about-dialog/about-dialog.component';
import {BreadcrumbsComponent} from './main/toolbars/breadcrumbs';
import {AllHistoryDialogComponent} from './main/toolbars/history-view/all-history-dialog/all-history-dialog.component';
import {EventBlockViewComponent} from './main/toolbars/history-view/event-block-view/event-block-view.component';
import {
  DefaultHistoryRendererComponent
} from './main/toolbars/history-view/event-block-view/renderers/default-history-renderer/default-history-renderer.component';
import {
  NodeLabelsChangedRendererComponent
} from './main/toolbars/history-view/event-block-view/renderers/node-labels-changed-renderer/node-labels-changed-renderer.component';
import {
  NodeLinksChangedRendererComponent
} from './main/toolbars/history-view/event-block-view/renderers/node-links-changed-renderer/node-links-changed-renderer.component';
import {
  ResourceColorChangedRendererComponent
} from './main/toolbars/history-view/event-block-view/renderers/resource-color-changed-renderer/resource-color-changed-renderer.component';
import {HistoryViewPopupComponent} from './main/toolbars/history-view/history-view-popup.component';
import {LabelOptionsDialogComponent} from './main/toolbars/label-options-dialog/label-options-dialog.component';
import {LanguageSelectorComponent} from './main/toolbars/language-selector';
import {LogoDialogComponent} from './main/toolbars/logo-dialog/logo-dialog.component';
import {SearchComponent} from './main/toolbars/search/search.component';
import {ViewOptionsMenuComponent} from './main/toolbars/view-options-menu/view-options-menu.component';
import {
  TempPasswordDialogComponent
} from './main/user-admin-dialog/temp-password-dialog/temp-password-dialog.component';
import {UserAdminDialogComponent} from './main/user-admin-dialog/user-admin-dialog.component';

import {ProcessManagerMaterialModule} from './material/material.module';
import {ClickedOutsideDirective} from './shared/clicked-outside.directive';
import {SharedComponentsModule} from './shared/components/shared-components.module';
import {AuthGuard} from './shared/guards/auth.guard';
import {LanguageInterceptorService} from './shared/interceptors/language-interceptor.service';
import {CredentialsInterceptor} from './shared/interceptors/localhost-interceptor.service';
import {RefreshInterceptor} from './shared/interceptors/refresh-interceptor.service';
import {ConvertToPipe} from './shared/pipes/convertTo.pipe';
import {KeysPipe} from './shared/pipes/keys.pipe';
import {LangDatePipe} from './shared/pipes/lang-date.pipe';
import {ContactService} from './shared/services/contact.service';
import {FavoriteService} from './shared/services/favorite.service';
import {InteractService} from './shared/services/interact.service';
import {MatDialogBlockedPopupReporterService} from './shared/services/mat-dialog-blocked-popup-reporter.service';
import {NavigationService} from './shared/services/navigation.service';
import {PwaUpdateService} from './shared/services/pwa-update.service';
import {StoreDomainResolver} from './shared/services/store-domain-resolver';
import {TreeService} from './shared/services/tree.service';
import {CustomerTreeBuilder} from './shared/tree-building/customer-tree-builder.service';
import {TrumbowygDirective} from './shared/trumbowyg.directive';
import {TreeActionTypes} from './state-management/actions/tree.actions';
import {AuthEffects} from './state-management/effects/auth.effects';
import {FavoriteEffects} from './state-management/effects/favorite.effects';
import {TemplatesEffects} from './state-management/effects/templates.effects';
import {TreeEffects} from './state-management/effects/tree.effects';
import {ViewOptionsEffects} from './state-management/effects/view-options.effects';
import {AppState, reducers} from './state-management/reducers/';

class LocaleStrippingTranslateHttpLoader extends TranslateHttpLoader {
  constructor(http: HttpClient, prefix?: string, suffix?: string) {
    super(http, prefix, suffix);
  }

  getTranslation(lang: string): any {
    return super.getTranslation(lang.toLowerCase().replace(/[-_].*/, ''));
  }
}

export const translateLoaderFactory = (http: HttpClient) => new LocaleStrippingTranslateHttpLoader(http,
  './assets/i18n/', '.json');

export const loadProviders = (provider: NoEmbedService) => () => provider.load();

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

@NgModule({
  declarations: [AppComponent, ProcessComponent, ProcessTreeComponent, BreadcrumbsComponent, ToolbarsComponent,
    LanguageSelectorComponent, MainComponent, AppComponent, UnsafeHtmlPipe, UnsafeUrlPipe, BulletListComponent,
    BulletComponent, ShapeComponent, LinkMenuComponent, EmbedCardComponent, EmbeddedDialogComponent,
    LogoDialogComponent, LanguageSelectorComponent, ContactDialogComponent, TrumbowygDirective, OptionsOverlayComponent,
    SearchComponent, ConfirmDialogComponent, LinkDialogComponent, LinkOverlayComponent, ExtraOptionsDialogComponent,
    LogoComponent, AboutDialogComponent, UserAdminDialogComponent, LanguageAdminDialogComponent,
    TempPasswordDialogComponent, PasswordChangeDialogComponent, LogbookDialogComponent, LogbookEntryComponent,
    LangDatePipe, GenericErrorDialogComponent, CantOpenDialogComponent, LinkClickDirective, LabelAdminDialogComponent,
    TemplateAdminDialogComponent, PublishTemplateDialogComponent, PmRowComponent, LabelsOverlayComponent,
    ViewOptionsMenuComponent, AutoTranslateDictionaryDialogComponent, SubscriptionDialogComponent, OwnerListComponent,
    LinkCheckComponent, TemplateLanguageDialogComponent, PublishErrorDialogComponent, StatisticsDialogComponent,
    LabelOptionsDialogComponent, KeysPipe, HistoryViewPopupComponent, EventBlockViewComponent,
    NodeLabelsChangedRendererComponent, DefaultHistoryRendererComponent, NodeLinksChangedRendererComponent,
    AllHistoryDialogComponent, ConvertToPipe, ResourceColorChangedRendererComponent, PermissionListComponent,
    MenuTriggerOverlayComponent, ClickedOutsideDirective],
  imports: [PmLibraryModule, AppRoutingModule, BrowserModule, BrowserAnimationsModule, FormsModule,
    SharedComponentsModule, ReactiveFormsModule, ProcessManagerMaterialModule, StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }), EffectsModule.forRoot([AuthEffects, TreeEffects, FavoriteEffects, TemplatesEffects, ViewOptionsEffects]),
    StoreDevtoolsModule.instrument({
      logOnly: !!environment.production,
      maxAge: 60,
      name: 'ProcessManager',
      actionsBlocklist: [TreeActionTypes.DEACTIVATE_ELEMENT, TreeActionTypes.ACTIVATE_ELEMENT],
      connectInZone: true
    }), HttpClientJsonpModule, HttpClientModule, LoginModule, ClipboardModule, TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translateLoaderFactory),
        deps: [HttpClient]
      }
    }), ColorChromeModule, MatExpansionModule, ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true, // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately'
    }), MatSlideToggleModule],
  providers: [AppConfig, TreeService, AuthGuard, NoEmbedService, FavoriteService, ContactService, UserService,
    PromanLinkService, {
      provide: APP_INITIALIZER,
      useFactory: loadProviders,
      deps: [NoEmbedService, PwaUpdateService],
      multi: true
    }, CustomerTreeBuilder, NavigationService, InteractService, {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptorService,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: XsrfInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshInterceptor,
      multi: true
    }, {
      provide: DomainResolver,
      useClass: StoreDomainResolver
    }, {
      provide: BlockedPopupReporter,
      useClass: MatDialogBlockedPopupReporterService
    }, {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'}
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
