import * as Immutable from 'immutable';
import {stripHtml} from 'string-strip-html';
import {HighlightSelectionType} from '../../state-management/reducers';
import {ActivationType, NodeActivation, TreeState} from '../../state-management/reducers/tree.reducer';
import {ViewOptionsState} from '../../state-management/reducers/view-options.reducer';
import {Label} from '../model/label';
import {Link} from '../model/link';
import {isProcess, Process} from '../model/process';
import {ElementFlags, RenderedElement} from '../model/rendered/rendered-element';
import {RenderedProcess} from '../model/rendered/rendered-process';
import {TemplateId} from '../model/template-id';
import {TreeElement} from '../model/treeelement';

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

class TreeViewRenderer {
  private nodes: Immutable.Map<string, TreeElement>;
  private activeNode: NodeActivation;
  private root: TreeElement;
  private links: Immutable.Map<string, Link>;
  private labelsFilter: number[];
  private labelsFilterEnabled: boolean;
  private openRowIds: string[];
  private openRows: RenderedProcess[] = [];

  private labels: Immutable.Map<number, Label>;
  renderOpenRows = (treeState: TreeState, translationHighLights: HighlightSelectionType, formattingSourceId: string,
    rows: TreeElement[], viewOptionsState: ViewOptionsState): RenderedProcess[] => {
    this.activeNode = treeState.activeNode;
    this.links = treeState.tree.links;
    this.labels = treeState.tree.labels;
    this.labelsFilterEnabled = viewOptionsState.labelSettings.enableFilter;

    this.labelsFilter = viewOptionsState.labelSettings.enableFilter ? viewOptionsState.labelSettings.labels : [];
    const writableNodes = treeState.tree.nodes.withMutations(map => {
      map.forEach((elem, key) => map.set(key, {...elem}));
    }) as Immutable.Map<string, Writeable<TreeElement>>;

    this.root = writableNodes.get(treeState.tree.root);
    this.nodes = writableNodes;

    const treeElements = writableNodes.toArray();
    const parentIds = [...new Set(treeElements.map(elem => elem.parentId))];
    const elementsByParentId = new Map<string, TreeElement[]>();
    parentIds.forEach(parentId => {
      elementsByParentId.set(parentId, treeElements.filter(elem => elem.parentId === parentId));
    })

    const leafElements = treeElements.filter(cand => !parentIds.includes(cand.id));

    this.updateDescendents(writableNodes, leafElements);
    this.updateAncestors(elementsByParentId, this.root);

    this.openRowIds = rows.map(row => row.id);
    const languageIsDefault = treeState?.activeLanguage?.isDefault || false;
    const highlightChecker = this.getHighlights(languageIsDefault, formattingSourceId, translationHighLights);

    if (!!this.root) {
      this.renderProcess({
        owner: this.root?.owner || '',
        permissions: undefined,
        writeable: this.root?.writeable || false,
        interested: true
      }, highlightChecker)(this.root);
    }

    return this.openRows;
  }

  private missingLabels(old: number[], add: number[]) {
    return add.filter(label => !old.includes(label));
  }

  private updateDescendents(treeElements: Immutable.Map<string, Writeable<TreeElement>>,
    leafElements: Writeable<TreeElement>[]) {
    leafElements.forEach(elem => {
      if ((elem.labels ?? []).length > 0) {
        elem.fullyLabeled = true;
      }
      while (elem?.parentId) {
        const temp = treeElements.get(elem.parentId);
        if (!!temp) {
          temp.descendentLabels = this.missingLabels(temp.labels || [],
            [...new Set([...temp.descendentLabels || [], ...elem.labels || [], ...elem.descendentLabels || []])]);
          if (isProcess(temp) && !temp.hasUnloadedChildren) {
            (temp as Writeable<Process>).fullyLabeled = (temp.labels ?? []).length > 0 ||
              ((temp.fullyLabeled === null || !!temp.fullyLabeled) &&
                (elem.fullyLabeled || (elem.labels ?? []).length > 0));
          }
        }
        elem = temp;
      }
    })
  }

  private updateAncestors(elementsByParentID: Map<string, Writeable<TreeElement>[]>, parent: Writeable<TreeElement>) {
    const children = elementsByParentID.get(parent?.id || 'nosuchparent') || [];
    children.forEach(child => {
      child.ancestorLabels =
        this.missingLabels(child.labels || [], [...new Set([...parent.labels || [], ...parent.ancestorLabels || []])]);
      this.updateAncestors(elementsByParentID, child);
    })
  }

  private renderProcess = (parent: Pick<RenderedProcess, 'owner' | 'permissions' | 'writeable' | 'interested'>,
    highlightChecker: (node: TreeElement,
      activation: ActivationType) => ElementFlags) => (process: Process & Partial<TemplateId>): RenderedProcess => {
    const getNode = id => this.nodes.get(id);

    const parentIsLast = this.openRowIds[this.openRowIds.length - 1] === process.parentId;
    const isOpen = this.openRowIds.includes(process.id);
    const activation = this.activeNode?.id === process.id && this.activeNode?.type || null
    const isMainPage = this.root.type === 'mainpage';

    const renderedProcess = {
      ...this.renderElement(parent, highlightChecker)(process),
      children: [],
      bullets: [],
      siblingOpened: !isMainPage && !parentIsLast && !isOpen && activation === null,
      hasUnloadedChildren: process.hasUnloadedChildren,
      isOpen: this.openRowIds.includes(process.id),
      needsSave: !process.serverId && process.hasUnloadedChildren,
      childSize: process.childSize
    } as RenderedProcess;

    if (isOpen) {
      this.openRows.push(renderedProcess);
    }

    renderedProcess.children = (process.nodes || []).map(getNode).filter(Boolean)
      .map(this.renderProcess(renderedProcess, highlightChecker));

    renderedProcess.bullets = (process.bullets || []).map(getNode).filter(Boolean)
      .map(this.renderElement(renderedProcess, highlightChecker));

    return renderedProcess;
  }

  private getHighlights = (languageIsDefault: boolean, formattingSourceId: string,
    translationHighlights: HighlightSelectionType) => (treeElement: TreeElement,
    activation: ActivationType): ElementFlags => {
    if ((activation === 'highlight') || formattingSourceId === treeElement.id) {
      return {
        link: false,
        info: false,
        text: true
      };
    }

    switch (translationHighlights) {
      case 'translated':
        return languageIsDefault ? {
          link: false, // TODO
          info: treeElement.anyTranslatedInfo,
          text: treeElement.anyTranslatedText
        } : {
          link: !treeElement.defLinks,
          info: !treeElement.defInfo && !treeElement.infoAutoTranslated,
          text: !treeElement.defText && !treeElement.textAutoTranslated
        };
      case 'untranslated':
        return {
          link: !!treeElement.defLinks,
          info: !!treeElement.defInfo || treeElement.infoAutoTranslated,
          text: !!treeElement.defText || treeElement.textAutoTranslated
        };
      default:
        return {
          link: false,
          info: false,
          text: false
        };
    }
  }


  private makeHtmlTextUnDraggable = (htmlText: string): string => {
    return stripHtml(htmlText, {
      cb: ({
        tag,
        rangesArr
      }) => {
        if (tag && tag.name === 'a' && !tag.slashPresent) {
          rangesArr.push([tag.nameEnds, tag.nameEnds, ' draggable="false"']);
        }
      }
    }).result.trim();
  }

  private renderElement = (parent: Pick<RenderedProcess, 'owner' | 'permissions' | 'writeable' | 'interested'>,
    highlightChecker: (node: TreeElement,
      activation: ActivationType) => ElementFlags) => (element: TreeElement & Partial<TemplateId>): RenderedElement & Partial<TemplateId> => {
    const owner = element.owner || parent.owner;
    const permissions = element.permissions || parent.permissions;
    const links = (element.links || []).map(linkId => this.links.get(linkId)).filter(link => !!link);

    const activation = this.activeNode?.id === element.id && this.activeNode?.type || null;

    const defaults: ElementFlags = {
      info: element.defInfo,
      text: element.defText,
      link: element.defLinks
    }

    const autoTranslated: ElementFlags = {
      info: element.infoAutoTranslated,
      text: element.textAutoTranslated,
      link: false
    }

    const getLabel = labelId => this.labels.get(labelId);

    return {
      id: element.id,
      parentId: element.parentId,
      nodeType: element.type,
      label: this.makeHtmlTextUnDraggable(element.label),
      style: element.style,
      links: links,
      info: element.info,
      infoColor: element.infoColor,
      extendedInfo: element.extendedInfo,
      activation: activation,
      owner: owner,
      ownerInherited: !element.owner,
      writeable: element.writeable,
      parentWriteable: parent.writeable,
      highlights: highlightChecker(element, activation),
      defaults: defaults,
      autoTranslated: autoTranslated,
      searchable: element.searchable,
      permissions: permissions,
      permissionsInherited: !element.permissions,
      labels: (element.labels || []).map(getLabel),
      ancestorLabels: (element.ancestorLabels || []).map(getLabel),
      descendentLabels: (element.descendentLabels || []).map(getLabel),
      interested: parent.interested && (!this.labelsFilterEnabled || !element.fullyLabeled ||
        [...element.labels || [], ...element.descendentLabels || []].some(x => this.labelsFilter.includes(x))),
      templateVersion: element.templateVersion,
      templateId: element.templateId,
      backup: element.backup,
      fullyLabeled: element.fullyLabeled
    };
  }
}

export const renderOpenRows = (treeState: TreeState, translationHighLights: HighlightSelectionType,
  formattingSourceId: string, rows: TreeElement[], viewOptionsState: ViewOptionsState): RenderedProcess[] => {
  return new TreeViewRenderer().renderOpenRows(treeState, translationHighLights, formattingSourceId, rows,
    viewOptionsState);
}
