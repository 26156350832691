import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {UserService} from '@process-manager/pm-library';
import {PopupDialogComponent, PopupDialogData} from '../../../shared/components/popup-dialog/popup-dialog.component';
import {Tree} from '../../../shared/model/tree';
import {TreeElement} from '../../../shared/model/treeelement';
import {ContactService} from '../../../shared/services/contact.service';
import {AppState, getTree} from '../../../state-management/reducers';

@Component({
  selector: 'pm-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.css']
})
export class ContactDialogComponent implements OnInit, OnDestroy {
  tree: Tree;
  tree$ = this.store$.select(getTree);
  treeSubscription = this.tree$.subscribe(tree => this.tree = tree);

  pending = false;

  constructor(public dialogRef: MatDialogRef<ContactDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public context: TreeElement, private store$: Store<AppState>,
              private contactService: ContactService, private dialog: MatDialog, private userService: UserService) {
  }

  get email(): string {
    return this.userService.user.email;
  }

  get fullName(): string {
    const user = this.userService.user;
    return user && [user.firstName, user.lastName].join(' ').trim() || '';
  }

  onSubmit(form: NgForm): void {
    this.pending = true;
    this.contactService.contactOwner(this.userService.domain, this.tree, this.context, form.value.sender_name,
      form.value.sender_email, form.value.email_content).subscribe(() => this.dialogRef.close(),
      () => {
        this.pending = false;
        this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
          data: {
            title: 'dialog.contact.send.error.title',
            body: 'dialog.contact.send.error'
          }
        });
      });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.treeSubscription.unsubscribe();
  }
}
