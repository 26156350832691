import {Action} from '@ngrx/store';
import * as Immutable from 'immutable';
import {Favorite} from '../../shared/services/favorite.service';

export const LOAD_FAVORITES = '[Favorites] Load';
export const LOAD_FAVORITES_SUCCESS = '[Favorites] Load success';
export const LOAD_FAVORITES_ERROR = '[Favorites] Load error';
export const STORE_FAVORITES = '[Favorites] Store';
export const STORE_FAVORITES_SUCCESS = '[Favorites] Store success';
export const STORE_FAVORITES_ERROR = '[Favorites] Store error';
export const ADD_FAVORITE = '[Favorites] Add';
export const REMOVE_FAVORITE = '[Favorites] Remove';

export class LoadFavorites implements Action {
  readonly type = LOAD_FAVORITES;

  constructor(public payload?: any) {
  }
}

export class LoadFavoritesSuccess implements Action {
  readonly type = LOAD_FAVORITES_SUCCESS;

  constructor(public readonly payload: Immutable.Map<string, Favorite>) {
  }
}

export class LoadFavoritesError implements Action {
  readonly type = LOAD_FAVORITES_ERROR;

  constructor(public payload: Error) {
  }
}

export class StoreFavorites implements Action {
  readonly type = STORE_FAVORITES;

  constructor(public payload?: any) {
  }
}

export class StoreFavoritesSuccess implements Action {
  readonly type = STORE_FAVORITES_SUCCESS;

  constructor(public payload?: any) {
  }
}

export class StoreFavoritesError implements Action {
  readonly type = STORE_FAVORITES_ERROR;

  constructor(public payload: Error) {
  }
}

export class AddFavorite implements Action {
  readonly type = ADD_FAVORITE;

  constructor(public payload: { favorite: Favorite }) {
  }
}

export class RemoveFavorite implements Action {
  readonly type = REMOVE_FAVORITE;

  constructor(public payload: string) {
  }
}

export type FavoriteActions = LoadFavorites | LoadFavoritesError | LoadFavoritesSuccess | StoreFavorites
  | StoreFavoritesError | StoreFavoritesSuccess | AddFavorite | RemoveFavorite;
