import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {UserService} from '@process-manager/pm-library';
import {take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AppState, getTreeSource} from '../../state-management/reducers';

@Injectable({
  providedIn: 'root'
})
export class VisitService {

  constructor(private userService: UserService, private http: HttpClient, private store$: Store<AppState>) {
  }

  public registerVisit(id: string | number, alsoParents: boolean = false): void {
    this.store$.select(getTreeSource).pipe(take(1)).subscribe(source => {
      if(source === 'customer') {
        const url = environment.api + this.userService.domain + '/visit/' + id;
        const httpParams = new HttpParams().set('alsoParents', alsoParents ? 'true' : 'false');

        this.http.post(url, {}, {params: httpParams}).subscribe({
          error: e => console.error('Error registering visit', e)
        });
      }
    })
  }
}
