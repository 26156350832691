import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './main';
import {AuthGuard} from './shared/guards/auth.guard';
import {ConfirmNavigationGuard} from './shared/guards/confirm-navigation.guard';
import {LanguageMappingGuard} from './shared/guards/language-mapping.guard';

const appRoutes: Routes = [
  {
    path: 'domains/:domain',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [ConfirmNavigationGuard],
        component: MainComponent,
      },
      {
        path: 'trees/:nodeid',
        canActivate: [ConfirmNavigationGuard],
        component: MainComponent,
      },
      {
        path: 'templates/:templateName/:templateVersion',
        pathMatch: 'full',

        canActivate: [ ConfirmNavigationGuard, LanguageMappingGuard],
        component: MainComponent,
      },
      {
        path: 'templates/:templateName/:templateVersion/:nodeid',
        canActivate: [ ConfirmNavigationGuard, LanguageMappingGuard],
        component: MainComponent,
      },
      {
        path: '**',
        canActivate: [ConfirmNavigationGuard],
        component: MainComponent,
      },
    ]
  }, {
    path: 'html',
    pathMatch: 'full',
    redirectTo: 'domains/'
  },
  {
    path: 'html/authentication',
    pathMatch: 'full',
    redirectTo: 'domains/'
  },  {
    path: 'html/reset_password',
    pathMatch: 'full',
    redirectTo: 'reset_password/'
  },
  {
    path: 'html/domains',
    pathMatch: 'full',
    redirectTo: 'domains/'
  }, {
    path: 'html/domains/:domain',
    pathMatch: 'full',
    redirectTo: 'domains/:domain'
  }, {
    path: 'html/domains/:domain/trees/:nodeid',
    redirectTo: 'domains/:domain/trees/:nodeid'
  }, {
    path: '',
    pathMatch: 'full',
    redirectTo: 'domains/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
