<mat-card appearance="outlined" (click)="cardClick$.next()">
  <mat-card-header>
    <mat-card-title *ngIf="hasTitle">
      {{title}}
    </mat-card-title>
    <mat-card-subtitle>
      {{embedDetails?.provider_name}}
    </mat-card-subtitle>
  </mat-card-header>
  <img mat-card-image *ngIf="hasImageSrc" [src]="imageSrc" (load)="imageLoaded($event)">
  <mat-card-content *ngIf="hasContent" [innerHtml]="htmlContent | unsafeHtml" [ngClass]="embedType"></mat-card-content>
  <mat-card-actions *ngIf="node?.writeable" (click)="stopPropagation($event)" align="end">
    <button mat-raised-button class="pm-remove-link" color="warn" *ngIf="!isInheritedLink && (isAuthorOrAdmin$ | async)" (click)="onRemove($event)">{{'button.remove' | translate}}
    </button>
    <span class="pull-right"></span>
    <pm-color-picker-input [color]="linkColor" (colorChanged)="colorChanged($event)"></pm-color-picker-input>
  </mat-card-actions>
</mat-card>
