import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AppConfig} from '../../app.config';

export interface Favorite {
  id: string;
  label: string;
}

@Injectable()
export class FavoriteService {
  constructor(private http: HttpClient, private appConfig: AppConfig) {
  }

  /**
   * Gets the favorites of a user.
   * @param user The name of the user
   * @param domain The domain to load the favorites from
   * @returns {Observable<Map<number, string>>} The loaded favorites
   */
  public getFavorites(user: string, domain: string): Observable<Favorite[]> {
    const url = environment.api + domain + '/favorites/' + user;
    return this.http.get<[[number, string]]>(url).pipe(
      map((response: any[]) => response.map((i) => {
        return {
          id: String(i.id),
          label: i.label
        };
      })));
  }

  public storeFavorites(user: string, domain: string, favorites: string[]): Observable<any> {
    const url = environment.api + domain + '/favorites/' + user;
    const options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

    return this.http.put(url, JSON.stringify(favorites), options);
  }
}
