import * as Immutable from 'immutable';
import {Label} from '../model/label';
import {Link} from '../model/link';
import {isProcess, Process} from '../model/process';
import {Tree} from '../model/tree';
import {TreeElement} from '../model/treeelement';

export class TreeMerger {
  constructor(private oldTree: Tree, private newTree: Tree) {
  }

  public merge(on: string): Tree {
    const nodes: Immutable.Map<string, TreeElement> = Immutable.Map(this.oldTree.nodes);
    const links: Immutable.Map<string, Link> = Immutable.Map(this.oldTree.links);
    const labels: Immutable.Map<number, Label> = Immutable.Map(this.oldTree.labels);
    const oldNode = nodes.get(on) as Process;

    this.newTree.nodes.forEach(element => nodes.set(element.id, element));

    return {
      source: this.oldTree.source,
      path: this.oldTree.path,
      localPath: [],
      root: this.oldTree.root,
      links: links.merge(this.newTree.links),
      labels: labels.merge(this.newTree.labels),
      nodes: nodes.mergeWith((oldVal, newVal) => {
        if (oldVal) {
          if (oldVal.id === on && isProcess(newVal)) {
            return {
              ...oldNode,
              label: newVal.label,
              info: newVal.info,
              extendedInfo: newVal.extendedInfo,
              owner: newVal.owner,
              style: newVal.style,
              type: newVal.type,
              childSize: newVal.childSize,
              nodes: oldNode.hasUnloadedChildren ? [...(newVal.nodes || []), ...(oldNode.nodes || [])] : newVal.nodes,
              bullets: newVal.bullets,
              links: newVal.links,
              hasUnloadedChildren: false
            } as Process;
          } else {
            return {
              ...newVal,
              parentId: oldVal.parentId,
              hasUnloadedChildren: false
            };
          }
        }
        return newVal;
      }, this.newTree.nodes)
    };
  }
}
