<h1 mat-dialog-title>{{'dialog.subscription.title' | translate}}</h1>
<form class="mat-typography" mat-dialog-content [formGroup]="form">
  <section>
    <span *ngIf="hasExistingSchedule"><em>{{'dialog.subscription.existing_schedule' | translate}}</em></span>
    <a class="pm-smaller pm-right" [href]="portalUrl" target="_blank">{{'dialog.subscription.manage' | translate}}</a>
  </section>
  <section>
    <span>{{'dialog.subscription.period.label' | translate}}</span>
    <mat-radio-group class="pm-horizontal-group" formControlName="billingCycle">
      <mat-radio-button value="year">{{'dialog.subscription.period.label.year' | translate}}</mat-radio-button>
      <mat-radio-button value="month">{{'dialog.subscription.period.label.month' | translate}}</mat-radio-button>
    </mat-radio-group>
  </section>

  <section>
  <mat-form-field>
    <mat-label>{{'dialog.subscription.employees.label' | translate}}</mat-label>
    <mat-select formControlName="employeeCount">
      <mat-option *ngFor="let employeeTier of employeePriceTiers" [value]="employeeTier.max || MORE_COUNT">{{getTierLabel(employeeTier)}}<span class="pm-right">{{getPrice(employeeTier)}}</span></mat-option>
      <mat-select-trigger>{{getTierLabel(employeeCountControl.value)}}<span class="pm-right">{{getPrice(employeeCountControl.value)}}</span></mat-select-trigger>
    </mat-select>
    <mat-error *ngIf="employeeCountControl.errors?.max">{{'dialog.subscription.enterprise-numbers' | translate}}</mat-error>
  </mat-form-field>
  </section>

  <section class="pm-horizontal-group" formGroupName="licensedTemplates">
    <span>{{'dialog.subscription.templates.label' | translate}}</span>
    <div class="pm-horizontal-group">
      <div class="pm-checkbox-wrapper" *ngFor="let template of templates">
        <mat-checkbox class="pm-template-checkbox" aria-label="{{template.label}}"  [formControlName]="template.templateName"></mat-checkbox>
        <div>{{template.label}}</div>
        <div class="pm-push-right"></div>
        <div>{{getTemplatePrice(template)}}</div>
      </div>
    </div>
    <div class="missing-templates mat-error"
         *ngIf="form.dirty && form.controls['licensedTemplates'].errors && !!form.controls['licensedTemplates'].errors.minimumCheckboxes">
      {{'dialog.subscription.templates.missing' | translate}}
    </div>
  </section>

  <section class="mat-body">
    <span>{{'dialog.subscription.changes.label' | translate}}</span>
    <div class="price">
      <div>{{'dialog.subscription.changes.old_price' | translate:subscriptionChanges}}<sup *ngIf="!!invoiceUrl"><a target="_blank" [href]="invoiceUrl">({{'dialog.subscription.invoice' | translate:subscriptionChanges}})</a></sup></div>
      <ng-container [ngSwitch]="subscriptionChanged">
        <ng-container *ngSwitchCase="true">
          <div>{{'dialog.subscription.changes.new_price' | translate:subscriptionChanges}}</div>
          <div>{{'dialog.subscription.changes.billed_at_end' | translate}}</div>
        </ng-container>
        <div *ngSwitchCase="false">{{'dialog.subscription.changes.unchanged' | translate}}</div>
      </ng-container>
    </div>
  </section>
</form>
<div mat-dialog-actions>
  <button mat-button type="button" (click)="submit()" [disabled]="!subscriptionChanged || form.invalid">{{'button.change_subscription' |
    translate}}
  </button>
  <button mat-button type="button" mat-dialog-close="">{{'button.cancel' | translate}}</button>
</div>
