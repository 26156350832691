import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {HistoryEventBlock} from '../../../shared/model/history-events';
import {HistoryLoaderService} from '../../../shared/services/history-loader.service';
import {AllHistoryDialogComponent} from './all-history-dialog/all-history-dialog.component';

@Component({
  selector: 'pm-history-view',
  templateUrl: './history-view-popup.component.html',
  styleUrls: ['./history-view-popup.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryViewPopupComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  isOpen = false;
  loaded = false;

  historyEventBlock: HistoryEventBlock[] = [];
  private eventSubscription: Subscription;


  constructor(private historyLoaderService: HistoryLoaderService, private zone: NgZone,
    private cd: ChangeDetectorRef, private dialog: MatDialog) {
  }

  identity(index: number, item: HistoryEventBlock) {
    return item.commitId;
  }

  ngOnInit(): void {
    this.eventSubscription = this.historyLoaderService.eventBlocks.subscribe(data => {
      this.loaded = data !== null;
      this.historyEventBlock = data;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.eventSubscription?.unsubscribe();
  }

  expandAll() {
    this.accordion.openAll();
    return undefined;
  }

  closeAll() {
    this.accordion.closeAll();
    return undefined;
  }

  showAll() {
    this.dialog.open(AllHistoryDialogComponent);
    return undefined;
  }
}
