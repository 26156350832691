<div mat-dialog-title>{{'dialog.about.title' | translate}}</div>
<mat-tab-group mat-dialog-content>
  <mat-tab label="{{'dialog.about.about-tab' | translate}}">
    <div class="pm-about-content" [innerHTML]="'dialog.about.content' | translate"></div>
  </mat-tab>
  <mat-tab label="{{'dialog.about.license-tab' | translate}}">
    <table mat-table class="mat-elevation-z4" [dataSource]="dataSource">
      <ng-container matColumnDef="project">
        <th mat-header-cell class="proman-search-header" *matHeaderCellDef> {{'dialog.about.licenses.columns.project' |
          translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.project}}
        </td>
      </ng-container>

      <ng-container matColumnDef="licenses">
        <th mat-header-cell class="proman-search-header" *matHeaderCellDef> {{'dialog.about.licenses.columns.licenses' |
          translate}}
        </th>
        <td mat-cell *matCellDef="let element">{{element.licenses}}
        </td>
      </ng-container>

      <ng-container matColumnDef="repository">
        <th mat-header-cell class="proman-search-header" *matHeaderCellDef> {{'dialog.about.licenses.columns.repository' |
          translate}}
        </th>
        <td mat-cell *matCellDef="let element" [title]="element.repository"><a target="_blank" [href]="element.repository">{{element.repository}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="publisher">
        <th mat-header-cell class="proman-search-header" *matHeaderCellDef> {{'dialog.about.licenses.columns.publisher' |
          translate}}
        </th>
        <td mat-cell *matCellDef="let element" [title]="element.publisher"> {{element.publisher}}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell class="proman-search-header" *matHeaderCellDef> {{'dialog.about.licenses.columns.email' |
          translate}}
        </th>
        <td mat-cell *matCellDef="let element" [title]="element.email"> {{element.email}}
        </td>
      </ng-container>

      <ng-container matColumnDef="url">
        <th mat-header-cell class="proman-search-header" *matHeaderCellDef> {{'dialog.about.licenses.columns.url' |
          translate}}
        </th>
        <td mat-cell *matCellDef="let element" [title]="element.url"> {{element.url}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </mat-tab>
</mat-tab-group>

