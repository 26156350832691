<h1 mat-dialog-title>{{data.title | translate}}</h1>
<div mat-dialog-content>
  <p class="pm-dialog-query">{{data.body | translate:data.bodyTranslationArguments}}</p>
  <p class="pm-dialog-error" *ngIf="error !== null">{{error}}</p>
</div>
<div mat-dialog-actions>
  <button mat-button type="submit" [disabled]="working" (click)="closeDialog('continue')"><span
    class="inliner"><mat-spinner diameter="16" *ngIf="working"></mat-spinner>{{(data.continueButton || 'button.save') | translate}}</span></button>
  <button *ngIf="!data.hideSaveButton" mat-button (click)="closeDialog('drop')">{{(data.dropButton || 'button.dont-save') | translate}}</button>
  <button mat-button (click)="closeDialog('cancel')">{{(data.cancelButton || 'button.cancel') | translate}}</button>
</div>
