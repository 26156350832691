<h2 mat-dialog-title>{{titleKey | translate}}</h2>

<mat-dialog-content class="mat-typography">
  <p *ngIf="data.type !== 'edit'">{{'dialog.template-mapping.missing.details'|translate}}</p>

  <ng-container>
    <table mat-table [dataSource]="dataSource">
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: columns;"></tr>

      <ng-container matColumnDef="template">
        <th mat-header-cell *matHeaderCellDef>{{'dialog.copy-template.language-map.column-template' | translate}}</th>
        <td class="pm-input-td" mat-cell *matCellDef="let templateLanguage;">{{templateLanguage.name}}</td>
      </ng-container>
      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef>{{'dialog.copy-template.language-map.column-site' | translate}}</th>
        <td mat-cell *matCellDef="let templateLanguage; let i = index;">
          <mat-form-field floatLabel="never">
            <mat-select placeholder="{{'dialog.copy-template.language-map.select-language' | translate}}"
                        [(value)]="templateLanguage.siteLanguage">
              <mat-option *ngFor="let siteLanguage of siteLanguages"
                          [value]="siteLanguage.id">{{siteLanguage.name}}</mat-option>
              <mat-option [value]="null">{{'dialog.copy-template.language-map.do-not-map' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
    </table>
    <mat-hint>{{'dialog.copy-template.map-languages'|translate}}</mat-hint>

    <div class="mapping-errors">
    <mat-error
      *ngIf="languageValidator && languageValidator['default-language-not-mapped'] || false">{{'dialog.copy-template.validation.default-language' | translate}}</mat-error>
    <mat-error
      *ngIf="(languageValidator && languageValidator['site-language-multi-mapped'] || 0) > 0">{{'dialog.copy-template.validation.multi-mapped' | translate}}</mat-error>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button type="submit" [disabled]="!mappingRemembered && languageValidator !== null" (click)="submit()"><span
      class="inliner"><mat-spinner diameter="16" *ngIf="saving"></mat-spinner>{{'button.ok' | translate}}</span></button>
    <button mat-button type="button" [mat-dialog-close]="null">{{'button.cancel' | translate}}</button>
    <button mat-button type="button" *ngIf="mappingRemembered && data.type === 'edit'" (click)="deleteMappings()">{{'button.delete.template.mappings' | translate }}</button>
</mat-dialog-actions>
