import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NodeLinksChanged} from '../../../../../../shared/model/history-events';

@Component({
  selector: 'pm-node-links-changed-renderer',
  templateUrl: './node-links-changed-renderer.component.html',
  styleUrls: ['./node-links-changed-renderer.component.css']
})
export class NodeLinksChangedRendererComponent implements OnInit {
  @Input()
  event: NodeLinksChanged;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {

  }

  linkTranslate() {
    const details = this.event?.details;
    return {
      'resourceName': details?.resourceName || '',
      'resourceType': this.translate.instant('history.event.resource-type.' + (details?.resourceType || '')),
      'resourceAction': this.translate.instant('history.event.' + (this.event?.eventType || '')),
    }
  }
}
