import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

import {Link} from '../../../shared/model/link';
import {RenderedElement} from '../../../shared/model/rendered/rendered-element';
import {RenderedProcess} from '../../../shared/model/rendered/rendered-process';
import {PositioningService} from '../../../shared/services/positioning.service';
import {internalDocumentTrumbowygOptions, JQueryTrumbowyg} from '../../../shared/trumbowyg.directive';
import {ChangeResourceColor, RemoveResource, UpdateResource} from '../../../state-management/actions/tree.actions';
import {AppState, getTreeDefaultLanguage} from '../../../state-management/reducers';
import {getLinkColor, PromanLinkService} from '../shared/proman-link.service';
import {EmbeddedDialogComponent} from './embedded-dialog/embedded-dialog.component';

@Component({
  selector: 'pm-link-menu',
  templateUrl: './link-menu.component.html',
  styleUrls: ['./link-menu.component.css'],
  animations: [trigger('showTrigger', [ // @formatter:off
    transition(':enter', [
      query('mat-card, pm-embedcard', [
        style({
          opacity: 0
        }),
        stagger(50, [
          animate('200ms', style({
            opacity: 1
          }))
        ])
      ], {optional: true}),
    ]),
    transition(':leave', [
      query('mat-card, pm-embedcard', [
        stagger(50, [
          animate('200ms', style({
            opacity: 0
          }))
        ])
      ], {optional: true}),
    ])
  ])],
  // @formatter:on
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() node: RenderedElement;
  @Input() open = false;
  @Input() isAuthor = false;
  @Output() linkClicked = new EventEmitter<Link>();

  @ViewChild('cardHolder') cardHolder: ElementRef;

  isInfoEditing = false;

  tempInfoBody: string;
  tempInfoTitle: string;


  isDefaultLanguage = false;
  languageSubscription = this.store$.select(getTreeDefaultLanguage).subscribe(isDefault => this.isDefaultLanguage = isDefault);

  readonly trumbowygOptions: JQueryTrumbowyg.Options = {...internalDocumentTrumbowygOptions};

  constructor(private store$: Store<AppState>, private dialog: MatDialog, private promanLinkService: PromanLinkService,
    private positioning: PositioningService, private el: ElementRef, private translationService: TranslateService, private snackBar: MatSnackBar) {
  }

  get buttonEditLabel(): Observable<string> {
    if (this.isDefaultLanguage) {
      return this.translationService.get('button.edit');
    } else {
      return this.translationService.get('menu.options.main.translate');
    }
  }

  get buttonRemoveLabel(): Observable<string> {
    if (this.isDefaultLanguage) {
      return this.translationService.get('button.remove');
    } else {
      return this.translationService.get('button.remove.translation');
    }
  }

  get isAutoTranslated(): boolean {
    return this.node.autoTranslated.info;
  }

  get showRemoveButton() {
    return this.isAuthor && (this.isDefaultLanguage || (!this.node.defaults.info && !this.isAutoTranslated));
  }

  get extendedInfo(): string {
    return this.node.extendedInfo || '';
  }

  get isInfoExtended(): boolean {
    return !!this.node && !!this.node.extendedInfo;
  }

  get filteredLinks(): Link[] {
    return this.node?.links?.filter(link => link.linkType !== 'watermark' && link.linkType !== 'logo');
  }

  get linkColor() {
    return getLinkColor({
      color: this.node.infoColor,
      linkType: 'info'
    }).hex();
  }

  private updateTempInfoBody() {
    this.tempInfoBody = this.node && this.node.extendedInfo || '';
  }

  private updateTempInfoTitle() {
    this.tempInfoTitle = this.node && this.node.info || '';
  }

  ngOnInit() {
    this.updateTempInfoTitle();
    this.updateTempInfoBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    const nc = changes['node'];
    if (!!nc) {
      const previous: RenderedProcess = nc.previousValue;
      const current: RenderedProcess = nc.currentValue;
      if(nc.firstChange || previous.info !== current.info) {
        this.updateTempInfoTitle();
      }

      if(nc.firstChange || previous.extendedInfo !== current.extendedInfo) {
        this.updateTempInfoBody();
      }
    }

    const openChange = changes['open'];
    if (!!openChange && !openChange.firstChange && !openChange.currentValue) {
      this.isInfoEditing = false;
      if ((this.node.info !== this.tempInfoTitle || this.node.extendedInfo !== this.tempInfoBody) && this.hasInfo()) {
        this.store$.dispatch(new UpdateResource({
          elementId: this.node.id,
          resourceType: 'info',
          data: {
            title: this.tempInfoTitle,
            body: this.tempInfoBody
          }
        }));
      }
    }
  }

  ngOnDestroy() {
    this.languageSubscription.unsubscribe()
  }

  onBodyChange($event: string) {
    this.tempInfoBody = $event;
  }

  onRemoveInfo($event: Event) {
    if ($event.stopImmediatePropagation) {
      $event.stopImmediatePropagation();
    }
    if ($event.stopPropagation) {
      $event.stopPropagation()
    }

    this.store$.dispatch(new RemoveResource({
      elementId: this.node.id,
      resourceType: 'info'
    }));

    if (!this.isDefaultLanguage) {
      this.snackBar.open(this.translationService.instant('warning.translation-removed'), undefined, {
        politeness: "polite"
      })
    }
  }

  stopPropagation($event: Event) {
    if ($event.stopImmediatePropagation) {
      $event.stopImmediatePropagation();
    }
    if ($event.stopPropagation) {
      $event.stopPropagation()
    }
  }

  onEditInfo($event: Event) {
    this.stopPropagation($event);
    this.isInfoEditing = true;
  }

  onLinkClicked(link: Link) {
    this.linkClicked.emit(link);
  }

  onCardClicked() {
    if (this.isInfoExtended && !this.isInfoEditing) {
      this.dialog.open(EmbeddedDialogComponent, {
        data: {
          title: this.node.info,
          html: this.node.extendedInfo,
          type: 'info'
        }
      });
    }
  }

  onRemoveLink(link: Link) {
    this.store$.dispatch(new RemoveResource({
      elementId: this.node.id,
      resourceType: link.linkType === 'http' ? 'link' : 'upload',
      resourceId: link.id
    }));
  }

  hasInfo(): boolean {
    return !!this.node && (!!this.node.info || !!this.node.extendedInfo);
  }

  colorChanged(newColor: string) {
    this.store$.dispatch(new ChangeResourceColor({
      elementId: this.node.id,
      color: newColor
    }));
  }

  // noinspection JSUnusedLocalSymbols
  getLinkId(index: number, link: Link): string {
    return link ? link.id : '';
  }

  dontPropagate($event: Event) {
    $event.stopPropagation();
  }

  beforeAnimation() {
    if (this.open && this.cardHolder) {
      this.positioning.doCheck(this.cardHolder.nativeElement, this.el.nativeElement);
    }
  }

  afterAnimation() {
    if (!this.open) {
      this.positioning.clearPositioning(this.el.nativeElement);
    }
  }

  embeddedInfoLinkClicked(href: string) {
    this.promanLinkService.openLink(href);
  }

  updateInfoTitle = ($event: Event) => {
    this.tempInfoTitle = ($event.target as HTMLInputElement).value;
  }
}
