import {SiteSettings} from '@process-manager/pm-library';
import {User} from '@process-manager/pm-library/lib/model/user';
import {Dexie, Table} from 'dexie';

import {Label} from '../model/label';
import {Link} from '../model/link';
import {TreeElement} from '../model/treeelement';

export interface DbSite {
  name: string;
  prohibitedNodes: string[];
  settings: SiteSettings;
  user: User;
}

export interface DbBase {
  site: string;
}

export interface DbLink extends Link, DbBase {
  data?: Blob;
  available: boolean;
}

export interface DbElement extends TreeElement, DbBase {
  available: boolean;
}

export interface DbLabel extends Label, DbBase {
}

export class AppDb extends Dexie {
  sites!: Table<DbSite, string>;
  nodes!: Table<DbElement, string>;
  labels!: Table<DbLabel, string>;
  links!: Table<DbLink, string>;

  constructor() {
    super('ProcessManagerOfflineStorage');
    this.version(4).stores({
      sites: 'name',
      nodes: '[site+id], [site+parentId]',
      labels: '[site+id]',
      links: '[site+id]',
    }).upgrade(trans => {
      trans.table('nodes').clear();
      trans.table('labels').clear();
      trans.table('links').clear();
    })
  }
}

export const db = new AppDb();
