<h2 mat-dialog-title>{{'dialog.template-admin.title' | translate}}</h2>

<div mat-dialog-content class="mat-typography">
    <mat-form-field>
        <mat-label>{{'dialog.template-admin.template' | translate}}</mat-label>
        <mat-select name="selected-language" [(ngModel)]="selectedTemplate" (selectionChange)="templateSelectionChange($event)">
            <mat-option [value]="NEW_TEMPLATE_SELECT">{{'dialog.template-admin.new-template' | translate}}</mat-option>
            <mat-optgroup label="{{'dialog.template-admin.existing-templates' | translate}}">
                <mat-option *ngFor="let templateName of templateNames" [value]="templateName">{{templateName}}</mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>

    <form [formGroup]="templateForm">
        <mat-form-field class="block-field">
            <input matInput id="pm_language-name"
                   placeholder="{{'dialog.template-admin.name' | translate}}" formControlName="name">
            <mat-error *ngIf="name.errors?.notUnique">{{'form.errors.label.name.not-unique' | translate}}</mat-error>
            <mat-error
                    *ngIf="name.errors?.required || name.errors?.minLength">{{'form.errors.label.name.required' | translate}}</mat-error>
            <mat-error
                    *ngIf="name.errors?.maxLength">{{'form.errors.label.name.maxLength' | translate:name.errors?.maxLength}}</mat-error>
        </mat-form-field>
        <ng-container formGroupName="descriptions">
            <mat-form-field>
                <mat-label>{{'dialog.template.descriptions.da' | translate}}</mat-label>
                <textarea matInput rows="5" formControlName="da"></textarea>
                <mat-error
                  *ngIf="descriptionDa.errors?.required || name.errors?.minLength">{{'form.errors.template.description.required' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'dialog.template.descriptions.en' | translate}}</mat-label>
                <textarea matInput rows="5" formControlName="en"></textarea>
                <mat-error
                  *ngIf="descriptionEn.errors?.required || name.errors?.minLength">{{'form.errors.template.description.required' | translate}}</mat-error>
            </mat-form-field>
        </ng-container>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-button type="button" (click)="submit()" [disabled]="templateForm.invalid">{{'button.ok' |
        translate}}
    </button>
    <button mat-button type="button" mat-dialog-close>{{'button.cancel' | translate}}</button>
    <button mat-button type="button" (click)="delete()" [disabled]="isNew" color="warn">
        {{'button.delete' | translate}}
    </button>
</div>
