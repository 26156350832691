import {
  AuthenticationActions, DOMAIN_CHOSEN, LOGIN, LOGIN_FAILURE, LOGIN_REDIRECT, LOGIN_SUCCESS, LOGOUT
} from '../actions/auth.actions';

export interface LoginPageState {
  pending: boolean;
  returnUrl: string | null;
  domain: string | null;
  local: boolean;
  error: Error | null;
  errorDetails: string | null;
  temporaryPassword: string | null;
}

export const initialState: LoginPageState = {
  pending: false,
  returnUrl: null,
  domain: null,
  local: false,
  error: null,
  errorDetails: null,
  temporaryPassword: null
};

export function loginPageReducer(state = initialState, action: AuthenticationActions): LoginPageState {
  switch (action.type) {
    case DOMAIN_CHOSEN:
      return {
        ...state,
        domain: action.payload.domain
      };
    case LOGIN:
      return {
        ...state,
        error: null,
        pending: true,
        temporaryPassword: action.payload.auth.password
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        pending: false,
        temporaryPassword: action.payload.passwordIsTemporary ? state.temporaryPassword : null
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        errorDetails: action.payload.errorDetails
      };

    case LOGIN_REDIRECT:
      return {
        ...state,
        returnUrl: action.payload.returnUrl,
        domain: action.payload.domain,
        local: action.payload.local
      };

    case LOGOUT:
      return {
        ...initialState,
        domain: state.domain
      };
    default:
      return state;
  }
}

export const getPending = (state: LoginPageState) => state.pending;
export const getError = (state: LoginPageState): [Error, string] => [state.error, decodeURIComponent(state.errorDetails)];
export const getReturnUrl = (state: LoginPageState) => state.returnUrl;
export const getDomain = (state: LoginPageState) => state.domain;
export const getLocal = (state: LoginPageState) => state.local;
export const getTemporaryPassword = (state: LoginPageState) => state.temporaryPassword;
