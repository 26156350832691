import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {UserService} from '@process-manager/pm-library';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {AppConfig} from '../../../app.config';
import {NodeType} from '../../../shared/model/treeelement';
import {NavigationService} from '../../../shared/services/navigation.service';
import {ActivateElement} from '../../../state-management/actions/tree.actions';
import {AppState, getAuthUserIsPublic} from '../../../state-management/reducers';

interface SearchElement {
  label: string;
  id: number;
  type: NodeType;
  pos: number;
  path: string;
  parent: number;
  parentPage: number;
  extraData: {
    url: NodeType;
    linkID: number;
  }
}

@Component({
  selector: 'pm-link-checker',
  templateUrl: './link-check.component.html',
  styleUrls: ['./link-check.component.css']
})
export class LinkCheckComponent implements OnInit, OnDestroy {
  folded = false;
  searchResultsSubscription: Subscription;
  loading = true;
  displayedColumns = ['label', 'path'];
  dataSource = new MatTableDataSource<SearchElement>();
  activeSearchElement: any = null;

  isPublic: boolean;
  isPublicSubscription = this.store$.select(getAuthUserIsPublic).subscribe(isPublic => this.isPublic = isPublic);

  constructor(private userService: UserService, private appConfig: AppConfig, private http: HttpClient,
              private navigationService: NavigationService, private router: Router, private store$: Store<AppState>) {
  }

  ngOnInit() {
    const url = environment.api + this.userService.domain + '/links/check';
    this.searchResultsSubscription =
      this.http.get<SearchElement[]>(url).pipe(
        finalize(() => this.loading = false)).subscribe(elements => this.dataSource.data = elements);
  }

  ngOnDestroy() {
    this.searchResultsSubscription.unsubscribe();
    this.isPublicSubscription.unsubscribe();
  }

  navigateToNode(searchElement: SearchElement) {
    this.activeSearchElement = searchElement.id;
    this.appConfig.highlightNext = true;
    if(['page','mainpage'].includes(searchElement.type)) {
      this.navigationService.navigateToNode(searchElement.parent, this.isPublic).then(() => this.store$.dispatch(new ActivateElement({ id: searchElement.id.toString(10), activationType: 'highlight' })))

    } else {
      this.navigationService.navigateToNode(searchElement.id, this.isPublic);
    }
  }
}
