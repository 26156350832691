import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {UserService, XsrfTokenReader} from '@process-manager/pm-library';

// @ts-ignore
import * as supportedBrowsers from 'assets/supportedBrowsers';
import interact from 'interactjs';
import {AppModule} from './app';
import {environment} from './environments/environment';

declare let jQuery: any;

const hasAllowUnsupported = document.cookie.split(';')
  .filter((item) => item.trim().indexOf('pm_allow_unsupported=') === 0).length > 0;

if (!hasAllowUnsupported && !supportedBrowsers.test(navigator.userAgent)) {
  document.getElementById('unsupported-warning').style.setProperty('display', 'block');
  document.getElementById('load-banner').style.setProperty('display', 'none');
} else {
  if (environment.production) {
    enableProdMode();
  }

  // Still can't get jQuery to use typings properly.
  (function ($) {
    const observer = new MutationObserver(function () {
      const modal = $('.trumbowyg-modal');
      if (modal.length > 0) {
        const top = modal.position().top;
        const clientHeight = document.body.clientHeight;
        const height = modal.height();

        if ((top + height) > clientHeight) {
          modal.css('top', (clientHeight - height) + 'px');
        }
      }
    });
    observer.observe(document, {attributes: false, childList: true, characterData: false, subtree: true});

    // Use our own icons (or, rather, Google Material Icons).
    $.trumbowyg.svgPath = 'assets/icons.svg';
    $.extend(true, $.trumbowyg, {
      langs: {
        // jshint camelcase:false
        it: {
          foreColor: 'Colore testo',
          backColor: 'Colore sfondo',
          italic: 'Corsivo'
        },
        da: {
          foreColor: 'Tekstfarve',
          backColor: 'Baggrundsfarve',
          formatting: 'Formatering',
          removeformat:"Ryd formatering"
        }
      }
    });
  })(jQuery);

// Fix tap and hold on Android.
  interact.pointerMoveTolerance(5);
  interact.dynamicDrop(true);

  platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
    (($: any) => {
      $.ajaxPrefilter((options: any) => {
        const userService = ref.injector.get(UserService);

        if(options.url.includes('{pmDomain}')) {
          options.url = options.url.replace('{pmDomain}', userService.domain);
        }

        const xsrfTokenReader = ref.injector.get(XsrfTokenReader);
        const token = xsrfTokenReader.getToken(userService.domain);

        if (options.method === 'GET' || options.method === 'HEAD' || !token) {
          return;
        }

        options.beforeSend = function (xhr: any) {
          xhr.setRequestHeader('X-XSRF-TOKEN', token);
        }
      })
    })(jQuery);
  });
}
