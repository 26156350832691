import {Component, ContentChildren, EventEmitter, HostListener, Input, Output, QueryList} from '@angular/core';

@Component({
  selector: 'zx-responsive-menu-item',
  template: ''
})
export class ResponsiveMenuItemComponent {
  @Input() tooltip: string;
  @Input() iconName: string;
  @Output() clicked = new EventEmitter<any>();
}

@Component({
  selector: 'zx-responsive-menu',
  templateUrl: './responsive-menu.component.html',
  styleUrls: ['./responsive-menu.component.css']
})
export class ResponsiveMenuComponent {
  @Input() subject: any;
  @ContentChildren(ResponsiveMenuItemComponent, {descendants: true}) items: QueryList<ResponsiveMenuItemComponent>;

  @HostListener('click', ['$event']) handleClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
