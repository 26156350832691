import {Component, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';

import {forkJoin} from 'rxjs';
import {environment} from '../../../environments/environment';
import {PopupDialogComponent, PopupDialogData} from '../../shared/components/popup-dialog/popup-dialog.component';
import {BillingCycle, Price, PriceTier, Subscription, SubscriptionService} from './subscription.service';

export function minimumCheckboxes(min = 1): ValidatorFn {
  return function (formGroup: FormGroup) {
    const actual = Object.values(formGroup.controls).filter(control => !!control.value).length;
    if (actual < min) {
      return {
        minimumCheckboxes: {
          min,
          checked: actual
        }
      }
    }

    return null;
  }
}

@Component({
  selector: 'pm-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.css']
})
export class SubscriptionDialogComponent implements OnInit {
  readonly MORE_COUNT: number = 100_000;
  form = this.fb.group({
    billingCycle: [null],
    employeeCount: [null, [Validators.max(this.MORE_COUNT - 1)]],
    licensedTemplates: this.fb.group({}, {validators: minimumCheckboxes()})
  });

  templates: Price[] = [];
  employeePriceTiers: PriceTier[] = [];

  public subscriptionChanges: {
    priceChange: string;
    oldPrice: string;
    oldPeriod: BillingCycle | string;
    currency: string;
    newPrice: string;
    newPeriod: any
  };

  public subscriptionChanged = false;
  private originalSubscription: Subscription;
  private prices: Price[] = [];

  constructor(private fb: UntypedFormBuilder, private subscriptionService: SubscriptionService,
    private translateService: TranslateService, private matDialog: MatDialog, private userService: UserService,
    @Optional() private dialogRef: MatDialogRef<SubscriptionDialogComponent>) {
  }

  get billingCycleControl(): FormControl {
    return this.form.controls['billingCycle'] as FormControl;
  }

  get employeeCountControl(): FormControl {
    return this.form.controls['employeeCount'] as FormControl;
  }

  get templateControl(): FormGroup {
    return this.form.controls['licensedTemplates'] as FormGroup;
  }

  get hasExistingSchedule(): boolean {
    return this.originalSubscription?.hasScheduledUpdate;
  }

  get invoiceUrl(): string {
    return this.originalSubscription?.latestInvoiceUrl || null;
  }

  get portalUrl(): string {
    return environment.api + this.userService.domain + '/stripe_portal';
  }

  asHumanPrice(price: number): string {
    return (price / 100.0).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  updateSubscriptionChanges() {
    const currentValue = this.getSubscriptionFromForm();

    const licensedTemplates = currentValue.licensedTemplates;

    const originalSubscription = this.originalSubscription;
    const origTemplates = [...originalSubscription.licensedTemplates].sort();
    this.subscriptionChanged = !(origTemplates.length === licensedTemplates.length &&
      origTemplates.every((v, i) => v === licensedTemplates[i]) && originalSubscription.employeeCount ===
      currentValue.employeeCount && originalSubscription.billingCycle === currentValue.billingCycle);

    const oldPrice = this.totalPrice(originalSubscription?.billingCycle || 'year',
      originalSubscription?.employeeCount || 0, originalSubscription?.licensedTemplates || []);
    const newPrice = this.totalPrice(currentValue.billingCycle || 'year', currentValue.employeeCount || 0,
      currentValue.licensedTemplates || []);
    this.subscriptionChanges = {
      oldPrice: this.asHumanPrice(oldPrice),
      oldPeriod: this.translateService.instant(
        'dialog.subscription.changes.period.' + originalSubscription?.billingCycle || 'year'),
      newPrice: this.asHumanPrice(newPrice),
      newPeriod: this.translateService.instant(
        'dialog.subscription.changes.period.' + currentValue.billingCycle || 'year'),
      priceChange: this.asHumanPrice(oldPrice - newPrice),
      currency: 'EUR'
    };

  }

  ngOnInit(): void {
    this.billingCycleControl.valueChanges.subscribe(this.updatePrices);

    this.form.valueChanges.subscribe(() => {
      this.updateSubscriptionChanges();
    });

    forkJoin({
      subscription: this.subscriptionService.getCurrentSubscription(),
      prices: this.subscriptionService.getCurrentPrices()
    }).subscribe(details => {
      const subscription = details.subscription;
      this.originalSubscription = subscription;
      this.prices = details.prices;

      this.billingCycleControl.setValue(subscription.billingCycle);
      this.employeeCountControl.setValue(subscription.employeeCount || this.MORE_COUNT);
      this.updatePrices();
    });
  }

  getTierLabel(priceTier: PriceTier | number) {
    priceTier = this.getPriceTier(priceTier);
    if (!priceTier) {
      return '';
    }

    if (!!priceTier.max) {
      return this.translateService.instant('dialog.subscription.employees.option.volume', priceTier);
    } else {
      return this.translateService.instant('dialog.subscription.employees.option.volume.no-max', priceTier);
    }
  }

  getPrice(priceTier: PriceTier | number) {
    priceTier = this.getPriceTier(priceTier);
    if (!priceTier) {
      return '';
    }
    return this.translateService.instant('dialog.subscription.employees.option.price', {
      currency: 'EUR',
      price: this.asHumanPrice(priceTier.flatPrice + (priceTier.unitPrice || 0) * priceTier.max)
    });
  }

  getTemplatePrice(template: Price) {
    return this.translateService.instant('dialog.subscription.employees.option.price', {
      currency: 'EUR',
      price: this.asHumanPrice(template.fixedPrice)
    });
  }

  submit() {
    this.subscriptionService.updateSubscription(this.getSubscriptionFromForm()).subscribe(() => {
      this.dialogRef?.close();
    }, (error) => {
      console.log('Could not update subscription:', error);
      this.matDialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
        data: {
          title: '',
          body: this.translateService.instant('dialog.subscription.error')
        }
      });
    });
  }

  private getSubscriptionFromForm(): Subscription {
    const currentValue = {
      ...this.form.value,
      id: this.originalSubscription.id
    };

    currentValue.licensedTemplates = Object.entries(currentValue.licensedTemplates || {}).filter(obj => obj[1] === true)
      .map(obj => obj[0]).sort();
    return currentValue;
  }

  private getPriceTier(employeeTier: PriceTier | number) {
    if (typeof employeeTier === 'number') {
      employeeTier = this.employeePriceTiers.find(tier => tier.max === employeeTier);
    }
    return employeeTier;
  }

  private employeePrice = (billingCycle: BillingCycle, employeeCount: number): number => {
    const price = this.prices.find(
      candPrice => candPrice.type === 'employee_count' && candPrice.interval === billingCycle);

    if (!!price) {
      const priceTier = price.tiers.find(value => (value.min <= employeeCount && employeeCount <= value.max) ||
        (!value.max && employeeCount === 100000));
      if (!!priceTier) {
        return priceTier.flatPrice + ((priceTier.unitPrice || 0) * employeeCount);
      }
    }

    return 0;
  };

  private templatePrice = (billingCycle: BillingCycle, templates: string[]) => {
    const prices = this.prices.filter(
      price => price.type === 'template' && price.interval === billingCycle && templates.includes(price.templateName));

    return prices.reduce((acc, price) => acc + price.fixedPrice, 0);
  };

  private totalPrice = (billingCycle, employeeCount: number, templates: string[]) => this.employeePrice(billingCycle,
    employeeCount) + this.templatePrice(billingCycle, templates);

  private updatePrices = () => {
    this.templates = this.prices.filter(
      price => price.type === 'template' && !!price.fixedPrice && price.interval === this.billingCycleControl.value);
    this.employeePriceTiers = this.prices.find(
      price => price.type === 'employee_count' && !!price.tiers && price.interval ===
        this.billingCycleControl.value)?.tiers || [];

    this.updateTemplateControls();
    this.updateSubscriptionChanges();
  };

  private updateTemplateControls() {
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    this.templates.sort((a, b) => collator.compare(a.label, b.label));
    const templateNames = this.templates.map(template => template.templateName);
    const controlNames = Object.keys(this.templateControl.controls);
    controlNames.filter(controlName => !templateNames.includes(controlName))
      .forEach(controlName => this.templateControl.removeControl(controlName));

    this.templates.filter(template => !controlNames.includes(template.templateName)).forEach(template => {
      this.templateControl.addControl(template.templateName,
        new FormControl(this.originalSubscription.licensedTemplates.includes(template.templateName)));
    });
  }
}
