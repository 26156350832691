import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserService} from '@process-manager/pm-library';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) {
  }

  private refreshingComplete$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isRefreshing = false;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const domain = request.url.slice(environment.api.length).split('/')[0];
    try {
      if ((request.url.startsWith(environment.api) && !request.url.includes('security/session'))) {
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
          if(err && err.status === 401) {
            if(!this.isRefreshing) {
              this.isRefreshing = true;
              this.refreshingComplete$.next(false);
              return this.userService.refresh(domain).pipe(
                switchMap(() => {
                  this.refreshingComplete$.next(true);
                  return next.handle(request);
                }),
                finalize(() => this.isRefreshing = false)
              );
            } else {
              return this.refreshingComplete$.pipe(
                filter(value => value),
                take(1),
                switchMap(() => {
                  return next.handle(request);
                }))
            }
          } else {
            return throwError(err);
          }
        }));
      }
    } catch (e) {
      console.warn('Could not refresh token');
    }

    return next.handle(request);
  }
}
