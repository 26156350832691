<div class="pm-user-image-wrapper" (click)="watermarkClick()">
  <div class="synchronize-warning" [ngClass]="offlinePosition" [class.hidden]="!offlineSynchronizing">
    Synchronizing offline fallback
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar> </div>
  <img class="pm-user-logo" [ngClass]="logoPositionClass" [src]="logoSource" [style.width]="logoWidth"
       [class.hidden]="!showLogo" [style.height]="logoHeight" *ngIf="hasLogo" (error)="showLogo = false"
       (load)="showLogo = true">
  <img class="pm-user-watermark" [src]="watermarkSource" [style.max-width]="watermarkSize"
       [style.max-height]="watermarkSize" [style.opacity]="watermarkOpacity" [class.hidden]="!showWatermark"
       *ngIf="hasWatermark" (error)="showWatermark = false" (load)="showWatermark = true">
</div>
