import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '@process-manager/pm-library';
import {PasswordUpdate} from '../../shared/components/password-change-component/password-change.component';

@Component({
  selector: 'pm-password-change-dialog',
  templateUrl: './password-change-dialog.component.html',
  styleUrls: ['./password-change-dialog.component.css']
})
export class PasswordChangeDialogComponent {
  pending: boolean;
  error: string;

  constructor(private userService: UserService, @Inject(
    MAT_DIALOG_DATA) public data: { username?: string, currentPassword?: string, passwordChangeRequired?: boolean },
    @Optional() private dialogRef: MatDialogRef<PasswordChangeDialogComponent>) {
  }

  get presetCurrentPassword(): string {
    return this.data && this.data.currentPassword;
  }

  onSubmit($event: PasswordUpdate) {
    return this.userService.updatePassword($event.currentPassword, $event.newPassword)
      .subscribe(() => {
        this.dialogRef.close();
        localStorage.setItem(UserService.CHANGE_PASSWORD_NAME, false.toString())
      });
  }
}
