import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserService} from '@process-manager/pm-library';
import * as Immutable from 'immutable';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Label} from '../model/label';
import {RenderedTemplateVersion} from '../model/rendered/rendered-bullet';
import {ElementFlags} from '../model/rendered/rendered-element';
import {RenderedProcess, RenderedTemplate} from '../model/rendered/rendered-process';
import {Shape} from '../model/shape';
import {ServerTemplate, Template} from '../model/template';
import {TemplateId} from '../model/template-id';
import {TemplateLanguageMappingService} from './template-language-mapping.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  private readonly jsonHeaderOptions = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

  constructor(private http: HttpClient, private userService: UserService,
    private templateLanguageMappingService: TemplateLanguageMappingService) {
  }

  listTemplates(): Observable<Template[]> {
    return this.http.get<any>(environment.api + this.userService.domain + '/templates',
      this.jsonHeaderOptions).pipe(map(templates => {
      return Object.keys(templates).map(templateName => {
        const template = templates[templateName];
        const descriptions = template['descriptions'];
        const versions = template['versions'];
        const extensions = template['extensions'];
        const isTrial = template['isTrial'];
        const templateVersionNames: string[] = Object.keys(versions).sort();
        return {
          id: templateName,
          versions: Immutable.OrderedMap(templateVersionNames.map(
            name => [name, Immutable.Map(Object.keys(versions[name]).map(lang => [lang, versions[name][lang]]))])),
          descriptions: Immutable.Map(Object.keys(descriptions).map(lang => [lang, descriptions[lang]])),
          extensions: extensions,
          isTrial: isTrial
        } as Template
      });
    }));
  }

  getTemplate(name: string): Observable<ServerTemplate> {
    return this.http.get<ServerTemplate>(environment.api + this.userService.domain + '/templates/' + name,
      this.jsonHeaderOptions);
  }

  createTemplate(name: string, template: ServerTemplate): Observable<void> {
    return this.http.put<void>(environment.api + this.userService.domain + '/templates/' + name, template,
      this.jsonHeaderOptions);
  }

  deleteTemplate(name: string): Observable<void> {
    return this.http.delete<void>(environment.api + this.userService.domain + '/templates/' + name,
      this.jsonHeaderOptions);
  }

  listVersions(name: string): Observable<string[]> {
    return this.http.get<string[]>(
      environment.api + this.userService.domain + '/templates/' + name + '/versions',
      this.jsonHeaderOptions);
  }

  getVersion(id: TemplateId): Observable<any> {
    return this.http.get<any>(
      environment.api + this.userService.domain + '/templates/' + id.templateId + '/versions/' +
      id.templateVersion, this.jsonHeaderOptions);
  }

  createVersion(nodeId: string, name: string, descriptions: { da: string, en: string }, version?: string) {
    const headers = {
      ...this.jsonHeaderOptions,
      params: new HttpParams().set('nodeId', nodeId)
    };
    if (!!version) {
      return this.http.put<void>(
        environment.api + this.userService.domain + '/templates/' + name + '/versions/' + version,
        {descriptions}, headers);
    } else {
      return this.http.post<void>(
        environment.api + this.userService.domain + '/templates/' + name + '/versions', {descriptions},
        headers);
    }
  }

  deleteVersion(name: string, version: string) {
    return this.http.delete<void>(
      environment.api + this.userService.domain + '/templates/' + name + '/versions/' + version,
      this.jsonHeaderOptions);
  }

  getPreRenderedTemplates(): Observable<RenderedProcess> {
    const OFF_FLAGS: ElementFlags = {
      link: false,
      text: false,
      info: false
    };
    const ROOT_STYLE = {
      id: 'template-root-style',
      bgColor: '',
      lineColor: '',
      shape: Shape.NONE
    };

    const CHILD_STYLE = {
      id: 'template-child-style',
      bgColor: '#0077be',
      lineColor: '#000000',
      shape: Shape.RECTANGLE
    };

    const BULLET_STYLE = {
      id: 'template-bullet-style',
      bgColor: '',
      lineColor: '',
      shape: Shape.NONE
    }

    const PERMS = {
      readAll: true,
      writeAll: false,
      subjects: []
    };
    const EMPTY_PRERENDER = {
      links: [],
      infoColor: '',
      info: '',
      extendedInfo: '',
      writeable: false,
      parentWriteable: false,
      ownerInherited: false,
      owner: '',
      siblingOpened: false,
      defaults: {...OFF_FLAGS},
      autoTranslated: {...OFF_FLAGS},
      labels: [],
      ancestorLabels: [],
      descendentLabels: [],
      interested: true,
      permissions: {...PERMS},
      permissionsInherited: false,
      searchable: false,
      highlights: {...OFF_FLAGS},
      activation: null,
    };

    return this.listTemplates().pipe(map(templates => {
      const children: RenderedProcess[] = templates.filter(template => template.versions?.size > 0).map(template => {
        const filteredTemplateId = template.id.replace(/[\s,]/g, '_');
        const versionNames = template.versions.keySeq().toArray();
        const currentVersionName = versionNames[versionNames.length - 1];

        const bullets: RenderedTemplateVersion[] = versionNames.reverse().slice(0, 4).map(versionName => {
          const version = template.versions.get(versionName);
          return {
            ...EMPTY_PRERENDER,
            id: filteredTemplateId + '-' + versionName + '-specific',
            templateId: template.id,
            templateVersion: versionName,
            parentId: filteredTemplateId,
            nodeType: 'bullet',
            label: `<a href="pm-template-version://${template.id}/${versionName}" draggable="false">Version: ${versionName}</a>`,
            info: version.get('da'),
            style: BULLET_STYLE,
            backup: false,
            isTemplateTop: true
          } as RenderedTemplateVersion
        });
        return {
          ...EMPTY_PRERENDER,
          id: filteredTemplateId + '-' + currentVersionName,
          templateId: template.id,
          templateVersion: currentVersionName,
          parentId: 'template-root',
          style: {...CHILD_STYLE},
          children: [],
          nodeType: 'mainpage',
          label: `<span style="color: white">${template.id}</span>`,
          info: template.descriptions.get('da'),
          labels: this.getLabels(template),
          owner: '',
          hasUnloadedChildren: true,
          bullets: bullets,
          isOpen: false,
          needsSave: false,
          childSize: {
            width: 3,
            height: 1.5
          },
          backup: false,
          isTemplateTop: true
        }
      }).filter(child => !!child) as RenderedTemplate[];

      return {
        ...EMPTY_PRERENDER,
        id: 'template-root',
        parentId: '',
        style: {...ROOT_STYLE},
        nodeType: 'page',
        hasUnloadedChildren: false,
        children: children,
        label: '<p style="text-align: center; font-weight: bold">Templates</p>',
        bullets: [],
        isOpen: false,
        needsSave: false,
        childSize: {
          width: 3,
          height: 1.5
        },
        backup: false
      };
    }));
  }

  overwriteSite(id: TemplateId): Observable<any> {
    const languageMap = this.templateLanguageMappingService.getMappingIds();

    if (!languageMap) {
      throw new Error('No map set!');
    }
    return this.http.put(
      environment.api + this.userService.domain + '/templates/' + id.templateId + '/versions/' +
      id.templateVersion + '/copy', {'languageMap': languageMap}, {
        ...this.jsonHeaderOptions,
        params: {clearOldTree: 'true'}
      });
  }

  private getLabels(template: Template): Label[] {
    if (template.isTrial) {
      return [{
        id: 1,
        name: 'Trial!',
        color: '#112233',
        isTrial: true,
        extension: false
      }]
    }
  }
}
