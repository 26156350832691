import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import {Subscription} from 'rxjs';
import {HistoryEventBlock} from '../../../../shared/model/history-events';

import {HistoryLoaderResponse, HistoryLoaderService} from '../../../../shared/services/history-loader.service';

@Component({
  selector: 'pm-all-history-dialog',
  templateUrl: './all-history-dialog.component.html',
  styleUrls: ['./all-history-dialog.component.css']
})
export class AllHistoryDialogComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  public currentLoaderResponse: HistoryLoaderResponse;
  private eventSubscription: Subscription;

  constructor(private historyLoaderService: HistoryLoaderService, private zone: NgZone,
    private cd: ChangeDetectorRef) {
  }

  identity(index: number, item: HistoryEventBlock) {
    return item.commitId;
  }

  dataSubscriber = (data: HistoryLoaderResponse) => this.zone.run(() => {
    this.currentLoaderResponse = data;
    this.cd.markForCheck();
  });

  public loadHistory(cursorId: number = 0, previous: boolean = false): void {
    this.eventSubscription = this.historyLoaderService.loadHistory(true, cursorId, previous).subscribe(this.dataSubscriber);
  }

  ngOnInit(): void {
    this.loadHistory();
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe()
  }

  expandAll() {
    this.accordion.openAll();
    return undefined;
  }

  closeAll() {
    this.accordion.closeAll();
    return undefined;

  }

  previous() {
    this.loadHistory(this.currentLoaderResponse.result[0].commitId, true);
  }

  next() {
    this.loadHistory(this.currentLoaderResponse.result[this.currentLoaderResponse.result.length - 1].commitId, false);
  }
}
