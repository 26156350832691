<div class="pm-templates" *ngIf="(templatesShowing$ | async)">
  <pm-row [rowNode]="templatesWithActiveNode$ | async" [dragDropActive]="false" [openRows]="[]"
          [class.active]="activeTemplateNodeIsProcess$ | async"
          [rowProcessSize]="(treeRoot$ | async).childSize" [isMainPage]="isMainPage"
          (activationChange)="templateActivationChange($event)" (elementClick)="templateElementClick($event)"
          (linkClick)="templateLinkClick($event)" [isTemplateRoot]="true">
  </pm-row>
</div>
<ng-container *ngFor="let rowNode of openRows;trackBy: getRowId">
  <div class="pm-dropzone-row-between" [attr.data-element-id]="rowNode.id" [attr.data-element-type]="'mainpage'"
       *ngIf="isMainPage" [style.width]="getProcessWidth(treeRoot$ | async)" data-element-drop-type="before">
    <div class="pm-dropzone-highlight"></div>
  </div>
  <div class="pm-row-spacer" *ngIf="!isMainPage"></div>
  <pm-row [ngClass]="getActiveState(rowNode)" [rowNode]="rowNode" [isMainPage]="isMainPage"
          [rowProcessSize]="(treeRoot$ | async).childSize"
          [openRows]="openRows" [isAuthor]="isAuthor" (activationChange)="activationChange($event)"
          (elementClick)="elementClick($event)" (linkClick)="linkClick($event)"
          (resized)="resized($event)" (updated)="updated($event)" >
  </pm-row>
</ng-container>
<div class="pm-dropzone-row-between" [attr.data-element-id]="(treeRoot$ | async).id"
     [attr.data-element-type]="'mainpage'" [attr.data-element-is-below]="true" *ngIf="isMainPage && (treeRoot$ | async).writeable"
     [style.width]="getProcessWidth(treeRoot$ | async)" data-element-drop-type="under">
  <div class="pm-dropzone-highlight"></div>
</div>
