import {Component, HostBinding, Input, OnChanges} from '@angular/core';
import {Shape} from '../../../../shared/model/shape';
import {Style} from '../../../../shared/model/style';

export const HORIZONTAL_ARROW_SIZE = 30;
export const VERTICAL_ARROW_SIZE = 20;

@Component({
  selector: 'pm-shape',
  templateUrl: './shape.component.html',
  styleUrls: ['./shape.component.css'],
})
export class ShapeComponent implements OnChanges {
  @Input() style: Style;
  @Input() width: number;
  @Input() height: number;
  @Input() highlight: boolean;

  @HostBinding('class.pm-isopen')
  @Input() isOpen: boolean;
  shape: Shape = Shape.NONE;
  lineColor = '#000';
  bgColor = '#000';
  strokeWidth = 1;

  constructor() {
  }

  get rightArrowPoints(): string {
    const bend: number = this.width - HORIZONTAL_ARROW_SIZE;
    return `0,0 ${bend},0 ${this.width},${this.height / 2} ${bend},${this.height} 0,${this.height}`;
  }

  get leftArrowPoints(): string {
    return `0,${this.height /
    2} ${HORIZONTAL_ARROW_SIZE},0 ${this.width},0 ${this.width},${this.height} ${HORIZONTAL_ARROW_SIZE},${this.height}`;
  }

  get upArrowPoints(): string {
    return `0,${VERTICAL_ARROW_SIZE} ${this.width /
    2},0 ${this.width},${VERTICAL_ARROW_SIZE} ${this.width},${this.height}, 0,${this.height}`;
  }

  get downArrowPoints(): string {
    const bend: number = this.height - VERTICAL_ARROW_SIZE;
    return `0,0 ${this.width},0 ${this.width},${bend} ${this.width / 2},${this.height} 0,${bend}`;
  }

  @HostBinding('class.pm-highlight') get iosHighlight(): boolean {
    return this.highlight;
  }

  get highlightFilter(): string {
    return '';
  }

  get viewBox(): string {
    return `0,0 ${this.width} ${this.height}`;
  }

  ngOnChanges() {
    if (this.style) {
      this.shape = this.style.shape;
      this.bgColor = this.style.bgColor;
      this.lineColor = this.style.lineColor;
    }
  }

  ngOnInit() {
  }

}
