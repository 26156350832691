import {Component, Input, OnInit} from '@angular/core';
import {AppConfig} from '../../../../../../app.config';
import {EventLink, ResourceColorChanged} from '../../../../../../shared/model/history-events';
import {NavigationService} from '../../../../../../shared/services/navigation.service';

@Component({
  selector: 'pm-resource-color-changed-renderer',
  templateUrl: './resource-color-changed-renderer.component.html',
  styleUrls: ['./resource-color-changed-renderer.component.css']
})
export class ResourceColorChangedRendererComponent implements OnInit {
  @Input()
  event: ResourceColorChanged;

  constructor(private appConfig: AppConfig, private navigationService: NavigationService) { }

  ngOnInit(): void {

  }

  browseToNode(link: EventLink) {
    if (link.stillExists) {
      this.appConfig.highlightNext = true;
      this.navigationService.navigateToNode(link.nodeId);
    }
  }

  prepareLink(link: EventLink): EventLink {
    if(!link.nodeType) {
      link = {...link, nodeType: 'node'}
    }
    return link;
  }
}
