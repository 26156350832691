import {
  IMAGE_CLEAR_ALL, IMAGE_CLEAR_LOGO, IMAGE_CLEAR_WATERMARK, IMAGE_SET_LOGO, IMAGE_SET_WATERMARK, ImageActions
} from '../actions/image.actions';
import {LogoSettings, WatermarkSettings} from './tree.reducer';

export interface ImageTempState {
  tempLogo: LogoSettings;
  tempWatermark: WatermarkSettings;
}

export const initialState: ImageTempState = {
  tempLogo: undefined,
  tempWatermark: undefined
};

export function tempImageReducer(state = initialState, action: ImageActions) {
  switch (action.type) {
    case IMAGE_SET_LOGO:
      return {
        ...state,
        tempLogo: action.payload.logo
      };
    case IMAGE_SET_WATERMARK:
      return {
        ...state,
        tempWatermark: action.payload.watermark
      };
    case IMAGE_CLEAR_LOGO:
      return {
        ...state,
        tempLogo: undefined
      };
    case IMAGE_CLEAR_WATERMARK:
      return {
        ...state,
        tempWatermark: undefined
      };
    case IMAGE_CLEAR_ALL:
      return {...initialState};
    default:
      return state;
  }
}
