import {Component, ElementRef, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '@process-manager/pm-library';
import {Observable} from 'rxjs';
import {finalize, take} from 'rxjs/operators';
import {DictionaryService} from '../../../shared/services/dictionary.service';
import {DictionaryControlService} from './dictionary-control.service';

type ControlObject = {
  [key: string]: AbstractControl;
};

@Component({
  selector: 'pm-auto-translate-dictionary-dialog',
  templateUrl: './auto-translate-dictionary-dialog.component.html',
  styleUrls: ['./auto-translate-dictionary-dialog.component.css']
})
export class AutoTranslateDictionaryDialogComponent implements OnInit {
  @ViewChild('dialogContent', {read: ElementRef, static: true}) dialogContent: ElementRef;

  languages: Language[];
  loading = true;
  error = false;
  saving = false;
  warnings: string[] = [];

  dictionaryForm: FormGroup = new FormGroup({'dictionary': new FormArray([])});
  dataSource: any[] = [];

  constructor(private dictionaryService: DictionaryService, private controlService: DictionaryControlService,
              @Optional() private dialogRef: MatDialogRef<AutoTranslateDictionaryDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: Language[], private translate: TranslateService) {
    this.languages = data || [];
  }

  get defaultLanguage(): Language {
    return this.languages.find(lang => lang.isDefault);
  }

  get otherLanguages(): Language[] {
    return this.languages.filter(lang => !lang.isDefault);
  }

  get sortedLanguages(): Language[] {
    if(!!this.defaultLanguage) {
      return [this.defaultLanguage, ...this.otherLanguages.filter(lang => lang.autoTranslateLanguage)];
    } else {
      return [];
    }
  }

  get columns(): string[] {
    return this.sortedLanguages.map(this.getColumnId).concat(['delete']);
  }

  ngOnInit(): void {
    this.dictionaryForm.valueChanges.subscribe(value => {
      if (this.dataSource.length !== value.dictionary.length) {
        this.dataSource = value.dictionary;
      }
    });
    this.dataSource = this.dictionaryForm.value.dictionary;

    this.dictionaryService.loadDictionary().pipe(take(1), finalize(() => this.loading = false)).subscribe(response => {
      this.warnings = response.warnings || [];
      this.controlService.toFormArray(this.languages, this.dictionaryFormArray, response.dictionary);
      this.dictionaryFormArray.setValidators(this.controlService.groupDuplicateValidator(this.defaultLanguage));
    }, () => {
      this.warnings = ['could_not_load'];
      this.error = true;
    });
  }

  getColumnId = (lang: Language) => this.controlService.getColumnId(lang);

  get prioritisedValidationError(): string {
    const controlTreeFlatten = (control: AbstractControl): string[] => [...(Object.values((control['controls'] ||
      {}) as ControlObject).flatMap(child => controlTreeFlatten(child))), ...Object.keys(control.errors || {})];
    const errors = controlTreeFlatten(this.dictionaryForm);

    if(!errors || errors.length === 0) {
      return null;
    }
    return errors[0];
  }

  deleteRow(index: number) {
    this.dictionaryFormArray.removeAt(index);
    this.dictionaryFormArray.markAsDirty();
  }

  addRow() {
    const formArray = this.dictionaryFormArray;
    formArray.push(this.controlService.createRow(this.languages));
    this.scrollEntryListTo('bottom');
  }

  private get dictionaryFormArray(): FormArray {
    return this.dictionaryForm.controls['dictionary'] as FormArray;
  }

  getControl(i: number, columnId: string): FormControl {
    return this.dictionaryForm.get('dictionary.' + i + '.' + columnId) as FormControl;
  }

  getGroup(i): FormGroup {
    return this.dictionaryForm.get('dictionary.' + i) as FormGroup;
  }

  onSubmit() {
    if(this.dictionaryForm.valid) {
      const observable: Observable<void> = this.dictionaryService.saveDictionary(
        this.dictionaryFormArray.value);

      this.saving = true;
      observable.pipe(finalize(() => this.saving = false)).subscribe(() => {
        if (!!this.dialogRef) {
          this.dialogRef.close()
        }
      }, () => {
        this.warnings.push('could_not_save');
        this.scrollEntryListTo('top');
      });
    }
  }

  getPlaceholder(lang: Language) {
    if(lang.isDefault) {
      return this.translate.instant('dialog.language-admin.dictionary.placeholder.required');
    } else {
      return this.translate.instant('dialog.language-admin.dictionary.placeholder.auto-translated');
    }
  }

  getLangName(lang: Language) {
    if(lang.isDefault) {
      return this.translate.instant('dialog.language-admin.dictionary.default-lang', {lang: lang.name});
    } else {
      return lang.name;
    }
  }

  private scrollEntryListTo(to: 'top' | 'bottom') {
    setTimeout(() => {
      const objDiv = this.dialogContent.nativeElement as Element;
      if(to === 'bottom') {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else if(to === 'top') {
        objDiv.scrollTop = 0;
      }
    }, 0);
  }
}
