import {HttpEventType} from '@angular/common/http';
import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {UserService} from '@process-manager/pm-library';
import {v1} from 'uuid';
import {PopupDialogComponent, PopupDialogData} from '../../../shared/components/popup-dialog/popup-dialog.component';
import {TreeElement} from '../../../shared/model/treeelement';
import {stripHtml} from '../../../shared/styleExtractor';
import {
  internalDocumentTrumbowygOptions, JQueryTrumbowyg, TrumbowygDirective
} from '../../../shared/trumbowyg.directive';
import {AddResource, Info, ResourceLink, ResourceType, Upload} from '../../../state-management/actions/tree.actions';
import {AppState} from '../../../state-management/reducers';
import {PromanLinkService} from '../shared/proman-link.service';

@Component({
  selector: 'pm-add-link-dialog',
  templateUrl: './link-dialog.component.html',
  styleUrls: ['./link-dialog.component.css']
})
export class LinkDialogComponent implements AfterViewInit {
  resourceType: ResourceType = this.hasInfo ? 'link' : 'info';
  infoTitle: string;
  infoBody: string;
  uploadResult: {
    token: string,
    preSignedUrl: string
  };
  uploadFilename: string;
  linkUrl: string;
  linkName: string;
  progress = 0;

  @ViewChild(TrumbowygDirective)
  set trumbowyg(directive: TrumbowygDirective) {
    directive.registerOnChange((value: string) => this.dialogRef.disableClose = !!value && stripHtml(value).length > 0);
  }

  pending = false;

  readonly trumbowygOptions: JQueryTrumbowyg.Options = {...internalDocumentTrumbowygOptions};

  constructor(public dialogRef: MatDialogRef<LinkDialogComponent>, @Inject(MAT_DIALOG_DATA) public context: TreeElement,
              private store$: Store<AppState>, private linkService: PromanLinkService, private dialog: MatDialog, private userService: UserService) {
  }

  get filesAllowed(): boolean {
    return this.userService.getSiteSettings()?.allowedTypes?.includes('file');
  }

  get hasInfo(): boolean {
    return !!this.context.info || !!this.context.extendedInfo;
  }

  get closeable() {
    return this.resourceType !== 'upload' || !!this.uploadResult;
  }

  ngAfterViewInit() {

  }

  get isTranslation(): boolean {
    return this.context?.defLinks;
  }

  onSubmit(): void {
    if (this.pending) {
      this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
        data: {
          title: 'dialog.link.please-wait'
        }
      });
      return;
    }

    let data: Upload | Info | ResourceLink;

    switch (this.resourceType) {
      case 'info':
        data = {
          title: this.infoTitle,
          body: this.infoBody
        };
        break;
      case 'link':
        data = {url: this.ensureSchemeAdded(this.linkUrl), name: this.linkName};
        break;
      case 'upload':
        data = {
          token: this.uploadResult.token,
          tempSource: this.uploadResult.preSignedUrl,
          filename: this.uploadFilename,
        };
        break;
      default:
        throw new Error('unsupported data type');
    }

    this.store$.dispatch(new AddResource({
      elementId: this.context.id,
      resourceType: this.resourceType,
      newLinkId: v1(),
      data: data
    }));

    this.dialogRef.close();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  handleUploads($event: Event) {
    const files: FileList = ($event.target as HTMLInputElement).files;
    if (files.length > 0) {
      this.progress = 0;
      this.pending = true;
      this.uploadFilename = files[0].name;
      this.uploadResult = null;
      this.linkService.uploadFile(files[0]).subscribe(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = (event.loaded * 100) / event.total;
            break;
          case HttpEventType.Response:
            this.pending = false;
            this.uploadResult = event.body;
            console.log('Uploadresult:', this.uploadResult)
        }
      }, error => {
        this.pending = false;
        console.log('Something went wrong', error);
        this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
          data: {
            body: 'dialog.link.upload-error'
          }
        });
      });
    } else {
      this.uploadResult = null;
    }
  }

  private ensureSchemeAdded(input: string): string {
    if (!input.includes('://')) {
      return 'http://' + input;
    }
    return input;
  }
}
