<span mat-dialog-title>{{'dialog.cant-open.title.' + data.reason | translate}}</span>
<mat-dialog-content class="mat-typography">
  <div><h4>{{'dialog.cant-open.summary.' + data.reason | translate}}</h4></div>
  <div>
    <h3>{{'dialog.cant-open.id' | translate}}</h3>
    <div>{{data.nodeId}}</div>
  </div>
  <div *ngIf="data.noLoadedNodes">
    <h3>{{'dialog.cant-open.go-to-startnode' | translate}}</h3>
    <div>{{'dialog.cant-open.go-to-startnode.details' | translate}}</div>
  </div>
  <div *ngIf="data.isStartNode">
    <h3>{{'dialog.cant-open.was-startnode' | translate}}</h3>
    <div>{{'dialog.cant-open.was-startnode.details' | translate}}</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions >
  <button mat-raised-button mat-dialog-close *ngIf="!data.isStartNode">{{'button.close' | translate}}</button>
  <button mat-raised-button mat-dialog-close (click)="logout()" *ngIf="data.isStartNode">{{'button.logout' | translate}}</button>
</mat-dialog-actions>
