<form (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>
  <div class="form-field-container">
    <mat-form-field appearance="outline">
      <mat-label>{{'login.domain' | translate}}</mat-label>
      <input autocomplete="organization" matInput id="pm_domain" name="domain"
             [ngModel]="domainValue" autofocus required/>
    </mat-form-field>
  </div>
  <button [disabled]="pending" mat-raised-button>{{'button.ok' | translate}}</button>
  <div *ngIf="error" class="alert alert-danger">{{errorMessage}}</div>
</form>
