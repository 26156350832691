<pm-labels-overlay class="pm-remove-when-dragging" *ngIf="hasLabels" [context]="context"></pm-labels-overlay>
<pm-link-overlay class="pm-remove-when-dragging" *ngIf="hasLinks" [node]="context"
                 (linkClicked)="linkClicked($event)" [highlightInfo]="context?.highlights?.info"></pm-link-overlay>
<div class="bullet pm-user-content" [pmTrumbowyg]="trumbowygOptions" (pmLinkClick)="linkClicked($event)"
      [class.text-highlight]="context?.highlights?.text" [offsetElement]="nativeElement"
      [innerHtml]="context.label | unsafeHtml" [enabled]="isEditingText"></div>

<div *ngIf="isActive && context.autoTranslated.text" class="mat-small pm-auto-translate-notice pm-remove-when-dragging">{{'menu.link.auto-translated' | translate}}</div>
<pm-menu-trigger-overlay (click)="contextMenu($event)"></pm-menu-trigger-overlay>

<pm-options-overlay class="pm-remove-when-dragging" [context]="context" [contextElement]="elementRef"
                    (toggleEdit)="toggleEdit($event)" *ngIf="isActive" (click)="prevent($event)"></pm-options-overlay>
<pm-link-menu class="pm-remove-when-dragging" [node]="context" *ngIf="hasLinks"
              [open]="isActive && !isEditingText" [isAuthor]="isAuthor"
              (linkClicked)="linkClicked($event)"></pm-link-menu>
