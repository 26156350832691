
// TODO: Use mapping to get preferred language instead of guessing.

import {Language} from '@process-manager/pm-library';

export const getPreferredLanguage = (activeLanguage: Language, languages: Language[]): Language => {
  const prioritizedLanguages = languages.filter(lang => lang !== null).map(language => {
    let priority = 1;
    if (language?.interfaceLanguage === activeLanguage?.interfaceLanguage) {
      priority += 2;
    }

    if (language.isDefault) {
      priority++;
    }

    return {
      language,
      priority
    };
  }).sort((a, b) => b.priority - a.priority);
  if (prioritizedLanguages.length === 0) {
    return activeLanguage;
  } else {
    return prioritizedLanguages[0].language;
  }
}
