import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Link} from '../../../shared/model/link';
import {RenderedBullet} from '../../../shared/model/rendered/rendered-bullet';
import {RenderedElement} from '../../../shared/model/rendered/rendered-element';
import {TemplateId} from '../../../shared/model/template-id';
import {ActivationType} from '../../../state-management/reducers/tree.reducer';

@Component({
  selector: 'pm-bullet-list',
  templateUrl: './bullet-list.component.html',
  styleUrls: ['./bullet-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulletListComponent {
  @Input() bullets: (RenderedBullet & Partial<TemplateId>)[];
  @Input() isAuthor: boolean;
  @Input() dragDropActive = true;
  @Input() isTemplateRoot = false;
  @Output() activationChange = new EventEmitter<{ element: RenderedElement, type: ActivationType }>();
  @Output() updated = new EventEmitter<{ element: RenderedElement, content: ActivationType }>();
  @Output() linkClick = new EventEmitter<{ element: RenderedElement, link: Link | string }>();
  @Output() elementClick = new EventEmitter<RenderedElement>();

  constructor() {
  }

  getBulletId(index: number, bullet: RenderedBullet): string {
    return bullet?.id || '';
  }

  bulletIsActive(bullet: RenderedBullet) {
    return !!bullet.activation && bullet.activation !== 'highlight';
  }
}
