<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" [class.folded]="folded" class="mat-typography dialog-content">
  <pm-foldable-dialog-title mat-dialog-title (foldedChange)="folded = $event">{{'dialog.link-check.title' | translate}}<mat-spinner matSuffix [class.proman-visible]="loading" [diameter]="18"></mat-spinner></pm-foldable-dialog-title>

  <mat-dialog-content>
    <div class="link-check-description">{{'dialog.link-check.description' | translate}}</div>
    <div class="link-check-description mat-small">{{'dialog.link-check.description.language' | translate}}</div>
    <table mat-table class="pm-show-active" [dataSource]="dataSource">
      <ng-container matColumnDef="label">
        <th mat-header-cell class="proman-search-header" *matHeaderCellDef> {{'dialog.link-check.table.label' |
          translate}}
        </th>
        <td mat-cell *matCellDef="let element" [title]="element.label"> {{element.label}}
        </td>
      </ng-container>

      <ng-container matColumnDef="path">
        <th mat-header-cell *matHeaderCellDef> {{'dialog.link-check.table.path' | translate}}</th>
        <td mat-cell class="proman-search-path" *matCellDef="let element"
            [title]="element.path"> {{element.path}}</td>
      </ng-container>

      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef> {{'dialog.link-check.table.link' | translate}}</th>
        <td mat-cell class="proman-search-path" *matCellDef="let element"
            [title]="element.link"> {{'dialog.link-check.table.link.cell' | translate:element.extraData}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToNode(row)"
               [class.active]="row.id === activeSearchElement"></tr>
    </table>
  </mat-dialog-content>
</div>
