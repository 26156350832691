<form (ngSubmit)="onSubmit()" #f="ngForm" class="mat-typography" novalidate>
  <h2 mat-dialog-title>{{'dialog.link.title' | translate}}</h2>
  <mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-select placeholder="{{'dialog.link.resource-type.label' | translate}}" name="resource_type"
                    [(ngModel)]="resourceType">
          <mat-option value="info" [disabled]="hasInfo">{{'dialog.link.resource-type.internal-document' | translate}}
          </mat-option>
          <mat-option value="link">{{'dialog.link.resource-type.link' | translate}}</mat-option>
          <mat-option *ngIf="filesAllowed" value="upload">{{'dialog.link.resource-type.upload' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field *ngIf="resourceType === 'info'">
      <input matInput id="pm_info_title" name="info_title" placeholder="{{'dialog.link.document-title' | translate}}"
             type="text" [(ngModel)]="infoTitle" required>
      <mat-hint>{{'dialog.link.document-title.hint' | translate}}</mat-hint>
    </mat-form-field>

    <div class="pm-info-editor" *ngIf="resourceType === 'info'" [pmTrumbowyg]="trumbowygOptions" [(ngModel)]="infoBody"
         [autoFocus]="false" name="info_body"></div>

    <div class="pm-translation-warning" *ngIf="resourceType !== 'info' && isTranslation">{{'dialog.link.translating' | translate}}</div>

    <div class="pm-url-link" *ngIf="resourceType === 'link'">
      <mat-form-field >
        <input matInput id="pm_link_url" name="link_url" placeholder="{{'dialog.link.url' | translate}}" type="url"
               required [(ngModel)]="linkUrl">
      </mat-form-field>

      <mat-form-field>
        <input matInput id="pm_link_name" name="link_name" placeholder="{{'dialog.link.name' | translate}}" type="text"
               [(ngModel)]="linkName">
        <mat-hint>{{'dialog.link.name.hint' | translate}}</mat-hint>
      </mat-form-field>
    </div>
    <!-- TODO: Let's style this better -->
    <input type="file" id="pm_upload" name="upload" *ngIf="resourceType === 'upload'" (change)="handleUploads($event)" required accept="*/*" minlength="1">
    <mat-progress-bar *ngIf="resourceType === 'upload'" mode="determinate" [value]="progress"></mat-progress-bar>
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-button type="submit" [disabled]="f.invalid || !closeable">{{'button.ok' | translate}}</button>
    <button mat-button type="button" (click)="onCancelClick()">{{'button.cancel' | translate}}</button>
  </div>
</form>
