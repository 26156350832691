import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'pm-temp-password-dialog',
  templateUrl: './temp-password-dialog.component.html',
  styleUrls: ['./temp-password-dialog.component.css']
})
export class TempPasswordDialogComponent implements OnInit {
  @Input() password: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { password: string, error: boolean }) {
  }


  get param() {
    return {password: this.data.password};
  }

  ngOnInit() {
  }
}
