import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[pmLinkClick]'
})
export class LinkClickDirective {
  @Output() pmLinkClick: EventEmitter<string> = new EventEmitter();

  @HostListener('click', ['$event'])
  linkClick($event) {
    const target = this.targetMatches($event.target, 'a');
    if (!!target) {
      if ($event.stopImmediatePropagation) {
        $event.stopImmediatePropagation();
      }
      if ($event.stopPropagation) {
        $event.stopPropagation();
      }
      this.pmLinkClick.emit(target.href);
      return false;
    }
    return true;
  }

  private targetMatches(target: any, selector: string) {
    for (; target && target !== document; target = target.parentNode) {
      if (target.matches(selector)) {
        return target;
      }
    }

    return undefined;
  }
  constructor() { }

}
