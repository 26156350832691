import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckTouchService implements OnInit {
  userTouch = new BehaviorSubject<boolean>(false);

  constructor() {
    const that = this;

    // Test via a getter in the options object to see if the passive property is accessed
    let supportsPassive = false;
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: function() {
          supportsPassive = true;
        }
      });
      window.addEventListener('testPassive', null, opts);
      window.removeEventListener('testPassive', null, opts);
    } catch (e) {}

    // Legacy support for stupid browsers.
    window.addEventListener('touchstart', function onFirstTouch() {
      if (!that.touching) {
        that.userTouch.next(true);
      }
      window.removeEventListener('touchstart', onFirstTouch, false);
    }, supportsPassive ? { passive: true } : false);

    window.addEventListener('pointerdown', function onFirstPointer(event: PointerEvent) {
      const isTouch = event.pointerType !== 'mouse';
      if (isTouch && !that.touching) {
        that.userTouch.next(isTouch);
      }
      window.removeEventListener('pointerdown', onFirstPointer, false);
    });
  }

  get touching$() {
    return this.userTouch.asObservable();
  }

  get touching() {
    return this.userTouch.getValue();
  }

  ngOnInit(): void {
  }
}
