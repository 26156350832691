import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Language, UserService} from '@process-manager/pm-library';
import {isObservable, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

export interface AutoTranslationLanguage {
  displayName: string;
  languageCode: string;
  supportSource: boolean;
  supportTarget: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private readonly jsonHeaderOptions = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

  constructor(private http: HttpClient, private userService: UserService,
    private translateService: TranslateService) {
  }

  public changeUiLanguage(uiLanguage: string | Observable<string>) {
    if (isObservable(uiLanguage)) {
      uiLanguage.subscribe(language => {
        this.doChangeUiLanguage(language);
      })
    } else {
      this.doChangeUiLanguage(uiLanguage);
    }
  }

  public getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(environment.api + this.userService.domain + '/languages/', this.jsonHeaderOptions);
  }

  public getAvailableAutoTranslationLanguages(): Observable<AutoTranslationLanguage[]> {
    return this.http.get<AutoTranslationLanguage[]>(
      environment.api + this.userService.domain + '/languages/autotranslate/supported');
  }

  public createLanguage(language: Language): Observable<void> {
    return this.http.post<void>(environment.api + this.userService.domain + '/languages/', language,
      this.jsonHeaderOptions);
  }

  public updateLanguage(language: Language, oldName: string = language.name): Observable<void> {
    return this.http.put<void>(environment.api + this.userService.domain + '/languages/' + oldName, language,
      this.jsonHeaderOptions);
  }

  public deleteLanguage(name: string): Observable<void> {
    return this.http.delete<void>(environment.api + this.userService.domain + '/languages/' + name,
      this.jsonHeaderOptions);
  }

  private doChangeUiLanguage(uiLanguage: string) {
    const uiLang = uiLanguage || this.translateService.getBrowserCultureLang();

    const langWithUnderscore = () => uiLang.toLowerCase().replace('-', '_');
    const langWithDash = () => uiLang.toLowerCase().replace('_', '-');
    this.translateService.use(langWithUnderscore());
    document.documentElement.setAttribute("lang", langWithDash());
  }
}
