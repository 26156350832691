import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface PopupDialogData {
  title?: string;
  body?: string;
  okLabel?: string;
  actionLabel?: string;
}

@Component({
  selector: 'pm-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.css']
})
export class PopupDialogComponent {
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: PopupDialogData) {
    if (!this.data) {
      // Not sure why a default initializer doesn't work, but it doesn't
      this.data = {};
    }
  }

  get okLabel(): string {
    return this.data.okLabel || 'button.ok';
  }

  get title(): string {
    return this.data.title === undefined ? 'dialog.error.title' : this.data.title;
  }

  get body(): string {
    return this.data.body || '';
  }
}
