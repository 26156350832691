import {Component, OnInit, Optional} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {finalize} from 'rxjs/operators';
import {ServerTemplate} from '../../shared/model/template';
import {TemplatesService} from '../../shared/services/templates.service';
import {PmValidators} from '../../shared/validators/pm-validators';

const NEW_TEMPLATE: ServerTemplate = {
    name: '',
    descriptions: {
        da: '',
        en: ''
    },
    versions: []
}

@Component({
    selector: 'pm-template-admin-dialog',
    templateUrl: './template-admin-dialog.component.html',
    styleUrls: ['./template-admin-dialog.component.css']
})
export class TemplateAdminDialogComponent implements OnInit {
    readonly NEW_TEMPLATE_SELECT = '__new_template__';
    templateNames: string[] = [];
    selectedTemplate = this.NEW_TEMPLATE_SELECT;
    originalTemplate = {...NEW_TEMPLATE};

    templateForm = this.fb.group({
        name: [NEW_TEMPLATE.name, [Validators.required, Validators.minLength(1),
          Validators.maxLength(30), PmValidators.uniqueNameValidator(this.templateNames, this.originalTemplate.name)]],
        descriptions: this.fb.group({
            da: [NEW_TEMPLATE.descriptions.da, [Validators.required]],
            en: [NEW_TEMPLATE.descriptions.en, [Validators.required]]
        })
    })

    private submitting: boolean;

    constructor(private fb: FormBuilder, private templatesService: TemplatesService,
      @Optional() private dialogRef: MatDialogRef<TemplateAdminDialogComponent>) {
    }

    get name() {
        return this.templateForm.get('name');
    }

    get descriptionDa() {
        return this.templateForm.get('descriptions.da');
    }

    get descriptionEn() {
        return this.templateForm.get('descriptions.da');
    }

    get isNew() {
        return this.originalTemplate.name === this.NEW_TEMPLATE_SELECT;
    }

    ngOnInit(): void {
        this.templatesService.listTemplates().subscribe(templates => this.templateNames = templates.map(template => template.id));
    }

    templateSelectionChange($event: MatSelectChange) {
        if ($event.value === this.NEW_TEMPLATE_SELECT) {
            this.originalTemplate = {...NEW_TEMPLATE};
            this.templateForm.reset(this.originalTemplate);
        } else {
            this.templatesService.getTemplate($event.value).subscribe((template) => {
                this.originalTemplate = template;
                this.templateForm.reset(template);
            });
        }
    }

    submit() {
        const template = this.templateForm.value as ServerTemplate;
        this.submitting = true;

        this.templatesService.createTemplate(this.originalTemplate.name || template.name, template).pipe(
          finalize(() => this.submitting = false)).subscribe(() => {
            this.dialogRef.close();
        });
    }

    delete() {
        const deletedName = this.originalTemplate.name;
        this.templatesService.deleteTemplate(deletedName).subscribe(() => {
            this.templateNames = this.templateNames.filter(templateName => templateName != deletedName);
            this.selectedTemplate = this.NEW_TEMPLATE_SELECT;
            this.originalTemplate = {...NEW_TEMPLATE};
            this.templateForm.reset(this.originalTemplate);
        });
    }
}
