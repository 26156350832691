import {Language} from '@process-manager/pm-library';
import * as Immutable from 'immutable';

import {Label} from './label';
import {Link} from './link';
import {TemplateId} from './template-id';
import {TreeElement} from './treeelement';

export class PathElement {
  readonly _id: string;
  readonly _label: string;

  constructor(id: string, label: string) {
    this._id = id;
    this._label = label;
  }

  get id(): string {
    return this._id;
  }

  get label(): string {
    return this._label;
  }
}

export interface Tree {
  readonly source: 'customer' | 'offline' | TemplateId;
  readonly path: PathElement[];
  readonly localPath: PathElement[];
  readonly root: string;
  readonly labels: Immutable.Map<number, Label>;
  readonly nodes: Immutable.Map<string, TreeElement>;
  readonly links: Immutable.Map<string, Link>;
}

export interface TreeWithLanguages extends Tree {
  readonly languages: Language[];
  readonly translatedNodes: Immutable.Map<number, Immutable.Map<string, TreeElement>>;
  readonly translatedLinks: Immutable.Map<number, Immutable.Map<string, Link>>
}
