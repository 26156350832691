<pm-process class="page-process" *ngIf="isMainPage && !!rowNode" [context]="rowNode"
            [size]="rowProcessSize" [isAuthor]="isAuthor"
            [dragDropActive]="dragDropActive" (activationChange)="activationChange.emit($event)"
            (elementClick)="elementClick.emit($event)" (linkClick)="linkClick.emit($event)"
            [class.pm-not-interested]="!rowNode.interested"
            (resized)="resized.emit($event)" (updated)="updated.emit($event)" ></pm-process>
<div class="pm-row-children">
  <div class="pm-unloaded-children" *ngIf="rowNode?.hasUnloadedChildren">{{ 'process-tree.children.visible' |
    translate }}
  </div>
  <ng-container *ngFor="let childNode of rowNode?.children;trackBy: getItemId">
    <div [class.pm-dropzone-between]="canDrop" [class.pm-dropzone-between-dead]="!canDrop" [attr.data-element-id]="childNode.id"
         [attr.data-element-type]="rowNode?.nodeType"
         [style.height]="getRowHeight(rowNode)" data-element-drop-type="before">
      <div class="pm-dropzone-highlight"></div>
    </div>
    <pm-process [context]="childNode" [size]="rowNode?.childSize" [isAuthor]="isAuthor" [isTemplateRoot]="isTemplateRoot"
                [dragDropActive]="dragDropActive" (activationChange)="activationChange.emit($event)"
                [class.pm-sibling-opened]="childNode.siblingOpened"
                [class.pm-not-interested]="!childNode.interested"
                (elementClick)="elementClick.emit($event)" (linkClick)="linkClick.emit($event)"
                (resized)="resized.emit($event)" (updated)="updated.emit($event)"></pm-process>
  </ng-container>
  <div [class.pm-dropzone-between]="canDrop" [class.pm-dropzone-between-dead]="!canDrop" [attr.data-element-id]="rowNode?.id"
       [attr.data-element-is-below]="true"
       [attr.data-element-type]="rowNode?.nodeType" [style.height]="getRowHeight(rowNode)"
       data-element-drop-type="under">
    <div class="pm-dropzone-highlight"></div>
  </div>
</div>
