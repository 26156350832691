import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {PointerMoveListenerService} from "../../../shared/services/pointer-move-listener.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'pm-menu-trigger-overlay',
  templateUrl: './menu-trigger-overlay.component.html',
  styleUrls: ['./menu-trigger-overlay.component.css']
})
export class MenuTriggerOverlayComponent implements OnInit, OnDestroy {
  private _isTouch = false;

  @HostBinding('class.pm-touch')
  get isTouch() {
    return this._isTouch;
  }

  constructor(private pointerMoveListener: PointerMoveListenerService) {
  }

  ngOnDestroy(): void {
    this.touchSubscription.unsubscribe();
  }

  private touchSubscription: Subscription;

  ngOnInit(): void {
    this.touchSubscription = this.pointerMoveListener.isTouch$.subscribe(isTouch => this._isTouch = isTouch);
  }
}
