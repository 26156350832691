import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  TemplateDialogData,
  TemplateLanguageDialogComponent
} from '../../main/copy-template-dialog/template-language-dialog.component';
import {TemplateId} from '../model/template-id';
import {LanguageMap, TemplateLanguageMappingService} from '../services/template-language-mapping.service';
import {TemplatesService} from '../services/templates.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageMappingGuard  {
  constructor(private templateService: TemplatesService,
    private templateLanguageMapping: TemplateLanguageMappingService, private dialog: MatDialog) {
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const templateId: TemplateId = {
      templateId: route.params['templateName'],
      templateVersion: route.params['templateVersion'],
    };

    if (!this.templateLanguageMapping.getMapping()) {
      return this.dialog.open<TemplateLanguageDialogComponent, TemplateDialogData, LanguageMap|null>(TemplateLanguageDialogComponent, {
        data: {
          node: templateId,
          type: 'missing'
        }
      }).afterClosed().pipe(map(languageMap => !!languageMap))
    } else {
      return of(true);
    }
  }
}
