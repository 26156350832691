<form (ngSubmit)="onSubmit()" [formGroup]="passwordForm">
    <!--suppress HtmlFormInputWithoutLabel -->
    <input type="text" name="username" [value]="username" autocomplete="username" class="pm_user_invisible"/>
    <mat-form-field *ngIf="needsCurrentPassword">
      <input autocomplete="current-password" matInput id="pm_current_password"
             placeholder="{{'dialog.password-change.current-password' | translate}}"
             type="password" formControlName="currentPassword" required/>
      <mat-error *ngIf="passwordForm.hasError('required', ['currentPassword'])">
        {{'dialog.password-change.current-password.required' | translate}}
      </mat-error>
      <mat-error *ngIf="passwordForm.hasError('wrongPassword', ['currentPassword'])">
        {{'dialog.password-change.current-password.wrong' | translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input autocomplete="new-password" matInput id="pm_new_password" minlength="6"
             placeholder="{{'dialog.password-change.new-password' | translate}}"
             type="password" formControlName="newPassword" required/>
      <mat-error *ngIf="passwordForm.hasError('required', ['newPassword'])">
        {{'dialog.password-change.new-password.required' | translate}}
      </mat-error>
      <mat-error *ngIf="passwordForm.hasError('minlength', ['newPassword'])">
        {{'dialog.password-change.new-password.too-short' | translate:tooShortParam}}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input autocomplete="new-password" matInput id="pm_repeat_password"
             [errorStateMatcher]="parentMatch" placeholder="{{'dialog.password-change.repeat-password' | translate}}"
             type="password" formControlName="repeatPassword" required/>
      <mat-error *ngIf="passwordForm.hasError('passwordsDiffer')">
        {{'dialog.password-change.repeat-password.mismatch' | translate}}
      </mat-error>
    </mat-form-field>
    <button #submitButton style="display: none" type="submit"></button>
</form>
