import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'pm-foldable-dialog-title',
  templateUrl: './foldable-dialog-title.component.html',
  styleUrls: ['./foldable-dialog-title.component.css']
})
export class FoldableDialogTitleComponent implements OnInit {
  @Input() folded = false;
  @Output() foldedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  toggleFold() {
    this.folded = !this.folded;
    this.foldedChange.emit(this.folded);
  }

  ngOnInit(): void {
  }

}
