import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Language} from '@process-manager/pm-library';

export interface LanguageChange {
  newLanguage: Language;
  oldLanguage: Language;
}

@Component({
  selector: 'pm-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('scaleInOut', [
    transition(':enter', [
      style({ opacity: 0, transform: 'scaleX(0)', width: 0 }),
      animate('100ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 1, transform: 'scaleX(1)', width: '40px' })),
    ]),
    transition(':leave', [
      animate('100ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 0, transform: 'scaleX(0)', width: 0 })),
    ]),
  ])]
})
export class LanguageSelectorComponent {
  @Input() languages: Language[];
  @Input() currentLanguage: Language;
  @Input() isTemplateTree = false;
  @Output() languageChange: EventEmitter<LanguageChange> = new EventEmitter();

  get languageName(): string {
    return this.currentLanguage?.name || '';
  }

  get isDefault(): boolean {
    return this.currentLanguage?.isDefault || false;
  }

  languageIsMapped(language: Language): boolean {
    return !this.isTemplateTree || !!language.mappedLang;
  }

  selectLanguage(language: Language): void {
    if (this.languageIsMapped(language)) {
      this.languageChange.emit({
        newLanguage: language,
        oldLanguage: this.currentLanguage
      });
    }
  }
}
