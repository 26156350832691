import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {v1} from 'uuid';

import {Link} from '../../../../shared/model/link';
import {RenderedBullet} from '../../../../shared/model/rendered/rendered-bullet';
import {RenderedElement} from '../../../../shared/model/rendered/rendered-element';
import {Shape} from '../../../../shared/model/shape';
import {
  defaultButtonsWithLinking,
  defaultTrumbowygOptions,
  JQueryTrumbowyg,
  TrumbowygDirective
} from '../../../../shared/trumbowyg.directive';
import {ActivationType} from '../../../../state-management/reducers/tree.reducer';
import {PointerMoveListenerService} from "../../../../shared/services/pointer-move-listener.service";
import {InteractService} from "../../../../shared/services/interact.service";

@Component({
  selector: 'pm-bullet',
  templateUrl: './bullet.component.html',
  styleUrls: ['./bullet.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulletComponent implements OnInit, OnChanges, OnDestroy {
  @Input() context: RenderedBullet;
  @Input() isAuthor: boolean;
  @Output() activationChange = new EventEmitter<{ element: RenderedElement, type: ActivationType}>();
  @Output() updated = new EventEmitter<{ element: RenderedElement, content: ActivationType }>();
  @Output() linkClick = new EventEmitter<{ element: RenderedElement, link: Link|string}>();
  @Output() elementClick = new EventEmitter<RenderedElement>();

  @ViewChild(TrumbowygDirective, {static: true}) trumbowyg: TrumbowygDirective;

  readonly trumbowygOptions: JQueryTrumbowyg.Options = {...defaultTrumbowygOptions,
    btns: defaultButtonsWithLinking()
  };

  constructor(public elementRef: ElementRef, private pointerMoveListener: PointerMoveListenerService,
              private interactService: InteractService) {
  }

  @HostBinding('id') get processId() {
    return this.context && this.context.id || v1();
  }

  @HostBinding('class.active') get isActive(): boolean {
    return !!this.context.activation && this.context.activation !== 'highlight';
  }

  @HostBinding('class.pm-style-edit') get isEditingStyle(): boolean {
    return this.context.activation === 'styleEdit';
  }

  @HostBinding('class.pm-text-edit') get isEditingText(): boolean {
    return this.context.activation === 'textEdit';
  }

  @HostBinding('class.hidden-bullet') get isBulletHidden(): boolean {
    return this.context && this.context.style && this.context.style.shape === Shape.NONE || false;
  }

  get nativeElement(): any {
    return this.elementRef.nativeElement;
  }

  @HostListener('touchend',  ['$event']) onTouchEnd($event:PointerEvent) {
    if (this.interactService.isDragging() && $event.preventDefault) {
      $event.preventDefault();
    }
  }

  @HostListener('contextmenu', ['$event']) contextMenu($event) {
    if(!this.pointerMoveListener.isTouch) {
      this.toggleMenu($event);
    }
  }

  toggleMenu($event) {
    if (!this.isEditingText) {
      this.prevent($event);
      this.toggleActive();
    }
  }

  @HostListener('click', ['$event']) bulletClicked($event) {
    this.prevent($event);
    this.elementClick.emit(this.context);
  }

  prevent($event) {
    if ($event.preventDefault) {
      $event.preventDefault();
    }
    if ($event.stopPropagation) {
      $event.stopPropagation();
    }
  }

  toggleActive() {
    if (!this.isActive) {
      if (this.context.activation !== 'textEdit') {
        this.activationChange.emit({element: this.context, type: 'active'});
      } else {
      }
    } else {
      this.updateElement();
      this.activationChange.emit({element: this.context, type: null});
    }
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const bulletChange = changes['context'];
    if (!!bulletChange && !bulletChange.firstChange && bulletChange.previousValue.activation === 'textEdit' &&
      bulletChange.currentValue.activation !== 'textEdit') {
      this.updateElement();
    }
    if (bulletChange?.previousValue?.activation !== 'highlight' && bulletChange?.currentValue?.activation === 'highlight') {
      const target = this.elementRef.nativeElement;
      if (!!target.scrollIntoView) {
        target.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth'
        });
      }
    }
  }

  ngOnDestroy() {
  }

  toggleEdit(edit: boolean) {
    this.activationChange.emit({element: this.context, type: edit ? 'textEdit' : 'active'});
  }

  linkClicked(link: Link|string) {
    this.linkClick.emit({element: this.context, link: link});
  }

  hasLabels(): boolean {
    return this.context.labels?.length > 0;
  }

  hasLinks(): boolean {
    return !!this.context.info || !!this.context.extendedInfo || this.context.links
      .filter(link => link.adopted === false && link.linkType !== 'watermark' && link.linkType !== 'logo').length > 0;
  }
  private updateElement() {
    const html = this.trumbowyg.html;
    if (!!html && this.context.label !== html) {
      // Stupid workaround
      window.setTimeout(() => {
        this.updated.emit({
          element: this.context,
          content: html
        })
      }, 0);
    }
  }
}
