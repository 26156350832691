import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {hasMessage} from './has.message';

@Component({
  selector: 'pm-domain-form',
  templateUrl: './domain-form.component.html',
  styleUrls: ['./domain-form.component.css']
})
export class DomainFormComponent implements OnInit {
  @Input() pending: boolean;
  @Input() error: Error | string;

  domainValue = '';
  errorLabel = '';

  @Output() domain = new EventEmitter<string>();

  constructor(private translateService: TranslateService) {
  }

  get errorMessage(): string {
    if (this.error instanceof HttpErrorResponse && !this.error.status) {
      return this.errorLabel;
    } else if (hasMessage(this.error)) {
      return this.error.message;
    } else {
      return this.error;
    }
  }

  ngOnInit() {
    this.translateService.get('login.error.unknown').subscribe(label => this.errorLabel = label);
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      this.domain.emit(form.value.domain.toLowerCase());
    }
  }
}
