import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Store} from '@ngrx/store';
import {UserService} from '@process-manager/pm-library';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {AppConfig} from '../../../app.config';
import {NodeType} from '../../../shared/model/treeelement';
import {NavigationService} from '../../../shared/services/navigation.service';
import {ActivateElement} from '../../../state-management/actions/tree.actions';
import {AppState, getAuthUserIsPublic} from '../../../state-management/reducers';

interface SearchElement {
  label: string;
  id: number;
  type: NodeType;
  pos: number;
  path: string;
  parent: number;
  parentPage: number;
}

interface DSPermission {
  writePermission: boolean
}

interface DSGroup extends DSPermission {
  groupID: number,
  groupName: string,
  groupExternalID: string
}

interface DSUser extends DSPermission {
  userID: number,
  userName: string,
  userFirstName: string,
  userLastName: string
}

interface DataSourceElement extends SearchElement {
  extraData: {
    groupPerms: [DSGroup],
    userPerms: [DSUser]
  }
}

@Component({
  selector: 'pm-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.css']
})
export class PermissionListComponent implements OnInit, OnDestroy {
  folded = false;
  searchResultsSubscription: Subscription;
  loading = true;
  displayedColumns = ['label', 'path', 'users', 'groups'];
  dataSource = new MatTableDataSource<DataSourceElement>();
  activeSearchElement: any = null;

  isPublic: boolean;
  isPublicSubscription = this.store$.select(getAuthUserIsPublic).subscribe(isPublic => this.isPublic = isPublic);

  constructor(private userService: UserService, private appConfig: AppConfig, private http: HttpClient,
              private navigationService: NavigationService, private store$: Store<AppState>) {
  }

  ngOnInit() {
    const url = environment.api + this.userService.domain + '/permissions';
    const compareFn = (a, b) => (a?.label || '').localeCompare(b?.label || '', undefined, {numeric: true});
    this.searchResultsSubscription =
      this.http.get<DataSourceElement[]>(url).pipe(
        finalize(() => this.loading = false)).subscribe(result => {
          this.dataSource.data = result;
      });
  }

  ngOnDestroy() {
    this.searchResultsSubscription.unsubscribe();
    this.isPublicSubscription.unsubscribe();
  }

  navigateToNode(searchElement: DataSourceElement) {
    this.activeSearchElement = searchElement.id;
    this.appConfig.highlightNext = true;
    if(['page','mainpage'].includes(searchElement.type)) {
      this.navigationService.navigateToNode(searchElement.parent, this.isPublic).then(() => this.store$.dispatch(new ActivateElement({ id: searchElement.id.toString(10), activationType: 'highlight' })))
    } else {
      this.navigationService.navigateToNode(searchElement.id, this.isPublic);
    }
  }

  fullname(user: DSUser) {
    return [user.userFirstName, user.userLastName].join(' ').trim();
  }
}
