export function isLink(candidate: Link | string): candidate is Link {
  const link = (<Link>candidate);
  return link && !!link.linkType;
}

export interface Link {
  id: string;
  serverId?: number;
  label: string;
  action: string;
  color?: string;
  linkType: string;
  defLink?: boolean;
  anyTranslated?: string;
  adopted?: boolean;
  settings?: {[key: string]: string};
}

export function newLink(id: string, serverId: number, label: string, action: string, linkType: string, adopted: boolean,
                        color?: string): Link {
  return {
    id,
    serverId,
    label,
    action,
    linkType,
    adopted,
    color
  }
}
