<ng-container *ngFor="let bullet of bullets; trackBy: getBulletId">
  <div [class.pm-dropzone-bullet]="dragDropActive" [attr.data-element-id]="bullet.id" data-element-type="process"
       data-element-drop-type="after">
    <pm-bullet [context]="bullet" [isAuthor]="isAuthor" data-element-type="bullet" [attr.data-element-id]="bullet.id"
               [attr.data-element-template-id]="bullet.templateId" [attr.data-template-root]="isTemplateRoot"
               [attr.data-element-template-version]="bullet.templateVersion"
               [class.pm-not-interested]="!bullet.interested"
               (activationChange)="activationChange.emit($event)" (elementClick)="elementClick.emit($event)"
               (linkClick)="linkClick.emit($event)" (updated)="updated.emit($event)"
               (pmClickedOutside)="activationChange.emit({element: bullet, type: null})" [clickedOutsideActive]="bulletIsActive(bullet)"></pm-bullet>
    <div class="pm-dropzone-wrapper">
      <div class="pm-dropzone-highlight"></div>
    </div>
  </div>
</ng-container>
