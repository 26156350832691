import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder, ValidationErrors, ValidatorFn} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Language, UserService} from '@process-manager/pm-library';
import {Observable, Subscription} from 'rxjs';
import {AutoTranslationLanguage, LanguageService} from '../../shared/services/language.service';
import {UpdateSettings} from '../../state-management/actions/auth.actions';
import {AppState} from '../../state-management/reducers';

import {
  ConfirmDialogComponent,
  ConfirmDialogOptions,
  ConfirmDialogResult
} from '../confirm-navigation/confirm-dialog.component';
import {
  AutoTranslateDictionaryDialogComponent
} from './auto-translate-dictionary-dialog/auto-translate-dictionary-dialog.component';

@Component({
  selector: 'pm-language-admin-dialog',
  templateUrl: './language-admin-dialog.component.html',
  styleUrls: ['./language-admin-dialog.component.css']
})
export class LanguageAdminDialogComponent implements OnInit, OnDestroy {
  readonly NEW_LANGUAGE_SELECT = '__new_language__';

  languages: Language[] = [];
  autoLanguages: AutoTranslationLanguage[] = [];

  selectedLanguage = this.NEW_LANGUAGE_SELECT;


  readonly uniqueLangValidator: ValidatorFn = (control): ValidationErrors => {
    if (!control.value) {
      return null;
    }
    for (const lang of this.languages) {
      if (lang.autoTranslateLanguage === control.value && lang.id !== control.parent.controls['id'].value) {
        return {'notUnique': {value: control.value, takenBy: lang}}
      }
    }
  };

  languageForm = this.fb.group({
    id: -1,
    name: '',
    interfaceLanguage: '',
    isDefault: false,
    autoTranslateLanguage: [null, {disabled: true, validators: [this.uniqueLangValidator] } ]
  });

  get autoControl() { return this.languageForm.get('autoTranslateLanguage'); }

  triggerLabel: string;
  private nameSubscription: Subscription;

  get dictionaryRelevant(): boolean {
    const autoTranslated = this.languages.filter(lang => !!lang.autoTranslateLanguage);
    return autoTranslated.length > 1 && !!autoTranslated.find(lang => lang.isDefault)
  }

  constructor(private languageService: LanguageService, private userService: UserService,
              private store$: Store<AppState>, private fb: UntypedFormBuilder, private translation: TranslateService,
              @Optional() private dialogRef: MatDialogRef<LanguageAdminDialogComponent>, private matDialog: MatDialog) {
  }

  get overriddenTriggerLabel(): string {
    return this.triggerLabel || this.translation.instant('dialog.language-admin.new-language');
  }

  get defaultLanguageAutoTranslationDisabled(): boolean {
    return !this.languages.find(lang => lang.isDefault)?.autoTranslateLanguage;
  }

  get automaticLanguage(): string {
    return `dialog.language-admin.auto-language${this.languageForm.value.isDefault && '.default' || ''}`;
  }

  get automaticLanguageNone(): string {
    return `dialog.language-admin.auto-none${this.languageForm.value.isDefault && '.default' || ''}`;
  }

  get automaticLanguageHint(): string {
    const key = 'dialog.language-admin.auto-language.hint';
    if (this.languageForm.value.isDefault) {
      return `${key}.default`;
    } else if (this.defaultLanguageAutoTranslationDisabled) {
      return `${key}.disabled`;
    } else {
      return key;
    }
  }

  get disabled(): boolean {
    return this.languageForm.value.isDefault || this.selectedLanguage === this.NEW_LANGUAGE_SELECT;
  }

  public filterAutoLanguages(asSource: boolean) {
    if (asSource) {
      return this.autoLanguages?.filter(lang => lang.supportSource = true);
    } else {
      return this.autoLanguages?.filter(lang => lang.supportTarget = true);
    }
  }

  ngOnInit() {
    this.languageService.getLanguages().subscribe(languages => {
      this.languages = languages;
      this.updateDisabledAutotranslate();
    });
    this.languageService.getAvailableAutoTranslationLanguages()
      .subscribe(autoLanguages => this.autoLanguages = autoLanguages);
    this.nameSubscription = this.languageForm.get('name').valueChanges.subscribe(change => this.triggerLabel = change);
  }

  onSubmit() {
    let observable: Observable<void>;

    if (this.selectedLanguage === this.NEW_LANGUAGE_SELECT) {
      observable = this.languageService.createLanguage(this.languageForm.value);
    } else {
      observable = this.languageService.updateLanguage(this.languageForm.value, this.selectedLanguage);
    }

    observable.subscribe(() => {
      if (!!this.dialogRef) {
        this.dialogRef.close()
      }
      this.reloadSettings();
    });
  }

  onDelete() {
    this.matDialog.open<ConfirmDialogComponent, ConfirmDialogOptions, ConfirmDialogResult>(ConfirmDialogComponent, {
      data: {
        title: 'dialog.language-admin.delete.confirm.title',
        body: 'dialog.language-admin.delete.confirm.body',
        bodyTranslationArguments: {
          language: this.selectedLanguage
        },
        hideSaveButton: true,
        continueButton: 'button.delete'
      }
    }).afterClosed().subscribe((result) => {
      if (result === 'continue') {
        this.languageService.deleteLanguage(this.selectedLanguage).subscribe(() => {
          this.languages = this.languages.filter(language => this.selectedLanguage !== language.name);
          this.languageForm.reset();
          this.selectedLanguage = this.NEW_LANGUAGE_SELECT;
          this.reloadSettings();
        });
      }
    });
  }

  languageSelectionChange($event: MatSelectChange) {
    if ($event.value === this.NEW_LANGUAGE_SELECT) {
      this.languageForm.reset();
    } else {
      this.languageForm.reset({...this.languages.find(lang => lang.name === $event.value)});
    }
    this.updateDisabledAutotranslate();
  }

  ngOnDestroy(): void {
    if (!!this.nameSubscription) {
      this.nameSubscription?.unsubscribe();
    }
  }

  private updateDisabledAutotranslate() {
    if (!this.languageForm.get('isDefault').value && this.defaultLanguageAutoTranslationDisabled) {
      this.languageForm.get('autoTranslateLanguage').disable();
    } else {
      this.languageForm.get('autoTranslateLanguage').enable();
    }
  }

  private reloadSettings() {
    this.userService.reloadSettings()
      .subscribe(settings => {
        this.store$.dispatch(new UpdateSettings({settings}));
      });
  }

  showDictionary() {
    this.matDialog.open(AutoTranslateDictionaryDialogComponent, {
      data: this.languages
    });
  }
}
