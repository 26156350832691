<span mat-dialog-title>{{'dialog.error.title' | translate}}</span>
<mat-dialog-content class="mat-typography">
  <div>{{'dialog.error.summary' | translate}}</div>
  <div><h4>{{'dialog.error.type' | translate}}</h4></div>
  <div>{{errorActionType}}</div>
  <div><h4>{{'dialog.error.name' | translate}}</h4></div>
  <div>{{error.name}}</div>
  <div><h4>{{'dialog.error.message' | translate}}</h4></div>
  <div>{{error.message}}</div>
  <ng-container *ngIf="showDebug">
    <ng-container *ngIf="!!error.stack">
      <div><h4>{{'dialog.error.stack' | translate}}</h4></div>
      <div class="pm-frame">
        <pre>{{error.stack}}</pre>
      </div>
    </ng-container>
    <div><h4>{{'dialog.error.action' | translate}}</h4></div>
    <div class="pm-frame">
      <pre>{{action | json}}</pre>
    </div>
    <ng-container *ngIf="(hasSaveHistory$ | async)">
      <h4>{{'dialog.error.save-history' | translate}}</h4>
      <div class="pm-frame">
        <pre *ngFor="let action of (prettySaveHistory$ | async)">{{action}}</pre>
      </div>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>{{'button.close' | translate}}</button>
  <button (click)="sendError()" mat-raised-button [disabled]="reported || reporting">
    <span class="pm-inliner"><mat-spinner
      diameter="16" *ngIf="reporting"></mat-spinner>
      <ng-container *ngIf="!reported">{{'dialog.error.report' | translate}}</ng-container>
      <ng-container *ngIf="reported">{{'dialog.error.reported' | translate}}</ng-container>
    </span>
  </button>
</mat-dialog-actions>
