import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

@Injectable({
  providedIn: 'root'
})
export class DateTranslateService {
  readonly options: DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  constructor(private translate: TranslateService) {
  }

  transform(date: Date, args?: any): any {
    if (!date) {
      date = new Date();
    }
    const locale = this.translate?.currentLang?.replace('_', '-') || undefined;
    return date.toLocaleString(locale, this.options);
  }
}
