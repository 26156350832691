<!--suppress RequiredAttributes -->
<ng-container [ngSwitch]="shape">
  <svg:svg xmlns:svg="http://www.w3.org/2000/svg">
    <svg:defs xmlns="http://www.w3.org/2000/svg">
      <filter id="outline_selected" x="-20%" y="-20%" width="140%" height="140%">
        <feFlood flood-color="black" result="base"></feFlood>
        <feMorphology result="bigger" in="SourceGraphic" operator="dilate" radius="2"></feMorphology>
        <feColorMatrix result="mask" in="bigger" type="matrix"
                       values="0 0 0 0 0
              0 0 0 0 0
              0 0 0 0 0
              0 0 0 1 0"></feColorMatrix>
        <feComposite result="drop" in="base" in2="mask" operator="in"></feComposite>
        <feGaussianBlur id="filter_blur" result="blur" in="drop" stdDeviation="5"></feGaussianBlur>
        <feBlend in="SourceGraphic" in2="blur" mode="normal"></feBlend>
      </filter>
      <animate xlink:href="#filter_blur" id="anim-blur" attributeName="stdDeviation" values="0;5;0" dur="1s"
               repeatCount="indefinite"></animate>
    </svg:defs>
    <g [attr.filter]="highlightFilter" [attr.fill]="bgColor" [attr.stroke]="lineColor" transform="translate(0.5,0.5)">
      <rect width="100%" height="100%" *ngSwitchCase="'rectangle'"></rect>
      <rect width="100%" height="100%" rx="25" ry="25" *ngSwitchCase="'ellipse'"></rect>
      <polygon [attr.points]="rightArrowPoints" *ngSwitchCase="'rightarrow'"></polygon>
      <polygon [attr.points]="leftArrowPoints" *ngSwitchCase="'leftarrow'"></polygon>
      <polygon [attr.points]="upArrowPoints" *ngSwitchCase="'uparrow'"></polygon>
      <polygon [attr.points]="downArrowPoints" *ngSwitchCase="'downarrow'"></polygon>
    </g>
  </svg:svg>
</ng-container>
