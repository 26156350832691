import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {UserService, XsrfTokenReader} from '@process-manager/pm-library';
import {User} from '@process-manager/pm-library/lib/model/user';
import {liveQuery} from 'dexie';
import {from, Observable, of} from 'rxjs';
import {map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {DomainChosen, LoginFailure, LoginRedirect, LoginSuccess} from '../../state-management/actions/auth.actions';
import {AppState, getAuthLoggedIn, getTreeSource} from '../../state-management/reducers';
import {db} from '../components/db';
import {OfflineTreeService} from '../services/offline-tree.service';

@Injectable()
export class AuthGuard implements CanActivateChild {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private store$: Store<AppState>,
    private userService: UserService, private xsrfTokenReader: XsrfTokenReader,
    private offlineTreeService: OfflineTreeService) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store$.select(getAuthLoggedIn).pipe(withLatestFrom(this.store$.select(getTreeSource)),
      mergeMap(([isLoggedIn, treeSource]): Observable<boolean> => {
      const domain = route.params['domain'] && route.params['domain'].toLowerCase();
      const currentUser = this.userService.user;
      const hasXsrfCookie = !!this.xsrfTokenReader.getToken(domain);

      if(!window.navigator.onLine) {
        if(treeSource === 'offline' || !route.params['nodeid']) {
          return of(true);
        }

        return this.offlineTreeService.offlineDialog(domain).pipe(mergeMap(result => {
          if(result === 'keep-trying') {
            return this.checkLoginState(isLoggedIn, hasXsrfCookie, domain, route, currentUser, state);
          } else {
            if(result === 'offline') {
              return from(liveQuery(() => db.sites.get(domain))).pipe(map(siteDetails => {
                this.userService.offlineLogin(siteDetails.user, siteDetails.settings);

                this.store$.dispatch(new LoginSuccess({
                  user: siteDetails.user || currentUser,
                  siteSettings: siteDetails.settings,
                  returnUrl: '',
                  redirect: false,
                  passwordIsTemporary: false,
                  domain: domain,
                  isOnline: false
                }));
                return true;
              }));
            } else {
              return of(false);
            }
          }
        }));
      }

      return this.checkLoginState(isLoggedIn, hasXsrfCookie, domain, route, currentUser, state);
    }));
  }

  private checkLoginState(isLoggedIn: boolean, hasXsrfCookie: boolean, domain: string, route: ActivatedRouteSnapshot,
    currentUser: User, state: RouterStateSnapshot): Observable<boolean> {
    if (isLoggedIn && hasXsrfCookie) {
      return of(true);
    } else {
      if (hasXsrfCookie && !!this.userService.getSiteSettings() && this.userService.domain === domain) {
        const publicUserMatchesUsername = route.queryParams['user'] === currentUser.username;
        if (!!route.queryParams['user'] && !publicUserMatchesUsername) {
          const {
            user,
            ...strippedQueryParams
          } = route.queryParams;
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: strippedQueryParams,
            replaceUrl: true
          });
        }
        this.store$.dispatch(new LoginSuccess({
          user: currentUser,
          siteSettings: this.userService.getSiteSettings(),
          returnUrl: '',
          isOnline: true,
          redirect: false,
          passwordIsTemporary: !!localStorage.getItem(UserService.CHANGE_PASSWORD_NAME),
          domain: domain
        }));
        return of(true);
      }

      return this.userService.checkAndUpdateLoginState(domain).pipe(map(success => {
        if (success && !!this.userService.user) {
          this.store$.dispatch(new LoginSuccess({
            user: this.userService.user,
            siteSettings: this.userService.getSiteSettings(),
            returnUrl: '',
            isOnline: true,
            redirect: false,
            passwordIsTemporary: false,
            domain: domain
          }));
          return true;
        } else {
          if (route.queryParamMap.get('type') === 'redirected') {
            this.store$.dispatch(new LoginFailure({
              domain: domain,
              publicUser: route.queryParams['user'],
              error: new Error(route.queryParamMap.get('error') || ''),
              errorDetails: route.queryParamMap.get('errorDetails') || ''
            }));
          } else {
            const forceLocal = route.queryParamMap.get('type') === 'local' || !!route.queryParamMap.get('user');
            if (!forceLocal && !!domain) {
              this.store$.dispatch(new DomainChosen({
                domain: domain,
                returnUrl: state.url.split('?')[0]
              }));
            } else {
              this.store$.dispatch(new LoginRedirect({
                returnUrl: state.url && state.url.includes(`domains/${domain}/trees`) && state.url.split('?')[0] || '',
                domain: domain,
                local: forceLocal,
                publicUser: route.queryParams['user']
              }));
            }
          }
          return false;
        }
      }));
    }
  }
}
