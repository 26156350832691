import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';
import {Observable, of, Subscription} from 'rxjs';
import {RenderedElement} from '../../../shared/model/rendered/rendered-element';
import {isRenderedProcess} from '../../../shared/model/rendered/rendered-process';
import {Permissions} from '../../../shared/model/treeelement';
import {UpdateTreeElementPermissions} from '../../../state-management/actions/tree.actions';
import {AppState, getTreeActiveInheritedDetails} from '../../../state-management/reducers';
import {InheritedDetails} from '../../../state-management/reducers/tree.reducer';

interface GroupPermission {
  readonly id: number;
  readonly name: string;
  readonly group: boolean;
}

const isEqual = (a: GroupPermission,b: GroupPermission) => {
  return a?.id === b?.id && a?.group === b?.group;
}

interface UserPermission extends GroupPermission {
  readonly fullName: string;
}

@Component({
  selector: 'pm-extra-options-dialog',
  templateUrl: './extra-options-dialog.component.html',
  styleUrls: ['./extra-options-dialog.component.css']
})
export class ExtraOptionsDialogComponent implements OnInit, OnDestroy {
  ownerControl = new FormControl();
  viewerControl = new FormControl([]);
  editorControl = new FormControl([]);
  inherit = false;

  readGroups: GroupPermission[] = [];
  writeGroups: GroupPermission[] = [];

  readUsers: UserPermission[] = [];
  writeUsers: UserPermission[] = [];

  inheritedReadPermissions: GroupPermission[] = [];
  inheritedWritePermissions: GroupPermission[] = [];

  currentReadPermissions: GroupPermission[] = [];
  currentWritePermissions: GroupPermission[] = [];

  private inheritedDetails: InheritedDetails;
  private inheritedDetailsSubscription = this.store$.select(getTreeActiveInheritedDetails).subscribe((details) => {
    this.inheritedDetails = details;
  });

  private usersAndGroupsSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<ExtraOptionsDialogComponent>, private userService: UserService,
              private store$: Store<AppState>, @Inject(MAT_DIALOG_DATA) public context: RenderedElement,
              private translateService: TranslateService) {
  }

  get hasOwnerOrParent(): boolean {
    return !!this.ownerControl.value || !!this.inheritedDetails.owner;
  }

  get parentOwner(): string {
    if (!!this.inheritedDetails.owner) {
      return this.translateService.instant('process.owner.inherited', {owner: this.inheritedDetails.owner})
    } else {
      return '';
    }
  }

  get readerGroupCount(): number {
    return this.getCount(true, true);
  }

  get readerUserCount(): number {
    return this.getCount(true, false);
  }

  get writerGroupCount(): number {
    return this.getCount(false, true);
  }

  get writerUserCount(): number {
    return this.getCount(false, false);
  }

  get viewerSelectTrigger(): Observable<string> {
    return this.getSelectTrigger(this.readerGroupCount, this.readerUserCount);
  }

  get editorSelectTrigger(): Observable<string> {
    return this.getSelectTrigger(this.writerGroupCount, this.writerUserCount);
  }

  getSelectTrigger(groups: number, users: number): Observable<string> {
    if (groups > 0 && users > 0) {
      return this.translateService.get('dialog.extra-options.permissions.group-and-user-count', {
        groups: groups,
        users: users
      });
    } else if (groups > 0) {
      return this.translateService.get('dialog.extra-options.permissions.group-count', {groups: groups});
    } else if (users > 0) {
      return this.translateService.get('dialog.extra-options.permissions.user-count', {users: users});
    } else {
      return of('');
    }
  }

  ngOnInit() {
    this.usersAndGroupsSubscription = this.userService.getUsersAndGroups().subscribe(usersAndGroups => {
      for (const group of usersAndGroups.groups || []) {
        const item = {
          ...group,
          group: true,
        };

        this.readGroups.push(item);
        this.writeGroups.push(item);
      }

      for (const user of usersAndGroups.users) {
        const item = {
          id: user.id,
          name: user.username,
          fullName: [user.firstName, user.lastName].join(' ').trim(),
          group: false
        };
        this.readUsers.push(item);

        if (user.roles.indexOf('edit') > -1 || user.roles.indexOf('admin') > -1) {
          this.writeUsers.push(item);
        }
      }

      this.inherit = this.context.permissionsInherited;
      this.ownerControl.setValue(!this.context.ownerInherited && this.context.owner || null)
      this.readPermissions();
      this.doUpdateControls(this.inherit);
    });
  }

  ngOnDestroy() {
    this.usersAndGroupsSubscription.unsubscribe();
    this.inheritedDetailsSubscription.unsubscribe();
  }

  getCount(reader: boolean, group: boolean): number {
    const selected: GroupPermission[] = reader ? this.viewerControl.value : this.editorControl.value;
    return selected.filter((value: GroupPermission) => value.group === group).length;
  }

  getFullName(user: UserPermission): string {
    return user.fullName;
  }

  onSubmit(): void {
    const selectedReadUsers = this.viewerControl.value as GroupPermission[];
    const selectedWriteUsers = this.editorControl.value as GroupPermission[];
    this.store$.dispatch(new UpdateTreeElementPermissions({
      id: this.context.id,
      owner: this.ownerControl.value,
      permissions: this.inherit ? null : selectedReadUsers.map(value => ({
        id: value.id,
        group: value.group,
        writeable: false
      })).concat(selectedWriteUsers.map(value => ({
        id: value.id,
        group: value.group,
        writeable: true
      })))
    }));
    this.dialogRef.close();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onReadersChanged(): void {
    const selectedReadUsers = this.viewerControl.value as GroupPermission[];
    const selectedWriteUsers = this.editorControl.value as GroupPermission[];
    for (const writeUser of selectedWriteUsers) {
      if (selectedReadUsers.indexOf(writeUser) === -1) {
        selectedWriteUsers.splice(selectedWriteUsers.indexOf(writeUser), 1);
      }
    }

    this.editorControl.setValue(selectedWriteUsers);
  }

  onWritersChanged(): void {
    const selectedReadUsers = this.viewerControl.value as GroupPermission[];
    const selectedWriteUsers = this.editorControl.value as GroupPermission[];
    for (const writeUser of selectedWriteUsers) {
      if (selectedReadUsers?.length >= 1 && selectedReadUsers.indexOf(writeUser) === -1 &&
        !selectedReadUsers.some(value => isEqual(value, writeUser))) {
        selectedReadUsers.push(writeUser);
      }
    }

    this.viewerControl.setValue(selectedReadUsers);
  }

  onInheritChange($event: MatCheckboxChange) {
    this.doUpdateControls($event.checked);
  }

  private doUpdateControls(inherit: boolean) {
    if (inherit) {
      this.currentReadPermissions = this.viewerControl.value;
      this.viewerControl.setValue(this.inheritedReadPermissions);

      this.currentWritePermissions = this.editorControl.value;
      this.editorControl.setValue(this.inheritedWritePermissions);
    } else {
      this.viewerControl.setValue(this.currentReadPermissions);
      this.editorControl.setValue(this.currentWritePermissions);
    }
  }

  get contextIsProcess(): boolean {
    return isRenderedProcess(this.context);
  }

  private readPermissions() {
    this.inheritedReadPermissions = this.getSelectedSubjects(this.inheritedDetails.permissions, false);
    this.inheritedWritePermissions = this.getSelectedSubjects(this.inheritedDetails.permissions, true);

    this.currentReadPermissions = this.getSelectedSubjects(this.context.permissions, false);
    this.currentWritePermissions = this.getSelectedSubjects(this.context.permissions, true);
  }

  private getSelectedSubjects(permissions: Permissions, write: boolean): GroupPermission[] {
    if (!permissions) {
      return [];
    }

    const candidates = write ? this.writeGroups.concat(this.writeUsers) : this.readGroups.concat(this.readUsers);

    const selectedSubjects: GroupPermission[] = [];
    if((write && !permissions.writeAll) ||( !write && !permissions.readAll)) {
      for (const subject of candidates) {
        for (const permission of permissions.subjects.filter(value => write ? value.writeable : true)) {
          if (subject.id === permission.id && subject.group === permission.group && !selectedSubjects.some(sub => isEqual(sub, subject))) {
            selectedSubjects.push(subject);
          }
        }
      }
    }
    return selectedSubjects;
  }
}
