import {Injectable} from '@angular/core';
import {Language, UserService} from '@process-manager/pm-library';

export interface LanguageMapEntry {
  id: number;
  name: string;
}

interface LanguageMapSettings {
  rememberMapping?: boolean;
  siteLanguages: LanguageMapEntry[];
}

export interface LanguageMap {
  [key: string]: LanguageMapEntry;
}

interface LanguageMapWithSettings {
  settings: LanguageMapSettings;
  map: LanguageMap;
}

@Injectable({
  providedIn: 'root'
})
export class TemplateLanguageMappingService {
  public static readonly TEMPLATE_LANGUAGE_MAPPING_POSTFIX = '_template_language_mapping';

  constructor(private userService: UserService) {
  }

  private get storageKey() {
    return this.userService.domain + TemplateLanguageMappingService.TEMPLATE_LANGUAGE_MAPPING_POSTFIX;
  }

  public getMapping(): LanguageMap {
    return this.getMapWithSettings()?.map;
  }

  public hasMapping(alsoSkipped: boolean = true) {
    const languageMap = this.getMapWithSettings();

    if (alsoSkipped) {
      return !!languageMap;
    } else {
      const skipMapping = languageMap?.settings?.rememberMapping || true;
      return !skipMapping;
    }
  }

  public storeMapping(mapping: LanguageMap, rememberMapping) {
    const siteSettings = this.userService.getSiteSettings();
    const mapWithSettings: LanguageMapWithSettings = {
      map: mapping,
      settings: {
        rememberMapping: rememberMapping,
        siteLanguages: Object.values(mapping).map<LanguageMapEntry>(
          entry => ({id: entry.id, name: siteSettings.languages.find(lang => lang.id === entry.id)?.name}))
      }
    };
    localStorage.setItem(this.storageKey, JSON.stringify(mapWithSettings));
  }

  public clearAllMappings() {
    localStorage.removeItem(this.storageKey);
  }

  public hasAnyMappings() {
    return !!localStorage.getItem(this.storageKey);
  }

  public getMappingIds(): {[key: string]: number} {
    const mapping = {};
    const oldMapping = this.getMapping();
    Object.keys(oldMapping).forEach(key => {
      mapping[key] = oldMapping[key].id;
    });
    return mapping;
  }

  public getTemplateLanguages(): {[key: number]: Language} {
    return {
      1: {
        id: 1,
        name: 'Dansk',
        interfaceLanguage: 'da_dk',
        autoTranslateLanguage: 'da_dk',
        isDefault: true
      },
      2: {
        id: 2,
        name: 'English',
        interfaceLanguage: 'en_gb',
        autoTranslateLanguage: 'en_gb',
        isDefault: false
      }
    }
  }

  private getMapWithSettings(): LanguageMapWithSettings {
    const storage = localStorage.getItem(this.storageKey);
    const mapWithSettings: LanguageMapWithSettings = storage && JSON.parse(storage);
    if (!!mapWithSettings && mapWithSettings.settings) {
      if (this.validateSiteLanguages(mapWithSettings.settings.siteLanguages)) {
        return mapWithSettings;
      } else {
        // Relevant site languages was changed. Clear everything.
        this.clearAllMappings();
      }
    }

    return null;
  }

  private validateSiteLanguages(siteLanguages: LanguageMapEntry[]): boolean {
    return !siteLanguages || siteLanguages.every(
      lang => this.userService.getSiteSettings().languages.find(siteLang => siteLang.id === lang.id)?.name ===
        lang.name);
  }
}

