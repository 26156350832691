import {ChangeDetectorRef, Component, HostListener, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {Store} from '@ngrx/store';
import {UserService} from '@process-manager/pm-library';
import * as Color from 'color';
import {map} from 'rxjs/operators';
import {Label} from '../../../shared/model/label';
import {RenderedElement} from '../../../shared/model/rendered/rendered-element';
import {isTemplateId} from '../../../shared/model/template-id';
import {ToggleLabelSize} from '../../../state-management/actions/view-options.actions';
import {AppState, getLabelsSettings, getSmallLabels, getTreeSource} from '../../../state-management/reducers';
import {LabelSettings} from '../../../state-management/reducers/view-options.reducer';

interface LabelWithInheritance extends Label {
  default?: boolean;
}

@Component({
  selector: 'pm-labels-overlay',
  templateUrl: './labels-overlay.component.html',
  styleUrls: ['./labels-overlay.component.css']
})
export class LabelsOverlayComponent implements OnDestroy, OnChanges {
  @Input() context: RenderedElement;
  smallLabels$ = this.store$.select(getSmallLabels);
  isTemplate = false;
  isTemplateSubscription = this.store$.select(getTreeSource).pipe(map(isTemplateId))
    .subscribe(isTemplate => {
      this.isTemplate = isTemplate;
      this.updateLabels();
    });

  labels: LabelWithInheritance[];
  private labelSettings: LabelSettings;
  private labelSettingsSubscription = this.store$.select(getLabelsSettings).subscribe(labelSettings => {
    this.labelSettings = labelSettings;
    this.updateLabels();
  });

  constructor(private store$: Store<AppState>, private userService: UserService, private cd: ChangeDetectorRef) {
  }

  get showLabels() {
    return this.labelSettings?.show;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const contextChanges = changes['context'];

    if (contextChanges.currentValue !== contextChanges.previousValue) {
      this.updateLabels();
    }
  }

  updateLabels(): void {
    setTimeout(() => {
      const labels: LabelWithInheritance[] = this.context?.labels || [];
      const filterDefault = (source: Label[]): LabelWithInheritance[] => [...source || []].filter(
        defaultLabel => !labels.filter(Boolean).find(label => label?.id === defaultLabel?.id)).map(label => ({
        ...label,
        default: true
      }));

      const ancestorLabels = filterDefault(this.context?.ancestorLabels);
      const descendentLabels = filterDefault(this.context?.descendentLabels);

      const settings = this.labelSettings;

      let seenLabels = [...labels];
      if (settings?.inherit) {
        seenLabels = seenLabels.concat(ancestorLabels);
      }

      if (settings?.descendents) {
        seenLabels = seenLabels.concat(descendentLabels);
      }
      this.labels = seenLabels;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.isTemplateSubscription.unsubscribe();
    this.labelSettingsSubscription.unsubscribe();
  }

  @HostListener('click') click() {
    setTimeout(() => this.store$.dispatch(new ToggleLabelSize()), 0);
  }

  isDark(label: Label) {
    return Color(label?.color || '#000').isDark();
  }

  dimLabel(label: LabelWithInheritance): boolean {
    return this.isTemplate && label.extension && !this.userService.getSiteSettings().licencedTemplates
      .some(licensedTemplate => licensedTemplate.toLowerCase() === label.name.toLowerCase());
  }
}
