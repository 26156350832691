import {Injectable} from '@angular/core';

interface LicenseData {
  project: string;
  licenses: string;
  repository: string;
  publisher: string;
  email: string;
  url: string;
  path: string;
  licenseFile: string;
}

const getProjectName = (name: string) => {
  const index = name.lastIndexOf('@');

  if (index !== 0) {
    return name.slice(0, index);
  }

  return name;
};

@Injectable({
  providedIn: 'root'
})
export class LicenseParserService {

  constructor() { }

  getLicenses(): LicenseData[] {
    let licenses = {};
    try {
      licenses = require('assets/licenses.json');
    } catch (err) {
      console.warn('Could not load licence file');
    }

    return Object.keys(licenses).filter(key => !licenses[key].private).map(key => {
      const {path, licenseFile, ...license} = licenses[key];
      return {
        ...license,
        project: getProjectName(key)
      }
    });
  }
}
