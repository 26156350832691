import {Size} from '../process';
import {TemplateId} from '../template-id';
import {RenderedBullet} from './rendered-bullet';
import {RenderedElement} from './rendered-element';

export function isRenderedProcess(element: RenderedElement): element is RenderedProcess {
  const process = (<RenderedProcess>element);
  return process?.nodeType && process?.nodeType !== 'bullet' || false;
}

export interface RenderedProcess extends RenderedElement {
  children: RenderedProcess[];
  bullets: RenderedBullet[];
  hasUnloadedChildren: boolean;
  siblingOpened: boolean;
  isOpen: boolean;
  needsSave: boolean;
  childSize: Size;
}

export interface RenderedTemplate extends RenderedProcess, TemplateId {
}
