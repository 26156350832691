import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserService} from '@process-manager/pm-library';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

export interface Entry {
  [key: string]: string;
}

export interface DictionaryResponse {
  dictionary: Entry[],
  warnings: string[]
}

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {
  private readonly jsonHeaderOptions = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

  loadDictionary(): Observable<DictionaryResponse> {
    return this.http.get<DictionaryResponse>(
      environment.api + this.userService.domain + '/dictionary/', this.jsonHeaderOptions);
  }

  saveDictionary(dictionary: Entry[]): Observable<void> {
    return this.http.post<void>(environment.api + this.userService.domain + '/dictionary/',
      dictionary, this.jsonHeaderOptions);
  }

  constructor(private http: HttpClient, private userService: UserService) { }
}
