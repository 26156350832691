import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
  }
)
export class AppConfig {
  private highlight = true;

  public get highlightNext(): boolean {
    return this.highlight;
  }

  public set highlightNext(highlight: boolean) {
    this.highlight = highlight;
  }
}
