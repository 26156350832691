import {ElementRef, Injectable, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DraggableOptions} from '@interactjs/actions/drag/plugin';
import {DropzoneOptions} from '@interactjs/actions/drop/plugin';
import {ResizableOptions} from '@interactjs/actions/resize/plugin';
import {InteractEvent} from '@interactjs/types';
import {select, Store} from '@ngrx/store';
import * as Immutable from 'immutable';
import interact from 'interactjs';
import {zip} from 'rxjs';
import {take} from 'rxjs/operators';
import {
  ConfirmDialogComponent, ConfirmDialogOptions, ConfirmDialogResult
} from '../../main/confirm-navigation/confirm-dialog.component';
import {
  TemplateDialogData, TemplateLanguageDialogComponent
} from '../../main/copy-template-dialog/template-language-dialog.component';
import {
  ActivateElement, AddOrMoveRelation, BackupElementData, DeactivateElement, MoveElementPayload, MoveTreeElement,
  SaveSteps
} from '../../state-management/actions/tree.actions';
import {
  AppState, getAuthIsAuthorOrAdmin, getTemplateNode, getTreeAnyNodeActive, getTreeLabels, getTreeLinks, getTreeNodes,
  getTreeRoot
} from '../../state-management/reducers';
import {findTemplateElement} from '../../state-management/reducers/tree.reducer';
import {Process, Size} from '../model/process';
import {TemplateIdWithMap} from '../model/template-id';
import {NodeType, TreeElement} from '../model/treeelement';
import {LanguageMap, TemplateLanguageMappingService} from './template-language-mapping.service';
import {ServerIds} from './tree.service';

export const DATA_NODE_ID = 'data-element-id';
export const DATA_NODE_IS_TEMPLATE_ROOT = 'data-template-root';
export const DATA_NODE_TEMPLATE_ID = 'data-element-template-id';
export const DATA_NODE_TEMPLATE_VERSION = 'data-element-template-version';
export const DATA_NODE_DROP_TYPE = 'data-element-drop-type';
export const DATA_NODE_TYPE = 'data-element-type';
export const DATA_NODE_IS_PASTING = 'data-element-is-pasting';
export const DATA_DROPPABLE = 'data-droppable';
export const DATA_POINTER_OVER = 'data-pointer-over';

interface DragItem {
  element: HTMLElement;
  isCopy: boolean;
  original: BackupElementData
}

function isProcess(element: TreeElement): element is Process {
  return (<Process>element).bullets !== undefined;
}

@Injectable({
  providedIn: 'root'
})
export class InteractService implements OnDestroy {
  private nodes: Immutable.Map<string, TreeElement>;
  private nodeCache: Immutable.Map<[string, string], boolean> = Immutable.Map();
  private currentDragItem: DragItem = null;
  private userCanEdit = false;
  private dragging = false;
  private anyNodeActive = false;
  private isMainPage: boolean;

  private mainPageSubscription = this.store$.pipe(select(getTreeRoot))
    .subscribe((root: Process) => this.isMainPage = root && root.type === 'mainpage');
  private userCanEditSubscription = this.store$.select(getAuthIsAuthorOrAdmin)
    .subscribe((isAuthorOrAdmin) => this.userCanEdit = isAuthorOrAdmin);
  private anyNodeActiveSubscription = this.store$.select(getTreeAnyNodeActive).subscribe((anyNodeActive) => this.anyNodeActive = anyNodeActive)
  private nodesSubscription = this.store$.select(getTreeNodes).subscribe((nodes) => {
    this.nodeCache = Immutable.Map();
    this.nodes = nodes;
  });
  private registered: Interact.Interactable[] = [];


  constructor(private store$: Store<AppState>, private dialog: MatDialog,
    private templateLanguageMapping: TemplateLanguageMappingService) {
  }

  updateCurrentDragItem(elementUpdates: ServerIds) {
    if (!!this.currentDragItem) {
      const updateWithNewId = <T extends { id: string }>(element: T, tempId: string, serverId: number) => {
        if (element.id === tempId) {
          return {
            ...element,
            id: String(serverId)
          };
        } else {
          return element;
        }
      }

      Object.keys(elementUpdates).forEach((tempId: string) => {
        const serverId = elementUpdates[tempId];
        if (this.currentDragItem.element.getAttribute(DATA_NODE_ID) === tempId) {
          this.currentDragItem.element.setAttribute(DATA_NODE_ID, String(serverId));
        }
        this.currentDragItem.original.treeElement =
          updateWithNewId(this.currentDragItem.original.treeElement, tempId, serverId);
        this.currentDragItem.original.bulletChildren =
          this.currentDragItem.original.bulletChildren.map(child => updateWithNewId(child, tempId, serverId));
        this.currentDragItem.original.links =
          this.currentDragItem.original.links.map(link => updateWithNewId(link, tempId, serverId));
      })
    }
  }

  setDragItem(elementRef: ElementRef, isCopy: boolean, elementId: string) {
    zip(this.store$.pipe(select(getTreeNodes)), this.store$.pipe(select(getTemplateNode)),
      this.store$.pipe(select(getTreeLinks)), this.store$.pipe(select(getTreeLabels))).pipe(take(1))
      .subscribe(([nodes, templateNode, links, labels]) => {
        const element = nodes.get(elementId) || findTemplateElement(templateNode, elementId);
        if (!element) {
          return;
        }
        let bullets = [];

        if (isProcess(element)) {
          bullets = element.bullets.map(bulletId => nodes.get(bulletId));
        }

        const nativeElement: HTMLElement = elementRef.nativeElement;
        const draggedElement = this.cloneDraggedElement(nativeElement);

        const linkIds = [...element.links];
        bullets.forEach(bullet => Array.prototype.push.apply(linkIds, bullet.links));

        this.currentDragItem = {
          element: draggedElement,
          isCopy,
          original: {
            width: nativeElement.getBoundingClientRect().width,
            treeElement: {...element},
            bulletChildren: bullets,
            links: linkIds.map(linkId => links.get(linkId))
          },
        }
      });

  }

  unregister() {
    this.registered.forEach(interactable => interactable.unset());
    this.registered = [];
  }

  register() {
    if (!this.userCanEdit) {
      return;
    }

    this.doInteract('.process.active')
      .resizable(<ResizableOptions><unknown>{
        autoScroll: true,
        edges: {
          top: false,
          right: '.pm-resize-handle',
          bottom: '.pm-resize-handle',
          left: false
        },
        restrictSize: {
          min: {
            width: Size.BASE_WIDTH,
            height: Size.BASE_HEIGHT
          },
        },
        enabled: this.userCanEdit,
        onmove: (event: InteractEvent) => {
          event.currentTarget.dispatchEvent(new CustomEvent('pm-resize', {detail: event}));
        },
        onend: (event: InteractEvent) => {
          event.currentTarget.dispatchEvent(new CustomEvent('pm-resizeend', {detail: event}));
        }
      });

    this.doInteract('.process,pm-bullet,.bullet-container,#pm-paste-button')
      .draggable(<DraggableOptions>{
        manualStart: true,
        autoScroll: {
          container: document.getElementsByTagName('mat-sidenav-content')[0] as HTMLElement,
          margin: 50,
          speed: 500
        },
        enabled: this.userCanEdit,
        onstart: this.onDragStart,
        onend: this.onDragEnd,
        onmove: this.onDragMove
      })
      .on('move', (event: InteractEvent) => {
        const isPasteButton = event.currentTarget.id === 'pm-paste-button';
        const interaction = event.interaction;

        if(this.anyNodeActive) {
          return;
        }

        if (interaction.interacting() || (isPasteButton && !this.currentDragItem) ||
          event.currentTarget.getAttribute(DATA_NODE_TYPE) === '') {
          return;
        }

        if (interaction.pointerIsDown) {
          console.debug('Touched for drag')
          if (!!event.currentTarget.getAttribute(DATA_POINTER_OVER) || this.touchOkay(event)) {
            console.debug('Drag ready');

            const cloneTarget: HTMLElement = isPasteButton ? this.currentDragItem.element :
              event.currentTarget.cloneNode(true) as HTMLElement;

            if (isPasteButton) {
              cloneTarget.setAttribute(DATA_NODE_IS_PASTING, 'true');
            }
            const width = isPasteButton ? this.currentDragItem.original.width :
              event.currentTarget.getBoundingClientRect().width;
            const clone = this.cloneDraggedElement(cloneTarget);
            this.startDragging(clone, event, width);
          }
        } else {
          // Ensure target has been seen without a click first.
          event.currentTarget.setAttribute(DATA_POINTER_OVER, 'true');
        }
      })['pointerEvents']({
      ignoreFrom: '.pm-resize-handle,.editText,.pm-text-edit,.trumbowyg-editor,pm-link-menu,pm-options-overlay'
    });
    this.doInteract('.pm-dropzone-between,.pm-dropzone-row-between,.pm-dropzone-under,.pm-dropzone-bullet')
      .dropzone(<DropzoneOptions>{
        overlap: 'pointer',
        enabled: this.userCanEdit,
        checker: (dragEvent, event, dropped, dropzone, dropElement, draggable, draggableElement): boolean => {
          return dropped && this.checkAllowed(dropElement as Element, draggableElement as Element, event.ctrlKey);
        },
        ondragenter: (event: InteractEvent) => {
          // @ts-ignore
          event.relatedTarget.setAttribute(DATA_DROPPABLE, 'true');
          event.target.classList.add('pm-droppable');
        },
        ondragleave: (event: InteractEvent) => {
          // @ts-ignore
          event.relatedTarget.removeAttribute(DATA_DROPPABLE);
          event.target.classList.remove('pm-droppable');
        },
        ondrop: (event: InteractEvent) => {
          const relatedTarget = event.relatedTarget as Element;
          const target = event.target;
          relatedTarget.removeAttribute(DATA_DROPPABLE);
          target.classList.remove('pm-droppable');

          const sourceId = relatedTarget.getAttribute(DATA_NODE_ID);
          const dropType: AddOrMoveRelation = target.getAttribute(DATA_NODE_DROP_TYPE) as AddOrMoveRelation;

          const dispatchMove = (templateWithMap: TemplateIdWithMap = null) => {
            const payload = {
              targetElementId: target.getAttribute(DATA_NODE_ID),
              sourceElementId: sourceId,
              relation: dropType,
              isNew: (relatedTarget.hasAttribute(DATA_NODE_IS_PASTING) ? this.currentDragItem.isCopy :
                (event as any).dragEvent.ctrlKey) || !!templateWithMap,
              backupElementData: relatedTarget.hasAttribute(DATA_NODE_IS_PASTING) ? {...this.currentDragItem.original} :
                undefined,
              template: templateWithMap
            } as MoveElementPayload;
            this.store$.dispatch(new MoveTreeElement(payload));
          }

          const checkThenDispatch = (templateWithMap: TemplateIdWithMap) => {
            this.dialog.open<ConfirmDialogComponent, ConfirmDialogOptions, ConfirmDialogResult>(ConfirmDialogComponent, {
              data: {
                title: 'dialog.confirm.copy.title',
                body: 'dialog.confirm.copy.description',
                hideSaveButton: true,
                continueButton: 'button.save',
              }
            }).afterClosed().subscribe(result => {
              if (result === 'continue') {
                dispatchMove(templateWithMap);
                this.store$.dispatch(new SaveSteps());
              }
            });

          }
          if (!!sourceId && !!dropType) {
            if (relatedTarget.hasAttribute(DATA_NODE_TEMPLATE_ID)) {
              const template = {
                templateId: relatedTarget.getAttribute(DATA_NODE_TEMPLATE_ID),
                templateVersion: relatedTarget.getAttribute(DATA_NODE_TEMPLATE_VERSION),
                languageMap: undefined
              };

              if (this.templateLanguageMapping.hasMapping()) {
                checkThenDispatch({...template, languageMap: this.templateLanguageMapping.getMappingIds()});
              } else {
                this.dialog.open<TemplateLanguageDialogComponent, TemplateDialogData, LanguageMap|null>(TemplateLanguageDialogComponent, {
                  data: {
                    node: template,
                    type: 'missing',
                  }
                }).afterClosed().subscribe(langMap => {
                  if (!!langMap) {
                    checkThenDispatch({...template, languageMap: this.templateLanguageMapping.getMappingIds()});
                  }
                })
              }
            } else {
              dispatchMove();
            }
          }

          console.debug('Element dropped, deactivating', event.target);
          this.store$.dispatch(new DeactivateElement());
        }
      });
    this.doInteract('.process')
      .dropzone(<DropzoneOptions>{
        enabled: this.userCanEdit,
        overlap: 'pointer',
        checker: (dragEvent, event, dropped, dropzone, dropElement, draggable, draggableElement): boolean => {
          return dropped && !this.isMainPage && this.canExpand(dropElement, draggableElement) &&
            this.checkAllowed(dropElement as Element, draggableElement as Element, event.ctrlKey);
        },
        ondragenter: (event: InteractEvent) => {
          event.target.dispatchEvent(new CustomEvent('pm-dragEnter', {detail: event}));
        }
      });
  }

  isDragging(): boolean {
    return this.dragging;
  }

  ngOnDestroy(): void {
    this.mainPageSubscription.unsubscribe();
    this.nodesSubscription.unsubscribe();
    this.userCanEditSubscription.unsubscribe();
    this.anyNodeActiveSubscription.unsubscribe();
  }

  private cloneDraggedElement(nativeElement: HTMLElement) {
    const draggedElement = nativeElement.cloneNode(true) as HTMLElement;

    const hiddenElements = Array.from(draggedElement.getElementsByClassName('pm-remove-when-dragging'));
    hiddenElements.forEach((element) => {
      draggedElement.removeChild(element);
    });
    return draggedElement;
  }

  /**
   * Ensures all new interactions are registered for removal when unregistering
   * @param target The interact target
   * @param options The interact options
   */
  private doInteract(target: string, options?: Interact.Options): Interact.Interactable {
    const interactable = interact(target, options);
    this.registered.push(interactable);
    return interactable;
  }

  private onDragStart = (event: InteractEvent) => {
    this.dragging = true;
    this.store$.dispatch(new ActivateElement({
      id: event.currentTarget.getAttribute(DATA_NODE_ID),
      activationType: 'highlight'
    }));
  };

  private onDragMove = (event: InteractEvent) => {
    const target: HTMLElement | SVGElement = event.target;
    const DATA_X = 'data-x';
    const DATA_Y = 'data-y';

    const x = (parseFloat(target.getAttribute(DATA_X)) || 0) + event.dx;
    const y = (parseFloat(target.getAttribute(DATA_Y)) || 0) + event.dy;

    // translate the element
    target.style.webkitTransform = target.style.transform = `translate(${x}px, ${y}px)`;

    target.setAttribute(DATA_X, '' + x);
    target.setAttribute(DATA_Y, '' + y);
  };

  private onDragEnd = (event: InteractEvent) => {
    this.dragging = false;
    const target = event.target;

    target.classList.remove('pm-dragging');
    if (target.hasAttribute(DATA_DROPPABLE)) {
      target.remove();
    } else {
      target.style.webkitTransform = target.style.transform = '';
      target.addEventListener('transitionend', () => {
        target.remove()
      });
    }
    console.debug('Drag end, deactivating', event.target);
    this.store$.dispatch(new DeactivateElement());
  };

  private getDropType(element: Element): NodeType {
    return element.getAttribute(DATA_NODE_TYPE) as NodeType;
  }

  private getDraggableType(element: Element): NodeType {
    if (element.getAttribute(DATA_NODE_IS_TEMPLATE_ROOT) === 'true') {
      return 'mainpage';
    }

    if (element.tagName.toLowerCase() === 'pm-bullet' || element.classList.contains('bullet-container')) {
      return 'bullet';
    }

    return element.getAttribute(DATA_NODE_TYPE) as NodeType;
  }

  private isContainable(dropType: NodeType, draggableType: NodeType) {
    switch (dropType) {
      case 'mainpage':
        return draggableType === 'page' || draggableType === 'bullet';
      case 'page':
        return draggableType === 'process' || draggableType === 'mainpage';
      case 'process':
        return draggableType !== 'page';
      default:
        return false;
    }
  }

  private canExpand(dropElement: Element, draggableElement: Element) {
    const treeElement = this.nodes.get(dropElement.getAttribute(DATA_NODE_ID));
    return dropElement.getAttribute(DATA_NODE_ID) !== draggableElement.getAttribute(DATA_NODE_ID) &&
      treeElement.type !== 'mainpage' && treeElement.type !== 'page' && isProcess(treeElement) &&
      ((treeElement.nodes && treeElement.nodes.length > 0) || treeElement.hasUnloadedChildren);
  }

  private checkAllowed(dropElement: Element, draggableElement: Element, isCopy: boolean): boolean {
    const dropType = this.getDropType(dropElement);
    const draggableType = this.getDraggableType(draggableElement);
    if ((dropElement.classList.contains('pm-dropzone-between') && draggableType === 'bullet') ||
      (dropElement.classList.contains('pm-dropzone-bullet') && draggableType !== 'bullet')) {
      return false;
    }
    return this.isContainable(dropType, draggableType) && (isCopy ||
      !this.processContains(draggableElement.getAttribute(DATA_NODE_ID), dropElement.getAttribute(DATA_NODE_ID),
        dropElement.classList.contains('pm-dropzone-under')));
  }

  private touchOkay(event: InteractEvent) {
    return (event as any).pointerType === 'touch' &&
      ((event.currentTarget as Element).classList.contains('active') || event.currentTarget.id === 'pm-paste-button');
  }

  private processContains(parent: string, candidate: string, checkSame: boolean = true) {
    if (checkSame && parent === candidate) {
      return true;
    }
    if (this.nodeCache.has([parent, candidate])) {
      return this.nodeCache.get([parent, candidate]);
    }
    const parentNode = this.nodes.get(parent) as Process;
    if (!parentNode || !parentNode.nodes) {
      return false;
    }
    for (const node of parentNode.nodes) {
      if (this.processContains(node, candidate)) {
        this.nodeCache.set([parent, candidate], true);
        return true;
      }
    }
    this.nodeCache.set([parent, candidate], false);
    return false;
  }

  private startDragging(clone: HTMLElement, event: InteractEvent, originalWidth: number) {
    clone.style.position = 'absolute';
    clone.classList.add('pm-dragging');
    clone.style.bottom = '';
    clone.style.right = '';
    clone.style.zIndex = '1';
    const boundingClientRect = event.currentTarget.getBoundingClientRect();
    clone.style.top = boundingClientRect.top + 'px';
    clone.style.left = boundingClientRect.left + 'px';
    clone.style.maxWidth = originalWidth + 'px';
    clone.style.opacity = '0.2';
    document.body.appendChild(clone);
    clone.ondragend = (event) => event.preventDefault();

    // start a drag interaction targeting the clone
    event.interaction.start({name: 'drag'}, event.interactable, clone);
  }
}
