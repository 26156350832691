import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AppState, getTreeActiveLanguage} from '../../state-management/reducers';

@Injectable()
export class LanguageInterceptorService implements HttpInterceptor {
  language: number;

  constructor(private store$: Store<AppState>) {
    this.store$.select(getTreeActiveLanguage).subscribe(lang => this.language = lang && lang.id || undefined);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!!this.language) {
      const httpRequest = req.clone({
        setHeaders: {
          'ProcessManager-Language': String(this.language)
        }
      });
      return next.handle(httpRequest);
    } else {
      return next.handle(req);
    }
  }
}
