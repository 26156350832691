<h2 mat-dialog-title>{{'dialog.template-publish.title' | translate}}</h2>

<form [formGroup]="templateForm" mat-dialog-content class="mat-typography">
  <mat-form-field>
    <mat-label>{{'dialog.template-admin.template' | translate}}</mat-label>
    <mat-select formControlName="template">
      <mat-option *ngFor="let templateName of templateNames" [value]="templateName">{{templateName}}</mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container formGroupName="descriptions">
    <mat-form-field>
      <mat-label>{{'dialog.template.descriptions.da' | translate}}</mat-label>
      <textarea matInput rows="5" formControlName="da"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'dialog.template.descriptions.en' | translate}}</mat-label>
      <textarea matInput rows="5" formControlName="en"></textarea>
    </mat-form-field>
  </ng-container>
</form>

<div mat-dialog-actions>
  <button mat-button type="button" (click)="submit()" [disabled]="templateForm.invalid">{{'button.ok' |
    translate}}
  </button>
  <button mat-button type="button" mat-dialog-close>{{'button.cancel' | translate}}</button>
</div>
