import {Pipe, PipeTransform} from '@angular/core';
import {DateTranslateService} from '../services/date-translate.service';

@Pipe({
  name: 'langDate'
})
export class LangDatePipe implements PipeTransform {

  constructor(private dateTranslateService: DateTranslateService) {
  }

  transform = (date: Date, args?: any) => this.dateTranslateService.transform(date, args);
}
