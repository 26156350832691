import {FlatTreeControl} from '@angular/cdk/tree';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatRadioChange} from '@angular/material/radio';
import {MatSelectChange} from '@angular/material/select';
import {
  MatTabChangeEvent, MatTabGroup
} from '@angular/material/tabs';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Language, ServerGroup, ServerUser, UserService} from '@process-manager/pm-library';
import {Group, User} from '@process-manager/pm-library/lib/model/user';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, finalize, flatMap, take} from 'rxjs/operators';
import {PopupDialogComponent, PopupDialogData} from '../../shared/components/popup-dialog/popup-dialog.component';
import {ContactService} from '../../shared/services/contact.service';
import {NestedPageTreeNode, PageTreeNode, TreeService} from '../../shared/services/tree.service';
import {AppState, getAuthSiteLanguages, getTreeActiveLanguage} from '../../state-management/reducers';

import {
  ConfirmDialogComponent, ConfirmDialogOptions, ConfirmDialogResult
} from '../confirm-navigation/confirm-dialog.component';
import {TempPasswordDialogComponent} from './temp-password-dialog/temp-password-dialog.component';

const NEW_USER: ServerUser = {
  ID: -1,
  admin: false,
  editor: false,
  showLinks: false
};

const NEW_GROUP: ServerGroup = {
  name: undefined,
  members: []
};

// TODO: Replace with proper validation
// eslint-disable-next-line max-len
const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

function getRandomPassword(pwLength: number = 10): string {
  // noinspection SpellCheckingInspection
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const num_chars = chars.length - 1;
  let randomChar = '';
  for (let i = 0; i < pwLength; i++) {
    randomChar += chars.charAt(Math.floor(Math.random() * num_chars));
  }
  return randomChar;
}

export class FlatPageTreeNode extends PageTreeNode {
  level: number;
  expandable: boolean;
  id: number;
  label: string;
}

@Component({
  selector: 'pm-user-admin-dialog',
  templateUrl: './user-admin-dialog.component.html',
  styleUrls: ['./user-admin-dialog.component.css']
})
export class UserAdminDialogComponent implements OnInit, OnDestroy {
  readonly NEW_USER_SELECT = '__new_user__';
  readonly NEW_GROUP_SELECT = '__new_group__';

  @ViewChild('userForm') userForm: NgForm;
  @ViewChild('groupForm') groupForm: NgForm;
  @ViewChild('matTabGroup') matTabGroup: MatTabGroup

  languages$ = this.store$.pipe(select(getAuthSiteLanguages));
  currentLanguage: Language;
  treeFlattener: MatTreeFlattener<NestedPageTreeNode, FlatPageTreeNode>;
  treeControl: FlatTreeControl<FlatPageTreeNode>;
  treeDataSource: MatTreeFlatDataSource<NestedPageTreeNode, FlatPageTreeNode>;
  currentDescendants: number[] = [];
  users: User[];
  groups: Group[];
  currentUser: ServerUser = {...NEW_USER};
  originalUser = {...this.currentUser};
  currentGroup: ServerGroup = {...NEW_GROUP};
  originalGroup = {...this.currentGroup};
  generatePassword = false;
  sendEmail = false;
  deleting = false;
  selectedUser: string = this.NEW_USER_SELECT;
  selectedGroup: string = this.NEW_GROUP_SELECT;
  private tabIndex = 0;

  constructor(private store$: Store<AppState>, private treeService: TreeService, private userService: UserService,
              private contactService: ContactService, private matDialog: MatDialog,
              private translateService: TranslateService, @Optional() private dialogRef: MatDialogRef<UserAdminDialogComponent>) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.level, this.expandable, this.children);
    this.treeControl = new FlatTreeControl(this.level, this.expandable);
    this.treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  get userAdminTitleKey(): string {
    return this.userService.getSiteSettings().localUserAdministration ? 'dialog.user-admin.title' : 'dialog.group-admin.title';
  }

  get adminUsers(): boolean {
    return this.userService.getSiteSettings().localUserAdministration;
  }

  get adminGroups(): boolean {
    return this.userService.getSiteSettings().localGroupAdministration;
  }

  get language(): Language {
    return this.currentLanguage || {
      id: -1,
      interfaceLanguage: '',
      name: '',
      isDefault: false,
      autoTranslateLanguage: null
    };
  }

  get memberCountTranslationParam() {
    return {users: this.currentGroup.members.length};
  }

  get passwordIsGenerated() {
    return this.currentTabIsUserAdmin && (this.newUser || this.generatePassword);
  }

  get emailDisabled(): boolean {
    return !(this.currentUser.email && EMAIL_REGEXP.test(this.currentUser.email)) || !this.passwordIsGenerated;
  }

  get newUser(): boolean {
    return this.selectedUser === this.NEW_USER_SELECT;
  }

  get newGroup(): boolean {
    return this.selectedGroup === this.NEW_GROUP_SELECT;
  }

  get adminSelf(): boolean {
    return this.selectedUser === this.userService.user.username;
  }

  get currentTabDeleteDisabled(): boolean {
    if (this.currentTabIsUserAdmin) {
      return this.newUser || this.adminSelf;
    } else {
      return this.newGroup;
    }
  }

  get currentTabInvalid(): boolean {
    if (this.currentTabIsUserAdmin) {
      return (this.userForm && this.userForm.invalid) || !this.currentUser || !this.currentUser.accessNode || !this.currentUser.startNode || false;
    } else {
      return this.groupForm && this.groupForm.invalid || false;
    }
  }

  get disableControls(): boolean {
    return !this.selectedUser;
  }

  hasChild(_: number, _nodeData: FlatPageTreeNode) {
    return _nodeData.expandable;
  };

  transformer(node: NestedPageTreeNode, level: number) {
    const flatNode = new FlatPageTreeNode();
    flatNode.id = node.id;
    flatNode.label = node.label;
    flatNode.level = level;
    flatNode.expandable = !!node.children && node.children.length > 0;
    return flatNode;
  };

  getFullName(user: User): string {
    return [user.firstName, user.lastName].join(' ').trim();
  }

  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.prepareNewUser();
    this.treeService.getPages().subscribe(data => {
      this.treeDataSource.data = [data];
      this.treeControl.expandAll();
      this.setTopNode();
    });

    this.userService.getUsersAndGroups().subscribe(data => {
      this.users = data.users;
      this.groups = data.groups;
    });

    if (!!this.dialogRef) {
      this.subscriptions.push(this.dialogRef.backdropClick().subscribe(() => this.closeDialog()));
      this.subscriptions.push(this.dialogRef.keydownEvents().subscribe(event => event.key === "Escape" && this.closeDialog()));
    }

    this.store$.pipe(select(getTreeActiveLanguage), take(1))
      .subscribe(ActiveLanguage => this.currentLanguage = ActiveLanguage);
  }

  private setTopNode() {
    const flatPageTreeNodes = this.treeControl.dataNodes?.filter(node => node.level === 0) || [];
    if(flatPageTreeNodes.length > 0 ) {
      const topNode = flatPageTreeNodes[0];
      const topNodeId = topNode.id;
      if (!this.currentUser.accessNode) {
        this.currentUser.accessNode = topNodeId;
        this.currentUser.startNode = topNodeId;
      }

      if (!this.originalUser.accessNode) {
        this.originalUser.accessNode = topNodeId;
        this.originalUser.startNode = topNodeId;
      }

      this.updateDescendants(topNode);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  userSelectionChange($event: MatSelectChange, force = false) {
    if (!force && this.currentTabIsChanged) {
      this.leaving(() => this.userSelectionChange($event, true),
        () => $event.source.ngControl.reset(!!this.originalUser.username && this.originalUser.username || this.NEW_USER_SELECT));
    } else {
      if (!$event.value || $event.value === this.NEW_USER_SELECT) {
        this.prepareNewUser();
      } else {
        this.userService.getUserDetails($event.value).subscribe(user => {
          this.generatePassword = this.sendEmail = false;
          this.originalUser = this.originalUser = {...this.currentUser = user};
          this.updateDescendants(this.treeControl.dataNodes.find(elem => elem.id === user.accessNode));
        });
      }
    }
  }

  groupSelectionChange($event: MatSelectChange, force = false) {
    if (!force && this.currentTabIsChanged) {
      this.leaving(() => this.groupSelectionChange($event, true),
        () => $event.source.ngControl.reset(this.originalGroup.name));
    } else {
      if (!$event.value || $event.value === this.NEW_GROUP_SELECT) {
        this.originalGroup = {...this.currentGroup = {...NEW_GROUP}};
      } else {
        this.userService.getGroupMembers($event.value).subscribe(groupMembers => {
          this.originalGroup = {
            ...this.currentGroup = {
              name: $event.value,
              members: groupMembers
            }
          };
        });
      }
    }
  }

  private leaving(onContinue: (wasSaved: boolean) => void = () => {}, onCancel: () => void = () => {}, completed: () => void = () => {}) {
    this.showWarning().subscribe(result => {
      switch (result) {
        case 'drop':
          onContinue(false);
          break;
        case 'continue':
          this.currentTabSubmit(false).subscribe(result => {
            if (result) {
              onContinue(true);
            }
          });
          break;
        default:
          onCancel();
      }

      completed();
    })
  }

  emailChange() {
    this.sendEmail = !this.emailDisabled;
  }

  generatePasswordChange() {
    this.sendEmail = !this.emailDisabled;
  }

  accessChanged($event: MatRadioChange) {
    this.currentUser.accessNode = $event.value.id;
    this.updateDescendants($event.value);
  }

  startChanged($event: MatRadioChange) {
    this.currentUser.startNode = $event.value.id;
  }

  startDisabled(node: number) {
    return !(this.currentUser.accessNode === node || this.currentDescendants.includes(node));
  }

  get currentTabIsUserAdmin(): boolean {
    return this.tabIndex === 0 && this.adminUsers;
  }

  private get currentTabIsChanged(): boolean {
    if (this.currentTabIsUserAdmin) {
      return JSON.stringify(this.currentUser) !== JSON.stringify(this.originalUser);
    } else {
      return JSON.stringify(this.currentGroup) !== JSON.stringify(this.originalGroup);
    }
  }

  closeDialog() {
    const close = () => this.dialogRef && this.dialogRef.close();

    if(this.currentTabIsChanged) {
      this.leaving(close);
    } else {
      close();
    }
  }

  currentTabSubmit(close = true): Observable<boolean> {
    if (this.generatePassword || this.selectedUser === this.NEW_USER_SELECT) {
      this.currentUser.mustChangePassword = true;
      this.currentUser.password = getRandomPassword();
    }

    const submitUser = () => {
      if (this.selectedUser === this.NEW_USER_SELECT) {
        return this.userService.createUser(this.currentUser);
      } else {
        return this.userService.updateUser(this.currentUser, this.originalUser.username);
      }
    }

    const submitGroup = () => {
      if (this.selectedGroup === this.NEW_GROUP_SELECT) {
        return this.userService.createGroup(this.currentGroup);
      } else {
        return this.userService.updateGroup(this.currentGroup, this.originalGroup.name);
      }
    }

    const observable: Observable<void> = this.currentTabIsUserAdmin ? submitUser() : submitGroup();

    const subject = new Subject<boolean>();
    observable.subscribe(() => {
      subject.next(true);
      if (close && !!this.dialogRef) {
        this.dialogRef.close()
      }

      const showPassword = (withError: boolean = false) => this.matDialog.open(TempPasswordDialogComponent, {
        data: {
          error: withError,
          password: this.currentUser.password
        }
      });

      if (this.passwordIsGenerated) {
        if (this.sendEmail) {
          this.contactService.sendPassword(this.currentUser, this.newUser).pipe(
            flatMap(() => this.translateService.get('dialog.user-admin.new-password.success')),
            catchError(() => {
              showPassword(true);
              return of();
            })).subscribe(message => message && this.matDialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {data: {title: '', body: message}}));
        } else {
          showPassword();
        }
      }
    }, (error: HttpErrorResponse) => {
      subject.next(false);
      const errorCode = error && error.error && error.error.errorCode || 0;
      let messageKey: string;
      switch (errorCode) {
        case 23:
          messageKey = 'dialog.user-admin.error.user-exists';
          break;
        case 2002:
          messageKey = 'dialog.user-admin.error.bad-username';
          break;
        default:
          messageKey = 'dialog.error.summary';
      }
      this.matDialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {data: {body: messageKey}});
    }, () => subject.complete());
    return subject;
  }

  currentTabDelete() {
    this.deleting = true;

    const deletingEntity = this.currentTabIsUserAdmin ? this.selectedUser : this.selectedGroup;
    const deletingLabelKey = this.currentTabIsUserAdmin ? 'user' : 'group';

    this.matDialog.open<ConfirmDialogComponent, ConfirmDialogOptions, ConfirmDialogResult>(ConfirmDialogComponent, {
      data: {
        title: 'dialog.user-admin.' + deletingLabelKey + '.delete.confirm.title',
        body: 'dialog.user-admin.' + deletingLabelKey + '.delete.confirm.body',
        bodyTranslationArguments: {
          [deletingLabelKey]: deletingEntity
        },
        hideSaveButton: true,
        continueButton: 'button.delete'
      }
    }).afterClosed().subscribe((result) => {
      if(result === 'continue') {
        if (this.currentTabIsUserAdmin) {
          this.userService.deleteUser(this.originalUser.username).pipe(finalize(() => this.deleting = false))
            .subscribe(() => {
              this.users = this.users.filter(user => user.username !== this.originalUser.username);
              this.prepareNewUser();
              this.selectedUser = this.NEW_USER_SELECT;
            });
        } else {
          this.userService.deleteGroup(this.originalGroup.name).pipe(finalize(() => this.deleting = false))
            .subscribe(() => {
              this.groups = this.groups.filter(group => group.name !== this.originalGroup.name);
              this.prepareNewGroup();
              this.selectedGroup = this.NEW_GROUP_SELECT;
            });
        }
      }
    });
  }

  isStartNode(node: number) {
    return this.currentUser.startNode === node;
  }

  isAccessNode(node: number) {
    return this.currentUser.accessNode === node;
  }

  private alreadyChanging = false;
  tabChanged($event: MatTabChangeEvent) {
    const updateTabIndex = (wasSaved: boolean) => {
      if(this.matTabGroup.selectedIndex !== $event.index) {
        this.matTabGroup.selectedIndex = $event.index;
      }

      this.tabIndex = $event.index;
      if (!wasSaved) {
        if(this.currentTabIsUserAdmin) {
          this.prepareNewUser();
          this.selectedUser = this.NEW_USER_SELECT;
        } else {
          this.prepareNewGroup();
          this.selectedGroup = this.NEW_GROUP_SELECT;
        }
      }
    }

    if (this.currentTabIsChanged && !this.alreadyChanging) {
      let oldIndex = 0;
      if (!this.currentTabIsUserAdmin && this.adminUsers) {
        oldIndex = 1;
      }
      this.alreadyChanging = true;
      this.matTabGroup.selectedIndex = oldIndex;
      this.leaving(updateTabIndex, undefined, () => this.alreadyChanging = false);
    } else if (!this.alreadyChanging){
      updateTabIndex(false);
    }
  }

  private showWarning(): Observable<ConfirmDialogResult> {
    const userOrGroup = this.currentTabIsUserAdmin ? 'user' : 'group';

    return this.matDialog.open<ConfirmDialogComponent, ConfirmDialogOptions, ConfirmDialogResult>(ConfirmDialogComponent, {
      data: {
        hideSaveButton: false,
        body: `dialog.user-admin.${userOrGroup}.confirm.body`,
        title: `dialog.user-admin.${userOrGroup}.confirm.title`
      }
    }).afterClosed();
  }

  private prepareNewUser() {
    this.originalUser = {...this.currentUser = {
      ...NEW_USER,
    }};
    this.setTopNode();
    this.sendEmail = false;
    this.generatePassword = false;
  }

  private prepareNewGroup() {
    this.originalGroup = {...this.currentGroup = {...NEW_GROUP}};
  }

  private updateDescendants(value: FlatPageTreeNode) {
    this.currentDescendants = this.treeControl.getDescendants(value).map(node => node.id);
    if (!this.currentDescendants.includes(this.currentUser.startNode)) {
      this.currentUser.startNode = this.currentUser.accessNode;
    }
  }

  private expandable(node) {
    return node.expandable
  }

  private level(node) {
    return node.level
  }

  private children(nestedNode) {
    return of(nestedNode.children)
  }
}
