<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" [class.folded]="folded" class="mat-typography dialog-content">
  <pm-foldable-dialog-title mat-dialog-title (foldedChange)="folded = $event">{{'dialog.owner-list.title' | translate}}<mat-spinner matSuffix [class.proman-visible]="loading" [diameter]="18"></mat-spinner></pm-foldable-dialog-title>
  <mat-dialog-content>
    <table mat-table class="pm-show-active" [dataSource]="dataSource">
      <ng-container matColumnDef="owner">
        <th mat-header-cell class="proman-search-header" *matHeaderCellDef> {{'dialog.owner-list.table.owner' |
          translate}}
        </th>
        <td mat-cell class="hide-active" *matCellDef="let element" [title]="element.owner" [attr.rowspan]="getRowSpan(element) || 1" [style.display]="getRowSpan(element) ? '' : 'none'"> {{element.owner}}
        </td>
      </ng-container>

      <ng-container matColumnDef="label">
        <th mat-header-cell class="proman-search-header" *matHeaderCellDef> {{'dialog.owner-list.table.label' |
          translate}}
        </th>
        <td mat-cell *matCellDef="let element" [title]="element.label"> {{element.label}}
        </td>
      </ng-container>

      <ng-container matColumnDef="path">
        <th mat-header-cell *matHeaderCellDef> {{'dialog.owner-list.table.path' | translate}}</th>
        <td mat-cell class="proman-search-path" *matCellDef="let element"
                  [title]="element.path"> {{element.path}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToNode(row)"
               [class.active]="row.id === activeSearchElement"></tr>
    </table>
  </mat-dialog-content >
</div>
