import {ApplicationRef, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {UserService} from '@process-manager/pm-library';
import {liveQuery} from 'dexie';
import * as Immutable from 'immutable';
import pThrottle from 'p-throttle';
import {BehaviorSubject, from, interval, merge, Observable, of, Subject} from 'rxjs';
import {filter, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {
  ConfirmDialogComponent,
  ConfirmDialogOptions,
  ConfirmDialogResult
} from '../../main/confirm-navigation/confirm-dialog.component';
import {AppState, getAuthUserIsOnline} from '../../state-management/reducers';
import {db, DbElement, DbLink, DbSite} from '../components/db';
import {Label} from '../model/label';
import {PathElement, Tree} from '../model/tree';
import {stripHtml} from '../styleExtractor';
import {TreeMerger} from '../tree-building/tree.merger';
import {NestedPageTreeNode, TreeService} from './tree.service';

type OfflineDialogResult = 'online' | 'offline' | 'wait' | 'keep-trying';

@Injectable({
  providedIn: 'root'
})
export class OfflineTreeService {
  private saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly manualUpdate$: Subject<void> = new Subject();

  private user$ = this.userService.user$;

  constructor(private treeService: TreeService, private userService: UserService, private dialog: MatDialog,
              private appRef: ApplicationRef, private store$: Store<AppState>) {
    this.setupAutoSave();
  }

  private userOnline$ = this.store$.select(getAuthUserIsOnline);
  get synchronizing(): Observable<boolean> {
    return this.saving$.asObservable();
  }

  domainAvailable = async (domain: string) => !!(await db.sites.get(domain));

  public offlineDialog = (domain: string, onlyOffline = false,
                          forceLanguage = undefined): Observable<OfflineDialogResult> => {
    return from(liveQuery(() => db.sites.get(domain))).pipe(mergeMap(site => {
      if (!site && onlyOffline) {
        return of<OfflineDialogResult>('wait');
      } else return this.dialog.open<ConfirmDialogComponent, ConfirmDialogOptions, ConfirmDialogResult>(
        ConfirmDialogComponent, {
          data: {
            // TODO: Add unsaved changes detection
            body: !!site ? 'dialog.offline.site-stored' : 'dialog.offline.site-missing',
            title: 'dialog.offline.title',
            hideSaveButton: true,
            cancelButton: 'button.wait',
            continueButton: !!site ? 'button.go-offline' : 'button.keep-trying',
            forceLanguage: forceLanguage
          },
          disableClose: true
        }).afterClosed().pipe(map(result => result === 'cancel' ? 'wait' : !!site ? 'offline' : 'keep-trying'));
    }));
  }

  public onlineDialog = (domain: string, onlyOffline = false,
                          forceLanguage = undefined): Observable<OfflineDialogResult> => {
    return from(liveQuery(() => db.sites.get(domain))).pipe(mergeMap(site => {
    return this.dialog.open<ConfirmDialogComponent, ConfirmDialogOptions, ConfirmDialogResult>(
        ConfirmDialogComponent, {
          data: {
            body: 'dialog.online',
            title: 'dialog.online.title',
            hideSaveButton: true,
            cancelButton: 'button.wait',
            continueButton: 'button.go-online',
            forceLanguage: forceLanguage
          },
          disableClose: true
        }).afterClosed().pipe(map(result => result === 'cancel' ? 'wait' : 'online'));
    }));
  }

  async getTree(id: string = null): Promise<Tree> {
    const domain = this.userService.domain;
    const dbLinks: DbLink[] = (await db.links.where({site: domain}).toArray()).map(link => {
      return {
        ...link,
        action: link.data instanceof Blob && URL.createObjectURL(link.data) || link.action
      }
    });

    const path = await this.getPath(id ?? '-1');
    return {
      source: 'offline',
      path: path,
      localPath: [],
      root: path[0].id,
      labels: (await db.labels.toArray()).reduce((acc, item) => {
        return acc.set(item.id, item)
      }, Immutable.Map<number, Label>()),
      nodes: (await db.nodes.where({site: domain}).toArray()).reduce((acc, item) => {
        return acc.set(item.id, item)
      }, Immutable.Map<string, DbElement>()),
      links: dbLinks.reduce((acc, item) => {
        return acc.set(item.id, item)
      }, Immutable.Map<string, DbLink>()),
    };
  }

  async prohibitedNodes() {
    const domain = this.userService.domain
    const walker = async (parent: string): Promise<string[]> => {
      const children = (await db.nodes.where({
        site: domain,
        parentId: parent
      }).toArray()).map(child => child.id);
      return children.concat((await Promise.all(children.map(walker))).flat());
    }
    return []; // (await Promise.all((await db.sites.get(domain)).prohibitedNodes.map(walker))).flat();
  }

  async fetchTree(): Promise<Tree> {
    const throttle = pThrottle({
      limit: 4,
      interval: 1000
    });
    const rootPage = await this.treeService.getPages().toPromise();
    const fetcher = throttle((id: number) => this.treeService.getTree(id, false, false).toPromise());

    const pageTreeLoader = async (page: NestedPageTreeNode): Promise<Tree> => {
      const tree = await fetcher(page.id);

      const childTrees = await Promise.all(page.children.map(async child => pageTreeLoader(child)));

      return childTrees.reduce((acc, childTree) => {
        return new TreeMerger(acc, childTree).merge(childTree.root);
      }, tree)
    }
    return pageTreeLoader(rootPage);
  }

  async storeTree(): Promise<void> {
    this.manualUpdate$.next();
  }

  private readonly PRODUCTION_INTERVAL = 5 * 60_000;
  private readonly TEST_INTERVAL = 30_000;

  private setupAutoSave() {
  this.user$.pipe(switchMap(user => {
    const interval$ = interval(environment.production ? this.PRODUCTION_INTERVAL : this.TEST_INTERVAL);
      if (!!user) {
        return merge(interval$, this.manualUpdate$).pipe(map(() => true))
      } else {
        return of(false);
      }
    }), filter(Boolean)).subscribe(() => this.doStoreTree());
  }

  private isOfflineEnabled() {
    return this.userService?.getSiteSettings()?.offlineEnabled || false;
  }

  public isOnline() {
    return this.userOnline$;
  }

  private async doStoreTree(): Promise<void> {
    if (!this.isOfflineEnabled() || !window.navigator.onLine || !(await this.isOnline().pipe(take(1)).toPromise())) {
      return;
    }

    try {
      this.saving$.next(true);
      const tree = await this.fetchTree();
      const domain = this.userService.domain;
      const prohibited = await this.prohibitedNodes();
      const links = (await Promise.all(tree.links.toArray().map(async (link): Promise<DbLink> => {
        const inNodes = tree.nodes.toArray()
          .filter(node => !prohibited.includes(node.id) && node.links.includes(link.id));

        if (inNodes.length === 0 || link.linkType === 'http') {
          return {
            ...link,
            site: domain,
            available: false
          }
        } else if(!!link.action){
          try {
            const response = await fetch(link.action, {mode: 'cors'});
            return {
              ...link,
              site: domain,
              available: true,
              data: await response.blob()
            }
          } catch (e) {
            console.warn('Network problem while fetching link for offline storage', e);
          }
        } else {
          return null
        }
      }))).filter(Boolean);

      return db.transaction('rw', db.sites, db.links, db.labels, db.nodes, async () => {
        const site: DbSite = {
          prohibitedNodes: [],
          name: domain,
          settings: this.userService.getSiteSettings(),
          user: this.userService.user
        }
        db.sites.put(site);

        await db.nodes.bulkPut(tree.nodes.toArray().map((node) => {
          const prohibitedParent = prohibited.includes(node.parentId);
          if (prohibitedParent) {
            return null;
          }

          const prohibitedSelf = prohibited.includes(node.id);

          return {
            ...node,
            site: domain,
            available: !prohibitedSelf,
            parentId: node.parentId ?? '-1'
          };
        }).filter(Boolean));
        await db.labels.bulkPut(tree.labels.toArray().map(label => ({
          ...label,
          site: domain
        })));
        await db.links.bulkPut(links);
      });
    } catch (err) {
      console.log('Error while saving:', err);
      alert('Storing of offline data not completed. Are you still online?');
    } finally {
      this.saving$.next(false);
    }
  }

  getRoot = async () => db.nodes.get({site: this.userService.domain, parentId: '-1'});

  private async getPath(id: string) {
    const path: PathElement[] = [];
    do {
      const current = await db.nodes.get({
        site: this.userService.domain,
        parentId: id
      });
      path.unshift(new PathElement(current.id, stripHtml(current.label)));
      id = current.parentId;
    } while (id !== '-1');

    return path;
  }
}
